import { createSlice } from "@reduxjs/toolkit";
import { notify } from "../../common/commonFunction";
import { messages } from "../../constants";
import {
  changePassword,
  reqToForgetPassword,
  reqToGetUser,
  reqToRegisterUser,
  reqToUserLogin,
  reqToVerifyEmail,
  verifyForgetPassword,
} from "../services/userServices";

// Create initial state
const initialState = {
  loader: false,
  user: null,
  token: null,
  userDetail: {},
  error: "",
};

// Create user slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reqToUserLogout: () => {
      notify(messages.toast.auth.logout, "success");
      return initialState;
    },
  },
  extraReducers: (builder) => {
    //registerUser
    builder.addCase(reqToRegisterUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToRegisterUser.fulfilled, (state, action) => {
      state.loader = false;
      state.reqToRegisterUser = action.payload;
    });
    builder.addCase(reqToRegisterUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload?.message;
    });

    //reqToUserLogin
    builder.addCase(reqToUserLogin.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUserLogin.fulfilled, (state, action) => {
      state.loader = false;
      notify(messages.toast.auth.login, "success");
      state.user = action.payload;
      state.token = action.payload.token;
    });
    builder.addCase(reqToUserLogin.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //registerUser
    builder.addCase(reqToVerifyEmail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToVerifyEmail.fulfilled, (state, action) => {
      state.loader = false;
      state.reqToVerifyEmail = action.payload;
    });
    builder.addCase(reqToVerifyEmail.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //get user detail
    builder.addCase(reqToGetUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetUser.fulfilled, (state, action) => {
      state.loader = false;
      state.userDetail = action.payload;
    });
    builder.addCase(reqToGetUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //forget password
    builder.addCase(reqToForgetPassword.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToForgetPassword.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToForgetPassword.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //forget password
    builder.addCase(verifyForgetPassword.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(verifyForgetPassword.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(verifyForgetPassword.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //forget password
    builder.addCase(changePassword.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export const { reqToUserLogout } = userSlice.actions;
export default userSlice.reducer;
