import * as Yup from "yup";
import { customEmailValidator } from "../common/commonFunction";

export const loginValidation = Yup.object({
  email: Yup.string().test("email", "Please enter a valid email!", customEmailValidator).required("Email is Requried!"),
  password: Yup.string().min(6, "Password must be at least 6 characters!").required("Password is Required!"),
});

export const forgetPassEmailValidation = Yup.object({
  email: Yup.string().test("email", "Please enter a valid email!", customEmailValidator).required("Email is Requried!"),
});

export const verifyPasswordValidation = Yup.object({
  password: Yup.string().min(6, "Password must be at least 6 characters!").required("Password is Required!"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match!")
    .required("Please confirm your password!"),
});

export const commonSignupFormValidation = Yup.object({
  firstName: Yup.string().trim().required("First Name is Required!"),
  lastName: Yup.string().trim().required("Last Name is Required!"),
  email: Yup.string().test("email", "Please enter a valid email!", customEmailValidator).required("Email is Requried!"),
  password: Yup.string().min(6, "Password must be at least 6 characters!").required("Password is Required!"),
  isAgency: Yup.boolean(),
  termsPolicy: Yup.boolean().oneOf([true], "You must accept the terms of use policy!"),
});

export const agencyValidation = commonSignupFormValidation.shape({
  agencyName: Yup.string().trim().required("Agency Name is Required!"),
  phone: Yup.string().trim().required("Phone number is Required!"),
});

export const AgencySignUpValidation = Yup.object().shape({
  firstName: Yup.string().required("Please Enter First Name"),
  lastName: Yup.string().required("Please Enter Last Name"),
  email: Yup.string().required("Please Enter Email Address"),
  password: Yup.string().required("Please Enter Password"),
  agencyName: Yup.string().required("Please Enter Agency Name"),
  phone: Yup.string().required("Please Enter Mobile Number"),
});
