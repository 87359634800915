import React from 'react'

const PaymentChat = () => {
    return (
        <section className='team-message-main'>
            <div className="messages">
                <div className="message-contents">
                    <div className="time">12:29 pm</div>
                    <p className='sender'>Show me the list of due payment amount and client name</p>
                </div>
            </div>
            <div className="messages owner">
                <div className="message-contents">
                    <div className="time">12:29 pm</div>
                    <div className="team-member-details">
                        <p>sure here the List of Due payments </p>
                        <div className="professinal-details">
                            <ol>
                                <li>$700 - Client name, due date 24 August, 2023</li>
                                <li>$200 - Client name, due date 24 August, 2023</li>
                                <li>$410 - Client name, due date 24 August, 2023</li>
                                <li>$1200 - Client name, due date 24 August, 2023</li>
                                <li>$1300 - Client name, due date 24 August, 2023</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PaymentChat