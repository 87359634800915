import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./TeamDetail.scss";
import LeftArrow from "../../../../assets/Icon/arrow-left-line.svg";
import TeamChat from "../../../../common/team/teamDetail/TeamChat";
import TeamDCard from "../../../../common/team/teamDetail/TeamDCard";
import TeamDTable from "../../../../common/team/teamDetail/TeamDTable";
import { routes } from "../../../../constants";
import { useSelector } from "react-redux";

const TeamDetail = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const navigate = useNavigate();
  const location = useLocation();
  const { member } = location.state;

  const goBack = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentMyTeam}/page/1`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffMyTeam}/page/1`);
    } else if (userRole === "AGENCY_FOREMAN") {
      navigate(`${routes.formanMyTeam}/page/1`);
    } else {
      navigate(`${routes.myTeam}/page/1`);
    }
  };

  const goToMemberAcvtivities = () => {
    navigate("/myteam/member-activities", { state: { member } });
  };

  const goToAssignProject = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentMyTeam}/detail/${member.member.mid}/assign-project`, { state: { member } });
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffMyTeam}/detail/${member.member.mid}/assign-project`, { state: { member } });
    } else if (userRole === "AGENCY_FOREMAN") {
      navigate(`${routes.formanMyTeam}/detail/${member.member.mid}/assign-project`, { state: { member } });
    } else {
      navigate("/assign-project", { state: { member } });
    }
  };
  return (
    <div className="team-detail-screen">
      <div className="project-heading">
        <span onClick={goBack} style={{ cursor: "pointer" }}>
          <img src={LeftArrow} alt="" />
        </span>
        <h2>Back to list</h2>
      </div>
      <div className="row team-detail-content">
        <div className="col-lg-6">
          <TeamDCard member={member} goToMemberAcvtivities={goToMemberAcvtivities} goToAssignProject={goToAssignProject} userRole={userRole} />
          <h2 className="table-heading">
            Assigned Projects <span>3 Projects</span>
          </h2>
          <TeamDTable />
        </div>
        <div className="col-lg-6 d-lg-block d-none">
          <TeamChat />
        </div>
      </div>
    </div>
  );
};

export default TeamDetail;
