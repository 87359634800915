import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Form, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./ClientScreen.scss";

import PlusIcon from "../../../assets/Icon/plus-icon.svg";
import AddClient from "../../../components/Modal/addClient/AddClient";
import { reqToAddClient, reqToGetAllClients } from "../../../reduxToolkit/services/clientServices";
import Loader from "../../../components/Loader/Loader";
import { routes } from "../../../constants";
import ClientTable from "../../../common/client/ClientTable";

const ClientScreen = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { page_number } = params;

  const clientReducer = useSelector((state) => state.client);

  const { clients } = clientReducer;
  const { totalClientCount } = clientReducer;

  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(page_number);

  useEffect(() => {
    dispatch(reqToGetAllClients({ page: page, limit: 10 }));
  }, []);

  const onHideModalHandler = () => {
    setModalShow(false);
    form.setFieldsValue({
      email: "",
    });
  };

  const onSubmitHandler = (values) => {
    const data = values;
    dispatch(
      reqToAddClient({
        data,
        onSuccess: (res) => {
          if (res) {
            onHideModalHandler();
          }
        },
      })
    );
  };

  const goToClientDetail = (clientD) => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentClient}/detail/${clientD.client.cid}`, { state: { clientD } });
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffClient}/detail/${clientD.client.cid}`, { state: { clientD } });
    } else {
      navigate(`${routes.client}/detail/${clientD.client.cid}`, { state: { clientD } });
    }
  };

  const onPageChange = (newPage) => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentClient}/page/${newPage}`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffClient}/page/${newPage}`);
    } else {
      navigate(`${routes.client}/page/${newPage}`);
    }
    setPage(newPage);
    dispatch(reqToGetAllClients({ page: newPage, limit: 10 }));
  };

  return (
    <>
      {clientReducer.loader && <Loader />}
      <section className="client-screen">
        <div className="list-header">
          <h1 className="heading">Client</h1>
          <div className="create-project-btn">
            <Button onClick={() => setModalShow(true)}>
              <img src={PlusIcon} alt="" />
              Add New Client
            </Button>
          </div>
        </div>
        <div className="list-table">
          <ClientTable clients={clients} goToClientDetail={goToClientDetail} />
          <div className="pagination-main">
            <Pagination total={totalClientCount} onChange={onPageChange} />
          </div>
          <AddClient show={modalShow} onHide={onHideModalHandler} form={form} onSubmitHandler={onSubmitHandler} />
        </div>
      </section>
    </>
  );
};

export default ClientScreen;
