import React from "react";
import profileImg from "../../assets/images/profile.jpg";
import { Button } from "react-bootstrap";
import "./AppoinmentDCard.scss";
import moment from "moment";

const AppoinmentDCard = ({ appDetail }) => {
  return (
    <div className="appointment-detail-card">
      <div className="d-flex profile-container flex-wrap gap-4">
        <div className="image">
          <img src={profileImg} alt="" />
        </div>
        <div className="detail">
          <h2>{appDetail?.firstName + " " + appDetail?.lastName}</h2>
          <h4>{appDetail?.phone}</h4>
          <h4>{appDetail?.email}</h4>
          <h4>203-Dallas, Texas</h4>
        </div>
      </div>
      {/* <Button className="complete-btn">Mark as complete</Button> */}
      <div className="status-detail">
        {/* Meeting Status : <span className="status"> Completed </span> */}
        <span className="date">
          {" "}
          {moment.utc(appDetail?.createdAt).format("D MMM YYYY,")} {moment.utc(appDetail?.createdAt).format("hh:mm A")}
        </span>
      </div>
    </div>
  );
};

export default AppoinmentDCard;
