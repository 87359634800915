import React from "react";
import { Table } from "react-bootstrap";
import "./ClientTable.scss";

import { FILE_BASE_URL } from "../../config";
import rightArrow from "../../assets/Icon/right-arrow-icon.svg";

const ClientTable = ({ clients, goToClientDetail }) => {
  return (
    <Table size="lg" responsive className="client-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Client Name</th>
          <th>Contact No.</th>
          <th>Email Id</th>
          <th>Projects</th>
          <th>Status</th>
          <th>CSAT</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        {/* {data.map((item, i) => (
      <tr key={i}>
        <td>{item.id}</td>
        <td>
          <div className="client-details">
            <div className="client-avatar">
              <img src="https://picsum.photos/id/1/200/300" alt="" />
            </div>
            <div className="name">{item.name}</div>
          </div>
        </td>
        <td>{item.contact}</td>
        <td>{item.mail}</td>
        <td>{item.project}</td>
        <td>{item.status}</td>
        <td>{item.csat}</td>
        <td>
          <img onClick={clientDetail} style={{ cursor: "pointer" }} src={rightArrow} alt="" />
        </td>
      </tr>
    ))} */}
        {clients.length === 0 ? (
          <tr>
            <td colSpan={8} className="no-data-found">
              No data found
            </td>
          </tr>
        ) : (
          clients.map((client, i) => {
            return (
              <tr key={i}>
                <td>{client.client.cid}</td>
                <td>
                  <div className="client-details">
                    <div className="client-avatar">
                      <img src={client.image ? FILE_BASE_URL + client.image : "https://picsum.photos/id/1/200/300"} alt="" />
                    </div>
                    <div className="name">{client.firstName + " " + client.lastName}</div>
                  </div>
                </td>
                <td>{client.phone}</td>
                <td>{client.email}</td>
                <td>{client.project ? client.project : "-"}</td>
                <td>{client.status ? client.status : "-"}</td>
                <td>{client.csat ? client.csat : "-"}</td>
                <td>
                  <img onClick={() => goToClientDetail(client)} style={{ cursor: "pointer" }} src={rightArrow} alt="" />
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
};

export default ClientTable;
