import React from "react";
import { Dropdown } from "react-bootstrap";
import "./MsgFilter.scss";

const MsgFilter = () => {
  return (
    <div className="chat-box-header">
      <div className="title">
        Chats
        <span>20</span>
      </div>
      <div className="filter-dorpdown">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" variant="none" style={{ backgroundColor: "#E6E5FD" }}>
            Filters
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <input type="radio" id="all" name="fav_language" value="ALL" />
              <label htmlFor="all">All</label>
            </Dropdown.Item>
            <Dropdown.Item>
              <input type="radio" id="only-team-members" name="fav_language" value="only-team-members" />
              <label htmlFor="only-team-members">Only Team Members</label>
            </Dropdown.Item>
            <Dropdown.Item>
              <input type="radio" id="only-clients" name="fav_language" value="only-clients" />
              <label htmlFor="only-clients">Only Clients</label>
            </Dropdown.Item>
            <Dropdown.Item>
              <input type="radio" id="only-floor-man" name="fav_language" value="only-floor-man" />
              <label htmlFor="only-floor-man">Only Floor Man</label>
            </Dropdown.Item>
            <Dropdown.Item>
              <input type="radio" id="only-projects" name="fav_language" value="only-projects" />
              <label htmlFor="only-projects">Only Projects</label>
            </Dropdown.Item>
            <Dropdown.Item>
              <input type="radio" id="unread" name="fav_language" value="unread" />
              <label htmlFor="unread">Unread</label>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default MsgFilter;
