import React from "react";
import { Dropdown } from "react-bootstrap";
import { DatePicker } from "antd";

import "./AdminDashboard.scss";
import OverviewSection from "../../../common/dashboard/OverviewSection";
import PerformaceSection from "../../../common/dashboard/PerformaceSection";
import TransactionTable from "../../../common/dashboard/TransactionTable";

const AdminDashboard = () => {
  const { RangePicker } = DatePicker;
  return (
    <>
      <section className="admin-dashboard-section">
        <div className="section-header">
          <h1 className="heading">Analytics overview</h1>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">This Week</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="droup-item">This Week</Dropdown.Item>
              <Dropdown.Item className="droup-item">This Month</Dropdown.Item>
              <Dropdown.Item className="droup-item">This Year</Dropdown.Item>
              <div className="droup-item">
                Select Date
                <RangePicker />
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <OverviewSection />
        <PerformaceSection />
        <div className="latest-transaction">Latest Transaction</div>
        <TransactionTable />
      </section>
    </>
  );
};

export default AdminDashboard;
