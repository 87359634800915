import { createSlice } from "@reduxjs/toolkit";
import { reqToCreateAppoinment, reqToGetAppoinments } from "../services/appoinmentServices";

const initialState = {
  loader: false,
  error: "",
  appoinments: [],
  totalAppoinmentCount: 0,
};

// Create appoinment slice
const appoinmentSlice = createSlice({
  name: "appoinment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //req To Create Appoinment
    builder.addCase(reqToCreateAppoinment.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToCreateAppoinment.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToCreateAppoinment.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Get Appoinments
    builder.addCase(reqToGetAppoinments.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetAppoinments.fulfilled, (state, action) => {
      state.loader = false;
      state.appoinments = action.payload.rows;
      state.totalAppoinmentCount = action.payload.count;
    });
    builder.addCase(reqToGetAppoinments.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
  },
});

export default appoinmentSlice.reducer;
