import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";

import "./ProposalPreview.scss";
import LeftArrow from "../../../../../assets/Icon/arrow-left-line.svg";
import { reqToGetProposalDetails } from "../../../../../reduxToolkit/services/proposalServices";
import Loader from "../../../../../components/Loader/Loader";
import ProposalDHead from "../../../../../common/proposal/proposalDetail/ProposalDHead";
import ProposalDSubject from "../../../../../common/proposal/proposalDetail/ProposalDSubject";
import { routes } from "../../../../../constants";

const ProposalPreview = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { proposal_id } = useParams();

  const proposalReducer = useSelector((state) => state.proposal);
  const { proposalDetail } = proposalReducer;

  const opportunity = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentOpportunity}/page/1`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffOpportunity}/page/1`);
    } else {
      navigate("/opportunity");
    }
  };

  useEffect(() => {
    dispatch(reqToGetProposalDetails(proposal_id));
  }, []);

  return (
    <>
      {proposalReducer.loader && <Loader />}
      <div className="proposal-preview-screen">
        <div className="proposal-preview-header d-flex justify-content-between align-items-center">
          <div className="project-heading">
            <span onClick={opportunity} style={{ cursor: "pointer" }}>
              <img src={LeftArrow} alt="" />
            </span>
            <h2>Back to list</h2>
          </div>
          <div className="current-stage">
            <h4>Last review by client</h4>
            <h3>{moment(proposalDetail.updatedAt).fromNow()}</h3>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="proposal-preview-content">
            <ProposalDHead proposalDetail={proposalDetail} />
            <ProposalDSubject proposalDetail={proposalDetail} />
          </div>
          <div className="d-flex justify-content-end footer-btn">
            <Button className="edit-btn">Edit</Button>
            <Button>Print</Button>
            <Button>Download</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalPreview;
