import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import AiLogo from "../../assets/images/ai-logo.svg";
import AiAddIcon from "../../assets/Icon/tabler_message-2-plus.svg";
import "./MsgUserList.scss";

const MsgUserList = ({ data, showMsgContent }) => {
  return (
    <div className="p-3">
      <div className="search-section">
        <InputGroup className="mb-3">
          <Form.Control placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2" />
          <InputGroup.Text id="basic-addon2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <div className="barcci-ai">
        <div className="d-flex">
          <div className="ai-logo">
            <img src={AiLogo} alt="" />
          </div>
          <div className="ai-name-mssage ms-4">
            <div className="name">Barcci AI</div>
            <span className="message">Write Proposal for....</span>
          </div>
        </div>
        <div className="ai-add-icon">
          <img src={AiAddIcon} alt="" />
        </div>
      </div>
      <div className="other-messages">
        {data.map((item, i) => (
          <div key={i} className="person-details" onClick={showMsgContent}>
            <div className="d-flex align-items-center">
              <div className="profile-img me-4">
                <img src={item.image} alt="" />
                <div className="circle">
                  <div className="small-circle"></div>
                </div>
              </div>
              <div className="personal-details">
                <div className="name">{item.name}</div>
                <div className="designation">{item.desg}</div>
                <div className="meassage">{item.message}</div>
              </div>
            </div>
            <div className="pending-msg">{item.pendingMess}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MsgUserList;
