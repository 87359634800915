import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../helper/axios";
import { toastify } from "../../common/commonFunction";
import { apiendpoints, messages, routes } from "../../constants";

//req to create appoinment
export const reqToCreateAppoinment = createAsyncThunk("reqToCreateAppoinment", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.createAppoinment, data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.appointment.createAppoinment);
      data.navigate(data.route);
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

// req To Get Appoinments
export const reqToGetAppoinments = createAsyncThunk("reqToGetAppoinments", async (data) => {
  try {
    const response = await Axios.get(
      `${apiendpoints.getAppoinments}/${data.type || "today"}?page=${data.page || 1}&limit=${data.limit || 10}&query=${data.query || ""}`,
      authHeaders()
    );
    if (response.status === 201 || response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});
