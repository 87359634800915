import DashboardIcon from "../assets/Icon/home-icon.svg";
import ProjectIcon from "../assets/Icon/project-icon.svg";
import OpportunityIcon from "../assets/Icon/opportunity-icon.svg";
import AppointmentIcon from "../assets/Icon/appointment-icon.svg";
import TeamIcon from "../assets/Icon/myTeam-icon.svg";
import ClientsIcon from "../assets/Icon/client-icon.svg";
import PaymentIcon from "../assets/Icon/payment-icon.svg";
import MessagesIcon from "../assets/Icon/message-icon.svg";
import ReportsIcon from "../assets/Icon/report-icon.svg";
import UpgradeIcon from "../assets/Icon/upgrade-icon.svg";
import { routes } from ".";

export const clientMenu = [
  { label: "Dashboard", icon: DashboardIcon, url: "/dashboard" },
  { label: "Appointment", icon: AppointmentIcon, url: `${routes.clientAppointment}/page/1` },
  { label: "Proposal", icon: TeamIcon, url: `${routes.clientProposal}/page/1` },
  { label: "Project Status", icon: ClientsIcon, url: `${routes.clientProject}/page/1` },
  { label: "Payment", icon: PaymentIcon, url: `${routes.clientPayment}` },
  { label: "Messages", icon: MessagesIcon, url: `${routes.clientMessages}` },
  { label: "Reports", icon: ReportsIcon, url: `${routes.clientReport}` },
];

export const agancyHeadMenu = [
  { label: "Dashboard", icon: DashboardIcon, url: "/dashboard" },
  { label: "Projects", icon: ProjectIcon, url: `${routes.project}` },
  { label: "Opportunity", icon: OpportunityIcon, url: `${routes.opportunity}/page/1` },
  { label: "Appointment", icon: AppointmentIcon, url: `${routes.appointment}/page/1` },
  { label: "My Team", icon: TeamIcon, url: `${routes.myTeam}/page/1` },
  { label: "Clients", icon: ClientsIcon, url: `${routes.client}/page/1` },
  { label: "Payment", icon: PaymentIcon, url: `${routes.payment}` },
  { label: "Messages", icon: MessagesIcon, url: `${routes.message}` },
  { label: "Reports", icon: ReportsIcon, url: "" },
  { label: "Upgrade", icon: UpgradeIcon, url: "" },
  { label: "Help & Support", icon: UpgradeIcon, url: "" },
];

export const agentMenu = [
  { label: "Dashboard", icon: DashboardIcon, url: "/dashboard" },
  { label: "Projects", icon: ProjectIcon, url: `${routes.agentProject}/page/1` },
  { label: "Opportunity", icon: OpportunityIcon, url: `${routes.agentOpportunity}/page/1` },
  { label: "Appointment", icon: AppointmentIcon, url: `${routes.agentAppointment}/page/1` },
  { label: "My Team", icon: TeamIcon, url: `${routes.agentMyTeam}/page/1` },
  { label: "Clients", icon: ClientsIcon, url: `${routes.agentClient}/page/1` },
  { label: "Messages", icon: MessagesIcon, url: `${routes.agentMessage}` },
  { label: "Reports", icon: ReportsIcon, url: `${routes.agentReport}` },
];

export const officeStaffMenu = [
  { label: "Dashboard", icon: DashboardIcon, url: "/dashboard" },
  { label: "Projects", icon: ProjectIcon, url: `${routes.staffProject}/page/1` },
  { label: "Opportunity", icon: OpportunityIcon, url: `${routes.staffOpportunity}/page/1` },
  { label: "Appointment", icon: AppointmentIcon, url: `${routes.staffAppointment}/page/1` },
  { label: "My Team", icon: TeamIcon, url: `${routes.staffMyTeam}/page/1` },
  { label: "Clients", icon: ClientsIcon, url: `${routes.staffClient}/page/1` },
  { label: "Messages", icon: MessagesIcon, url: `${routes.staffMessage}` },
  { label: "Reports", icon: ReportsIcon, url: `${routes.staffReport}` },
];

export const officeFormanMenu = [
  { label: "Dashboard", icon: DashboardIcon, url: "/dashboard" },
  { label: "Project", icon: ProjectIcon, url: `${routes.formanProject}/page/1` },
  { label: "My Team", icon: TeamIcon, url: `${routes.formanMyTeam}/page/1` },
  { label: "Message", icon: MessagesIcon, url: `${routes.formanMessage}` },
  { label: "Reports", icon: ReportsIcon, url: `${routes.formanReport}` },
];
