import React, { useState } from "react";
import { Table, InputGroup, Form, Modal, Button } from "react-bootstrap";
import { Drawer, Pagination } from "antd";
import "./DemoRequest.scss";
import PaymentPopup from "../../../../components/Modal/paymentPopup/PaymentPopup";
import SchedulDemo from "./ScheduleDemo";

const DemoRequest = () => {
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <section className="demo-request-section">
      <div className="list-table">
        <div className="table-header">
          <div className="search-section">
            <InputGroup>
              <Form.Control
                placeholder="Search"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text id="basic-addon2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
        <Table size="lg" className="demo-request-table" responsive>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Email ID</th>
              <th>Message</th>
              <th>Date</th>
              <th>Demo Status</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 10 }).map((item, i) => (
              <tr key={i}>
                <td>Sonam Gupta</td>
                <td>sonam@gmail.com</td>
                <td>
                  I wanted to spend the first part of this call going through
                  some of the technical..
                </td>
                <td>08 July,2023 1 day ago</td>
                <td>New</td>
                <td>
                  <Button className="send-btn" onClick={showDrawer}>
                    Schedule Demo
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-main">
          <Pagination total={100} />
        </div>
      </div>
      <Drawer
        width={800}
        className="notification-drawer"
        title="Scheduled New Demo"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <SchedulDemo />
      </Drawer>
      <PaymentPopup show={modalShow} onHide={() => setModalShow(false)} />
      <Modal show={show} onHide={handleClose} className="delete-modal" centered>
        <Modal.Body>
          <h2>Delete</h2>
          <div className="confirmation">
            Are you sure you want to delete this item ?
          </div>
          <div className="footer-btn d-flex">
            <Button className="cancel-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button>Delete</Button>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default DemoRequest;
