import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, Button, InputGroup, Form } from "react-bootstrap";
import { Pagination } from "antd";

import "./AppointmentScreen.scss";
import PlusIcon from "../../../assets/Icon/plus-icon.svg";
import AppointmentTable from "../../../common/appoinment/AppointmentTable";
import { useDispatch, useSelector } from "react-redux";
import { reqToGetAppoinments } from "../../../reduxToolkit/services/appoinmentServices";
import { routes } from "../../../constants";
import Loader from "../../../components/Loader/Loader";

const AppointmentScreen = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { page_number } = params;

  const appoinmentReducer = useSelector((state) => state.appoinment);

  const { appoinments } = appoinmentReducer;
  const { totalAppoinmentCount } = appoinmentReducer;

  const [page, setPage] = useState(page_number);
  const [tab, setTab] = useState("today");
  const [query, setQuery] = useState("");

  const navigateToDetailPage = (apt) => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentAppointment}/appointment/${apt.apmtId}`, { state: { apt } });
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffAppointment}/appointment/${apt.apmtId}`, { state: { apt } });
    } else {
      navigate(`/appointment-detail/${apt.apmtId}`, { state: { apt } });
    }
  };
  const addAppointment = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentAddAppointment}`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffAppointment}/set-appointment`);
    } else {
      navigate("/add-appointment");
    }
  };

  useEffect(() => {
    dispatch(reqToGetAppoinments({ page: page, limit: 10, type: tab, query: query }));
  }, []);

  const onPageChange = (newPage) => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentAppointment}/page/${newPage}`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffAppointment}/page/${newPage}`);
    } else {
      navigate(`${routes.appointment}/page/${newPage}`);
    }
    setPage(newPage);
    dispatch(reqToGetAppoinments({ page: newPage, limit: 10, type: tab, query: query }));
  };

  const onTabChange = (val) => {
    setTab(val);
    dispatch(reqToGetAppoinments({ page: page, limit: 10, type: val, query: query }));
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (page_number !== "1") {
      if (userRole === "AGENCY_AGENT") {
        navigate(`${routes.agentAppointment}/page/1`);
      } else if (userRole === "AGENCY_STAFF") {
        navigate(`${routes.staffAppointment}/page/1`);
      } else {
        navigate(`${routes.appointment}/page/1`);
      }
    }
    setPage("1");
    dispatch(reqToGetAppoinments({ page: page, limit: 10, type: tab, query: e.target.value }));
  };

  const appointmentTabs = [
    { title: "Today", eventKey: "today" },
    { title: "Upcoming", eventKey: "upcoming" },
    { title: "Completed", eventKey: "completed" },
    // { title: "Cancelled", eventKey: "cancelled" },
  ];

  return (
    <>
      {appoinmentReducer.loader && <Loader />}
      <section className="appointment-table-main">
        <div className="list-header">
          <h1 className="heading">Appointment</h1>
          <div className="create-project-btn">
            <Button onClick={addAppointment}>
              <img src={PlusIcon} alt="" />
              Set Appointment
            </Button>
          </div>
        </div>
        <div className="tab-main">
          <Tabs id="uncontrolled-tab-example" className="mb-3 appoinment-tab" onSelect={(e) => onTabChange(e)}>
            {appointmentTabs.map((appointmentTab, i) => (
              <Tab eventKey={appointmentTab.eventKey} title={appointmentTab.title} key={i}>
                <div className="list-table">
                  <AppointmentTable tab={tab} data={appoinments} goToDetailPage={navigateToDetailPage} />
                  <div className="pagination-main">
                    <Pagination total={totalAppoinmentCount} onChange={onPageChange} current={+page} />
                  </div>
                </div>
              </Tab>
            ))}
            <Tab
              className="search"
              title={
                <div className="search-section d-sm-block">
                  <InputGroup>
                    <Form.Control
                      placeholder="Search"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={query}
                      onChange={onQueryChange}
                    />
                    <InputGroup.Text id="basic-addon2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              }
            ></Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default AppointmentScreen;
