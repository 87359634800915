import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./SetAppoinmentModal.scss"
import { DatePicker, Form, Input, TimePicker } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const SetAppoinmentModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      className="set-appoinment-modal p-0"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h1>Set Appoinment</h1>
        <Form className="add-opportunity-form">
          <Form.Item
            className="form-date-picker select-date-time"
            name="date"
            label="Select Date"
            rules={[
              {
                required: true,
                message: "Please select a date!",
              },
            ]}
          >
            <DatePicker placeholder="dd-mm-yyyy" format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            className="form-date-picker select-date-time"
            name="time"
            label="Select Time"
            rules={[
              {
                required: true,
                message: "Please select a time!",
              },
            ]}
          >
            <TimePicker format={"h:mm a"} minuteStep={15} />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Description*"
            name="description"
            rules={[
              {
                required: true,
                message: "Description is required!",
                transform: (value) => {
                  if (value) {
                    return value.trim();
                  }
                  return value;
                },
              },
            ]}
          >
            <TextArea rows={4} placeholder="Write description" />
          </Form.Item>
        </Form>
        <div className="add-member-footer-btn d-flex justify-content-center gap-4">
          <Button className="cancel-btn" onClick={props.onHide}>
            Cancel
          </Button>
          <Button>Save</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SetAppoinmentModal