import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import "./PaymentScreen.scss";

import tableIcon from "../../../assets/Icon/tabler_calendar-due.png";
import PaymentPopup from "../../../components/Modal/paymentPopup/PaymentPopup";
import data from "../../../data/payment.json";
import PaymentTable from "../../../common/payment/PaymentTable";
import PaymentTableHeader from "../../../common/payment/PaymentTableHeader";

const PaymentScreen = () => {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const duePayment = () => {
    navigate("/due-payment");
  };

  return (
    <section className="payment-screen">
      <div className="list-header">
        <h1 className="heading">Payment</h1>
        <div className="create-project-btn">
          <Button onClick={duePayment}>
            <img src={tableIcon} alt="" />
            Check All Dues
          </Button>
        </div>
      </div>
      <div className="list-table">
        <PaymentTableHeader />
        <PaymentTable data={data} setModalShow={setModalShow} />
        <div className="pagination-main">
          <Pagination total={100} />
        </div>
      </div>
      <PaymentPopup show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
};

export default PaymentScreen;
