import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import "./AddClient.scss";
import { Button } from "react-bootstrap";
import { Form, Input, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import removeImg from "../../../../assets/Icon/remove-img.svg";
import { reqToSignupClient, reqToVerifyClientToken } from "../../../../reduxToolkit/services/clientServices";
import countries from "../../../../data/contries.json";

const AddClient = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { token } = params;
  const { email } = useSelector((state) => state.client);
  const client = useSelector((state) => state.client);

  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [image, setImage] = useState("");
  const [imagePrev, setImagePrev] = useState("");
  const [imgErr, setImgErr] = useState("");

  useEffect(() => {
    dispatch(reqToVerifyClientToken({ token, navigate }));
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      email: email,
    });
  }, [email]);

  const handleImgOnChange = (e) => {
    const fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 10) {
      setImgErr("The maximum size for an upload is 10 MB");
    } else {
      setImgErr("");
      setImage(e.target.files[0]);
      setImagePrev(URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImage = () => {
    setImgErr("");
    setImage("");
    setImagePrev("");
  };

  const handleSearch = (value) => {
    const filtered = countries.filter((country) => country.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredCountries(filtered);
  };

  const onSubmitHandler = (values) => {
    console.log("values12345", values);
    const formData = new FormData();
    formData.append("image", image);
    formData.append("token", token);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", email);
    formData.append("password", values.password);
    formData.append("phone", values.phone);
    formData.append("street", values.street);
    formData.append("area", values.area);
    formData.append("city", values.city);
    formData.append("state", values.state);
    formData.append("country", values.country);
    formData.append("zipCode", values.zipCode);
    dispatch(reqToSignupClient({ data: formData, navigate }));
  };

  return (
    <>
      {client.loader && <Loader />}
      <section className="add-client-screen">
        <div className="add-client-header">
          <h1>Add New Client</h1>
        </div>
        <div className="d-flex gap-5 flex-md-nowrap flex-wrap">
          <div className="upload-image m-md-0 m-auto">
            <div className="image d-flex justify-content-center align-items-center">
              {imagePrev ? (
                <div className="prev-image">
                  <img src={imagePrev} alt="" />
                  <span className="remove-icon" role="button" onClick={() => removeImage()}>
                    <img src={removeImg} alt="" />
                  </span>
                </div>
              ) : (
                <Icon.Image />
              )}
            </div>
            <Input
              type="file"
              id="image-upload-input"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => handleImgOnChange(e)}
              onClick={(e) => (e.target.value = null)}
            />
            <label htmlFor="image-upload-input">Upload profile image</label>
            {imgErr && <p className="form-error">{imgErr}</p>}
          </div>
          <Form className="add-client-form w-100" form={form} onFinish={onSubmitHandler}>
            <div className="d-flex gap-4">
              <Form.Item
                className="w-50 mb-4"
                label="First Name*"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "First name is required!",
                    transform: (value) => {
                      if (value) {
                        return value.trim();
                      }
                      return value;
                    },
                  },
                ]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
              <Form.Item
                className="w-50 mb-4"
                label="Last Name*"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Last name is required!",
                    transform: (value) => {
                      if (value) {
                        return value.trim();
                      }
                      return value;
                    },
                  },
                ]}
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
            </div>
            <h4>Contact Information</h4>
            <div className="d-flex gap-4">
              <Form.Item className="w-50 mb-4" label="Email*" name="email">
                <Input placeholder="abc@gmail.com" disabled />
              </Form.Item>
              <Form.Item
                className="w-50 mb-4"
                label="password*"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    min: 6,
                    message: "Password must be at least 6 characters!",
                  },
                ]}
              >
                <Input placeholder="password" type="password" />
              </Form.Item>
              <Form.Item
                className="w-50 mb-4"
                label="Phone*"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Phone number is required!",
                    transform: (value) => {
                      if (value) {
                        return value.trim();
                      }
                      return value;
                    },
                  },
                ]}
              >
                <Input
                  placeholder="+01 00000 00000"
                  onKeyPress={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item
              className="w-100 mb-4"
              label="Street Address*"
              name="street"
              rules={[
                {
                  required: true,
                  message: "Street Address is required!",
                  transform: (value) => {
                    if (value) {
                      return value.trim();
                    }
                    return value;
                  },
                },
              ]}
            >
              <Input placeholder="ie. 123, Street" />
            </Form.Item>
            <Form.Item
              className="w-100 mb-4"
              label="Apt, Suite, etc (Option)"
              name="area"
              rules={[
                {
                  required: true,
                  message: "Area is required!",
                  transform: (value) => {
                    if (value) {
                      return value.trim();
                    }
                    return value;
                  },
                },
              ]}
            >
              <Input placeholder="ie. 123, Street" />
            </Form.Item>
            <div className="d-flex gap-4">
              <Form.Item
                className="w-50 mb-4"
                label="City*"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "City is required!",
                    transform: (value) => {
                      if (value) {
                        return value.trim();
                      }
                      return value;
                    },
                  },
                ]}
              >
                <Input placeholder="Enter City Name" />
              </Form.Item>
              <Form.Item
                className="w-50 mb-4"
                label="State / Rigion"
                name="state"
                rules={[
                  {
                    required: true,
                    message: "State / Rigion is required!",
                    transform: (value) => {
                      if (value) {
                        return value.trim();
                      }
                      return value;
                    },
                  },
                ]}
              >
                <Input placeholder="Enter State Name" />
              </Form.Item>
            </div>
            <div className="d-flex gap-4">
              <div className="w-50 mb-4">
                <Form.Item
                  className="country-item-input"
                  label="Country*"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please select Country!",
                    },
                  ]}
                >
                  <Select name="country" allowClear showSearch filterOption={false} onSearch={handleSearch}>
                    <Select.Option value="" disabled>
                      Select Country
                    </Select.Option>
                    {filteredCountries.map((country, i) => (
                      <Select.Option key={i} value={country.name}>
                        {country.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                className="w-50 mb-4"
                label="Zip Code*"
                name="zipCode"
                rules={[
                  {
                    required: true,
                    message: "zipCode is required!",
                    transform: (value) => {
                      if (value) {
                        return value.trim();
                      }
                      return value;
                    },
                  },
                ]}
              >
                <Input placeholder="Enter 6 digit Zip code" />
              </Form.Item>
            </div>
            <div className="add-member-footer-btn d-flex w-50 ms-auto gap-4">
              <Button className="cancel-btn">Cancel</Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default AddClient;
