import React from "react";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import "./ProjectDetailDocument.scss";

const ProjectDetailDocument = ({ setUploadDocumentShow, userType }) => {
  return (
    <div className="project-detail-document">
      <div className="d-flex justify-content-between align-items-center total-document">
        <h4>06 Documents</h4>
        {userType !== "client" && (
          <Button onClick={() => setUploadDocumentShow(true)}>
            <Icon.PlusLg />
            Upload New Document
          </Button>
        )}
      </div>
      <div className="document-section d-flex flex-wrap">
        <div className="document-card">
          <div className="img">
            <Icon.FiletypePdf />
          </div>
          <div className="name">Document Name</div>
          <div className="d-flex justify-content-between">
            <div className="date">18 July, 2023</div>
            <div className="time">14:29</div>
          </div>
        </div>
        <div className="document-card">
          <div className="img">
            <Icon.FiletypePdf />
          </div>
          <div className="name">Document Name</div>
          <div className="d-flex justify-content-between">
            <div className="date">18 July, 2023</div>
            <div className="time">14:29</div>
          </div>
        </div>
        <div className="document-card">
          <div className="img">
            <Icon.FiletypePdf />
          </div>
          <div className="name">Document Name</div>
          <div className="d-flex justify-content-between">
            <div className="date">18 July, 2023</div>
            <div className="time">14:29</div>
          </div>
        </div>
        <div className="document-card">
          <div className="img">
            <Icon.FiletypePdf />
          </div>
          <div className="name">Document Name</div>
          <div className="d-flex justify-content-between">
            <div className="date">18 July, 2023</div>
            <div className="time">14:29</div>
          </div>
        </div>
        <div className="document-card">
          <div className="img">
            <Icon.FiletypePdf />
          </div>
          <div className="name">Document Name</div>
          <div className="d-flex justify-content-between">
            <div className="date">18 July, 2023</div>
            <div className="time">14:29</div>
          </div>
        </div>
        <div className="document-card">
          <div className="img">
            <Icon.FiletypePdf />
          </div>
          <div className="name">Document Name</div>
          <div className="d-flex justify-content-between">
            <div className="date">18 July, 2023</div>
            <div className="time">14:29</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailDocument;
