import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../helper/axios";
import { toastify } from "../../common/commonFunction";
import { apiendpoints, messages, routes } from "../../constants";

//registerUser
export const reqToAddMember = createAsyncThunk("reqToAddMember", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.addNewMember, data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.myTeam.addMember);
      if (data.onSuccess) {
        data.onSuccess(response.data);
      }
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

// Request to verify invitation token
export const reqToVerifyMemberToken = createAsyncThunk("reqToVerifyMemberToken", async (data) => {
  try {
    const response = await Axios.post(`${apiendpoints.verifyInvitationToken}${data.token}`);
    if (response.status === 201 || response.status === 200) {
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    data.navigate(routes.login);
    throw error;
  }
});

// Signup Member
export const reqToSignupMember = createAsyncThunk("reqToSignupMember", async (data, thunkAPI) => {
  try {
    const response = await Axios.post(apiendpoints.signupMember, data.data);

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.myTeam.signUpMember);
      data.navigate(routes.login);
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

// get Members
export const reqToGetAllMembers = createAsyncThunk("reqToGetAllMembers", async (data) => {
  try {
    const response = await Axios.get(`${apiendpoints.getAllMember}?page=${data.page || 1}&limit=${data.limit || 10}`, authHeaders());
    if (response.status === 201 || response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});

// get Members by type
export const reqToGetMembersBytype = createAsyncThunk("reqToGetMembersBytype", async (data) => {
  try {
    const response = await Axios.get(
      `${apiendpoints.getAllMember}${data.memberType ? "/" + data.memberType : ""}?page=${data.page || 1}&limit=${data.limit || 10}`,
      authHeaders()
    );
    if (response.status === 201 || response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});
