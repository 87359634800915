import React from "react";
import { Button, Table } from "react-bootstrap";
import { Progress } from "antd";
import "./AssignProjectTable.scss";

const AssignProjectTable = () => {
  return (
    <Table size="lg" className="assign-project-table">
      <thead>
        <tr>
          <th>Project Name</th>
          <th>Location / Area</th>
          <th>Duration</th>
          <th>Completion</th>
          <th>Task</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={30} />
          </td>
          <td>10 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td>
            <div className="completed">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={60} />
          </td>
          <td>10 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={10} />
          </td>
          <td>10 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td>
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={50} />
          </td>
          <td>10 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td>
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={30} />
          </td>
          <td>10 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td>
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td>
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>

        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td>
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <Button className="assign-btn">Assign</Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default AssignProjectTable;
