import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { FILE_BASE_URL } from "../../../config";
import profileImg from "../../../assets/images/profile.jpg";
import "./TeamDCard.scss";

const TeamDCard = ({ goToMemberAcvtivities, goToAssignProject, member, userRole }) => {
  return (
    <div className="team-detail-card">
      <div className="d-flex profile-container flex-xl-row flex-lg-column flex-sm-nowrap flex-wrap">
        <div className="image m-xl-0 mx-lg-auto m-sm-0 mx-auto">
          <img src={member.image ? FILE_BASE_URL + member.image : profileImg} alt="" />
        </div>
        <div className="detail">
          <Button>{member.member?.mid ? member.member.mid : "-"}</Button>
          <h2>{member.firstName + " " + member.lastName}.</h2>
          <h4>Sr. Engineer</h4>
          <h4>{member.phone}</h4>
          <h4>{member.email}</h4>
        </div>
        <Dropdown className="more-dropdown" align="end">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <Icon.ThreeDots />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Edit Profile</Dropdown.Item>
            <Dropdown.Item>Block</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex profile-experience flex-xl-nowrap flex-wrap">
        <div className="experience w-xl-25 w-lg-50 w-sm-25 w-50">
          <h5>Experience</h5>
          <h4>4.5 Years</h4>
        </div>
        <div className="kpi w-xl-25 w-lg-50 w-sm-25 w-50">
          <h5>KPI</h5>
          <h4>Excellent</h4>
        </div>
        <div className="last-login w-xl-50 w-lg-100 w-sm-50 w-100">
          <h5>Last Login</h5>
          <h4>Today 06:12 PM</h4>
        </div>
      </div>
      <div className="profile-footer">
        <div className="d-flex gap-4">
          <div className="part">
            <h5 className="number">05</h5>
            <h4>Total Project</h4>
          </div>
          <div className="part failed">
            <h5 className="number">05</h5>
            <h4>Failed Project</h4>
          </div>
          <div className="part success">
            <h5 className="number">05</h5>
            <h4>Success Project</h4>
          </div>
        </div>
        {userRole !== "AGENCY_AGENT" && userRole !== "AGENCY_FOREMAN" && (
          <div className="d-flex footer-btn gap-4">
            {userRole !== "AGENCY_STAFF" && (
              <Button className="activity" onClick={() => goToMemberAcvtivities()}>
                Check member activities
              </Button>
            )}
            <Button className="assign" onClick={() => goToAssignProject()}>
              Assign new project
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamDCard;
