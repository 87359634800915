import React, { useState } from "react";
import PaymentPopup from "../../../components/Modal/paymentPopup/PaymentPopup";
import { Pagination } from "react-bootstrap";
import PaymentTable from "../../../common/payment/PaymentTable";
import PaymentTableHeader from "../../../common/payment/PaymentTableHeader";
import data from "../../../data/payment.json";

const ClientPayment = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <section className="payment-screen">
      <div className="list-header">
        <h1 className="heading">Payment</h1>
      </div>
      <div className="list-table">
        <PaymentTableHeader />
        <PaymentTable data={data} setModalShow={setModalShow} />
        <div className="pagination-main">
          <Pagination total={100} />
        </div>
      </div>
      <PaymentPopup show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
};

export default ClientPayment;
