import { createSlice } from "@reduxjs/toolkit";
import { 
  reqToAddOpportunity, 
  reqToGetOpportunities, 
  reqToGetOpportunityDetails, 
  reqToUpdateOpportunity,
  reqToAddReminder,
  reqToUpdateReminder,
  reqToDeleteReminder,
  reqToAddNote,
  reqToUpdateNote,
  reqToDeleteNote } from "../services/opportunityServices";

const initialState = {
  loader: false,
  error: "",
  opportunities: [],
  totalOpportunityCount: 0,
  opportunityDetail: {},
};

//  Create opportunity Slice
const opportunitySlice = createSlice({
  name: "opportunity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //req To add opportunity
    builder.addCase(reqToAddOpportunity.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddOpportunity.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToAddOpportunity.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Get opportunity
    builder.addCase(reqToGetOpportunities.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetOpportunities.fulfilled, (state, action) => {
      state.loader = false;
      state.opportunities = action.payload.rows;
      state.totalOpportunityCount = action.payload.count;
    });
    builder.addCase(reqToGetOpportunities.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To update opportunity
    builder.addCase(reqToUpdateOpportunity.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUpdateOpportunity.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToUpdateOpportunity.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Get opportunity details
    builder.addCase(reqToGetOpportunityDetails.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetOpportunityDetails.fulfilled, (state, action) => {
      state.loader = false;
      state.opportunityDetail = action.payload;
    });
    builder.addCase(reqToGetOpportunityDetails.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To add reminder
    builder.addCase(reqToAddReminder.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddReminder.fulfilled, (state, action) => {
      state.loader = false;
      state.opportunityDetail = action.payload.data;
    });
    builder.addCase(reqToAddReminder.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To update reminder
    builder.addCase(reqToUpdateReminder.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUpdateReminder.fulfilled, (state, action) => {
      state.loader = false;
      state.opportunityDetail = action.payload.data;
    });
    builder.addCase(reqToUpdateReminder.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To delete reminder
    builder.addCase(reqToDeleteReminder.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToDeleteReminder.fulfilled, (state, action) => {
      state.loader = false;
      state.opportunityDetail = action.payload.data;
    });
    builder.addCase(reqToDeleteReminder.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To add note
    builder.addCase(reqToAddNote.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddNote.fulfilled, (state, action) => {
      state.loader = false;
      state.opportunityDetail = action.payload.data;
    });
    builder.addCase(reqToAddNote.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To update note
    builder.addCase(reqToUpdateNote.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUpdateNote.fulfilled, (state, action) => {
      state.loader = false;
      state.opportunityDetail = action.payload.data;
    });
    builder.addCase(reqToUpdateNote.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To delete note
    builder.addCase(reqToDeleteNote.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToDeleteNote.fulfilled, (state, action) => {
      state.loader = false;
      state.opportunityDetail = action.payload.data;
    });
    builder.addCase(reqToDeleteNote.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

  },
});

export default opportunitySlice.reducer;
