import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import "./Help.scss"
import MessageScreen from './components/AdminMessage';
import DemoRequest from './components/DemoRequest';
const Help = () => {
  return (
    <section className='help-section'>
      <div className="table-main-tab">
        <Tabs
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="bot-message" title="Bot Message">
            <MessageScreen />
          </Tab>
          <Tab eventKey="message" title="Message (10)">
            <MessageScreen />
          </Tab>
          <Tab eventKey="demo-request" title="Demo Request (5)">
            <DemoRequest />
          </Tab>
        </Tabs>
      </div>
    </section>
  )
}

export default Help