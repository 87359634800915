import React from "react";
import { Avatar } from "antd";
import applicationIcon from "../../../../assets/Icon/application.png";
import locationIcon from "../../../../assets/Icon/location.png";
import workerIcon from "../../../../assets/Icon/worker.png";
import project1 from "../../../../assets/images/project1.png";
import "./ProjectDCard.scss";
import { Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

const ProjectDCard = ({ showAssignMember }) => {
  return (
    <div className="project-detail-card d-flex flex-md-nowrap flex-wrap">
      <div className="img">
        <img src={project1} alt="" />
      </div>
      <div className="detail">
        <div className="d-flex justify-content-between flex-xl-nowrap flex-wrap gap-md-4 gap-3">
          <div className="left d-flex flex-column justify-content-between">
            <h3>Project Name</h3>
            <div className="d-flex mb-2">
              <img src={applicationIcon} alt="" />
              <div className="title">
                <p>Kitchen Module (SIZE Sq.)</p>
                <p>Bathroom Module (SIZE Sq.)</p>
              </div>
            </div>
            <div className="d-flex mb-2">
              <img src={locationIcon} alt="" />
              <p>203-Dallas, Texas</p>
            </div>
            <div className="d-flex mb-4">
              <img src={workerIcon} alt="" />
              <p>Project Manager Name</p>
            </div>
          </div>
          <div className="right">
            <Avatar.Group className="d-flex align-items-center">
              <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
              <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
              <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=3" />
              <span className="title">10 People are working</span>
            </Avatar.Group>
            <div className="date">
              <h3>18 June,2023</h3>
              <h4>Start Date</h4>
            </div>
            <div className="date">
              <h3>10 Jan,2024</h3>
              <h4>End Date</h4>
            </div>
            <div className="date">
              <h3>7 Month</h3>
              <h4>Duration</h4>
            </div>
          </div>
        </div>
        <div className="d-flex detail-icon flex-wrap">
          <Button className="assign-btn" onClick={() => showAssignMember(true)}>
            <Icon.PlusLg />
            Assign Manager
          </Button>
          <Button className="change-btn">
            <Icon.ArrowLeftRight />
            Change Manager
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectDCard;
