import React, { useEffect, useState } from "react";
import "./CreateProposal.scss";
import addIcon from "../../../../../assets/Icon/add-icon.png";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { Checkbox, Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reqToGetAllClients } from "../../../../../reduxToolkit/services/clientServices";
import { reqToGetMembersBytype } from "../../../../../reduxToolkit/services/myTeamServices";
import { routes } from "../../../../../constants";
import { durationData } from "../../../../../constants/constants";
import { reqToAddProposal } from "../../../../../reduxToolkit/services/proposalServices";
import Loader from "../../../../../components/Loader/Loader";

const CreateProposal = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clientReducer = useSelector((state) => state.client);
  const { clients } = clientReducer;
  const teamReducer = useSelector((state) => state.myTeam);
  const { membersByType } = teamReducer;
  const proposalReducer = useSelector((state) => state.proposal);

  const [newClient, setNewClient] = useState(true);

  useEffect(() => {
    dispatch(reqToGetAllClients({ page: 1, limit: 1000000 }));
    dispatch(reqToGetMembersBytype({ page: 1, limit: 1000000, memberType: "managers" }));
  }, []);

  const goBackHandler = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentOpportunity}/page/1`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffOpportunity}/page/1`);
    } else {
      navigate(`${routes.opportunity}/page/1`);
    }
  };

  const handleChange = () => {
    setNewClient(!newClient);
    if (form) {
      form.setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        client: "",
      });
    }
  };

  const handleClientChange = (val) => {
    const selectedClient = clients.find((client) => client.client.cid === val);

    if (form) {
      form.setFieldsValue({
        client: val,
        firstName: selectedClient.firstName,
        lastName: selectedClient.lastName,
        email: selectedClient.email,
        phone: selectedClient.phone,
      });
    }
  };

  const onSubmitHandler = (values) => {
    const data = {
      cid: values.client ? values.client : null,
      uid: values.manager,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      details: values.projectOverview,
      scope: values.scopeOfWork,
      remark: values.remark ? values.remark : null,
      budget: +values.budget,
      duration: values.duration,
      sections: values.sections,
    };

    const route =
      userRole === "AGENCY_AGENT"
        ? `${routes.agentOpportunity}/page/1`
        : userRole === "AGENCY_STAFF"
        ? `${routes.staffOpportunity}/page/1`
        : `${routes.opportunity}/page/1`;

    dispatch(reqToAddProposal({ data: data, navigate, route: route }));
  };

  return (
    <>
      {(clientReducer.loader || teamReducer.loader || proposalReducer.loader) && <Loader />}
      <section className="create-proposal-screen">
        <div className="create-proposal-header">
          <h1 className="heading">Create Proposal</h1>
        </div>
        <Form className="create-proposal-form" form={form} onFinish={onSubmitHandler}>
          {/* <div className="d-flex gap-4 w-lg-50">
          <Form.Item className="w-50 mb-4" label="Proposal No*">
            <Input placeholder="PB-102" />
          </Form.Item>
          <Form.Item className="w-50 mb-4" label="Select Project Date*">
            <DatePicker placeholder="dd - mm - yyyy" />
          </Form.Item>
        </div> */}
          <div className="d-flex align-items-center gap-5 client-information mb-2">
            <h4 className="border-0 mb-1">Client Information</h4>
            <Checkbox onChange={handleChange}>Existing Client</Checkbox>
          </div>
          {!newClient ? (
            <>
              <div className="d-flex gap-md-4 flex-md-nowrap flex-wrap">
                <div className="w-md-50 w-100 mb-4">
                  <Form.Item
                    className="client-item-input"
                    label="Select Client*"
                    name="client"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Client!",
                      },
                    ]}
                  >
                    <Select onChange={handleClientChange}>
                      <Select.Option value="" disabled>
                        Select Client Name
                      </Select.Option>
                      {clients.length !== 0 &&
                        clients.map((client, i) => {
                          return (
                            <Select.Option key={i} value={client.client.cid}>
                              {client.firstName + " " + client.lastName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="d-flex w-md-50 w-100 gap-4">
                  <Form.Item className="w-50 mb-4" label="First Name*" name="firstName">
                    <Input placeholder="Enter First Name" name="firstName" disabled />
                  </Form.Item>

                  <Form.Item className="w-50 mb-4" label="Last Name*" name="lastName">
                    <Input placeholder="Enter Last Name" name="lastName" disabled />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex gap-4">
                <Form.Item className="w-50 mb-4" label="Email*" name="email">
                  <Input placeholder="abc@gmail.com" disabled />
                </Form.Item>

                <Form.Item className="w-50 mb-4" label="Phone*" name="phone">
                  <Input placeholder="+01 00000 00000" disabled />
                </Form.Item>
              </div>
            </>
          ) : (
            <div className="d-flex gap-md-4 flex-md-nowrap flex-wrap">
              <div className="d-flex w-md-50 w-100 gap-4">
                <Form.Item
                  className="w-50 mb-4"
                  label="First Name*"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "First name is required!",
                      transform: (value) => {
                        if (value) {
                          return value.trim();
                        }
                        return value;
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter first name" name="firstName" />
                </Form.Item>
                <Form.Item
                  className="w-50 mb-4"
                  label="Last Name*"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Last name is required!",
                      transform: (value) => {
                        if (value) {
                          return value.trim();
                        }
                        return value;
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter last name" name="lastName" />
                </Form.Item>
              </div>
              <div className="d-flex w-md-50 w-100 gap-4">
                <Form.Item
                  className="w-50 mb-4"
                  label="Email*"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email!",
                    },
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input placeholder="abc@gmail.com" name="email" />
                </Form.Item>
                <Form.Item
                  className="w-50 mb-4"
                  label="Phone*"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone number is required!",
                      transform: (value) => {
                        if (value) {
                          return value.trim();
                        }
                        return value;
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="+01 00000 00000"
                    name="phone"
                    onKeyPress={(e) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          <div className="w-md-50 w-100 mb-4">
            <Form.Item
              className="client-item-input"
              label="Select Manager*"
              name="manager"
              rules={[
                {
                  required: true,
                  message: "Please select a Manager!",
                },
              ]}
            >
              <Select name="manager" allowClear>
                <Select.Option value="" disabled>
                  Select Manager Name
                </Select.Option>
                {membersByType.length !== 0 &&
                  membersByType.map((manager, i) => {
                    return (
                      <Select.Option key={i} value={manager.uid}>
                        {manager.firstName + " " + manager.lastName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <h4>Proposal Details</h4>
          {/* <Form.Item className="mb-4" label="Project Name*">
          <Input placeholder="Enter Project Name" />
        </Form.Item> */}
          <Form.Item
            className="mb-4"
            label="Project Overview*"
            name="projectOverview"
            rules={[
              {
                required: true,
                message: "Please describe project overview!",
                transform: (value) => {
                  if (value) {
                    return value.trim();
                  }
                  return value;
                },
              },
            ]}
          >
            <TextArea rows={4} placeholder="Write project overview" />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Problem Statement*"
            name="scopeOfWork"
            rules={[
              {
                required: true,
                message: "Please describe scope of work!",
                transform: (value) => {
                  if (value) {
                    return value.trim();
                  }
                  return value;
                },
              },
            ]}
          >
            <TextArea rows={4} placeholder="Write scope of work" />
          </Form.Item>
          <h4>Budget and cost estimation</h4>
          <div className="d-flex gap-4">
            <Form.Item
              className="w-50 mb-4"
              label="Cost*"
              name="budget"
              rules={[
                {
                  required: true,
                  message: "Budget amount is required!",
                  transform: (value) => {
                    if (value) {
                      return value.trim();
                    }
                    return value;
                  },
                },
              ]}
            >
              <Input
                placeholder="$ 0000"
                name="budget*"
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-50 mb-4 duration-item-input"
              label="Duration*"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Please select a duration!",
                },
              ]}
            >
              <Select name="duration" allowClear>
                <Select.Option value="" disabled>
                  Select Duration
                </Select.Option>
                {durationData.map((c, i) => {
                  return (
                    <Select.Option key={i} value={c.value}>
                      {c.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <Form.Item className="mb-4" label="Remark" name="remark">
            <TextArea rows={4} placeholder="Write Remark" />
          </Form.Item>
          {/* <Button className="add-btn">
          <img src={addIcon} alt="" /> Add New Task
        </Button> */}
          <Form.List name="sections">
            {(fields, { add, remove }) => (
              <>
                <label className="mb-1">Sections</label>
                {fields.map((field, index) => (
                  <>
                    <div className="form-multiple-input d-flex gap-3">
                      <Form.Item
                        className="mb-4 w-100"
                        name={[index, "title"]}
                        rules={[
                          {
                            required: true,
                            message: "Section Heading is required!",
                            transform: (value) => {
                              if (value) {
                                return value.trim();
                              }
                              return value;
                            },
                          },
                        ]}
                      >
                        <Input placeholder="write section heading" />
                      </Form.Item>
                      <Icon.XCircle onClick={() => remove(field.name)} className="remove-icon" />
                    </div>
                    <Form.Item
                      className="mb-4"
                      name={[index, "details"]}
                      rules={[
                        {
                          required: true,
                          message: "Section Summary is required!",
                          transform: (value) => {
                            if (value) {
                              return value.trim();
                            }
                            return value;
                          },
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="write section Summary" />
                    </Form.Item>
                    <hr className="mb-4" />
                  </>
                ))}
                <div className="add-more-section">
                  <Button className="add-btn more-section" onClick={() => add()}>
                    <img src={addIcon} alt="" /> Add More Section
                  </Button>
                </div>
              </>
            )}
          </Form.List>
          <div className="add-member-footer-btn d-flex w-sm-50 ms-auto gap-4">
            <Button className="cancel-btn" onClick={goBackHandler}>
              Cancel
            </Button>
            <Button type="primary" htmltype="submit">
              Submit
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};

export default CreateProposal;
