import React from 'react'
import "./TeamChat.scss"
const TeamChat = () => {
    return (
        <section className='team-message-main'>
            <div className="messages">
                <div className="message-contents">
                    <div className="time">12:29 pm</div>
                    <p className='sender'>Show Me a (member name) all Details</p>
                </div>
            </div>
            <div className="messages owner">
                <div className="message-contents">
                    <div className="time">12:29 pm</div>
                    <div className="team-member-details">
                        <p>Sure here is Mr. Member Details</p>
                        <p>Name- Full name</p>
                        <p>Position- Sr. Engineer</p>
                        <p>Address- 123, ABC Street, CA-202312</p>
                        <p>Mobile no- 00000000</p>
                        <p>Email ID - info@gamil.com</p>
                        <div className="professinal-details">
                            <p>Experience - 4.5 yrs</p>
                            <p>KPI - Excellent</p>
                            <p>Total Project Assign -30</p>
                            <p>Successful Project- 26 </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamChat