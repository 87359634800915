import React from 'react'
import LeftArrow from "../../../assets/Icon/arrow-left-line.svg"
import editIcon from "../../../assets/Icon/edit.png"
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import "./PlanDetail.scss"
const PlanDetail = () => {
  const navigate = useNavigate();
  const plan = () => {
    navigate("/admin/plan")
  }
  return (
    <section className="plan-detail-main">
      <div className="title">
        <span onClick={plan} style={{ cursor: "pointer" }}><img src={LeftArrow} alt="" /></span>
        <h2>Back to list</h2>
      </div>
      <div className="plan-card d-flex justify-content-between">
        <div className='info w-100'>
          <div className="d-flex">
            <h5>Plan Name</h5>
            <h4>: PRO</h4>
          </div>
          <div className="d-flex">
            <h5>Price</h5>
            <h4>: $ 19</h4>
          </div>
          <div className="d-flex">
            <h5>Period</h5>
            <h4>: Monthly</h4>
          </div>
          <div className="d-flex">
            <h5>Offered Discount</h5>
            <h4>: 30%</h4>
          </div>
          <div className="d-flex">
            <h5>Coupon Code</h5>
            <h4>: BARB30</h4>
          </div>
          <div className="d-flex">
            <h5>Plan Issue Date</h5>
            <h4>: 08 July, 2023</h4>
          </div>
          <div className="d-flex">
            <h5>Expiry On</h5>
            <h4>: --</h4>
          </div>
          <div className="d-flex">
            <h5>Used By (User)</h5>
            <h4>: 245</h4>
          </div>
        </div>
        <img className='edit-icon' src={editIcon} alt="" />
      </div>
      <div className="plan-card d-flex justify-content-between">
        <div className='info w-100'>
          <h2>Features</h2>
          <div className="feature-list d-flex justify-content-between align-items-center">
            <h5>Limited transcription credits</h5>
            <Form className='input-switch'>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <div className="feature-list d-flex justify-content-between align-items-center">
            <h5>800 mins of storage/seat</h5>
            <Form className='input-switch'>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <h2 className='mt-4'>Key Features</h2>
          <div className="feature-list d-flex justify-content-between align-items-center">
            <h5>Record Zoom, GMeet, MS Teams</h5>
            <Form className='input-switch'>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <div className="feature-list d-flex justify-content-between align-items-center">
            <h5>800 mins of storage/seat</h5>
            <Form className='input-switch'>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <div className="feature-list d-flex justify-content-between align-items-center">
            <h5>Transcription for 30+ languages</h5>
            <Form className='input-switch'>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <div className="feature-list d-flex justify-content-between align-items-center">
            <h5>Automated meeting summaries</h5>
            <Form className='input-switch'>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <div className="feature-list d-flex justify-content-between align-items-center">
            <h5>Search within meetings</h5>
            <Form className='input-switch'>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
        </div>
        <img className='edit-icon' src={editIcon} alt="" />
      </div>
    </section>
  )
}

export default PlanDetail