import React from "react";
import { Table } from "react-bootstrap";
import rightArrow from "../../assets/Icon/right-arrow-icon.svg";
import "./ProposalTable.scss";
import moment from "moment";

const ProposalTable = ({ data, goToDetailPage }) => {
  const getStatus = (statusId, classType) => {
    let status = "";
    if (statusId === 1) {
      status = "Pending";
    } else if (statusId === 2) {
      status = "Approved";
    } else if (statusId === 3) {
      status = "Rejected";
    }
    if (classType) {
      return status.toLocaleLowerCase();
    } else {
      return status;
    }
  };

  return (
    <Table size="lg" responsive className="proposal-table">
      <thead>
        <tr>
          <th>Proposal No. </th>
          <th>Client Details</th>
          <th>Contact No.</th>
          <th>Email Id</th>
          <th>Created By</th>
          <th>Created On</th>
          <th>Status</th>
          {/* <th>Last Seen</th> */}
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 ? (
          <tr>
            <td colSpan={8} className="no-data-found">
              No data found
            </td>
          </tr>
        ) : (
          data.map((item, i) => (
            <tr key={i}>
              <td>{item.propId}</td>
              <td>
                <div className="client-details">
                  <div className="client-avatar">
                    <img src="https://picsum.photos/id/1/200/300" alt="" />
                  </div>
                  <div className="client-detail">
                    <div className="name">{item.firstName + " " + item.lastName}</div>
                  </div>
                </div>
              </td>
              <td>{item.phone}</td>
              <td>{item.email}</td>
              <td>
                <div className="created">
                  <div className="creater-name">{item.agency.name}</div>
                  <div className="desg">Agency Owner</div>
                </div>
              </td>
              <td>
                <div className="date">{moment(item.createdAt).format("D MMM, YYYY")}</div>
                <div className="time">{moment(item.createdAt).format("hh:mm A")}</div>
              </td>
              <td className={`${getStatus(item.status, "class")}`}>{getStatus(item.status)}</td>
              {/* <td>{item.lastseen}</td> */}
              <td>
                <img style={{ cursor: "pointer" }} onClick={() => goToDetailPage(item)} src={rightArrow} alt="" />
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default ProposalTable;
