import React from 'react'
import { Button, Modal } from 'react-bootstrap'
//import "./ReminderModal.scss"
import { DatePicker, Form, TimePicker } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const ConfirmationModal = (props) => {
  // const { onSubmitHandler, reminder } = props;

  return (
    <Modal
      show={props.show}
      size="md"
      className="reminder-modal p-0"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
          <h2>{props.msgText}</h2>
          <div className="add-member-footer-btn d-flex justify-content-center gap-4">
            <Button className="primary" onClick={props.onYes}>
              Yes
            </Button>
            <Button className="cancel-btn" onClick={props.onNo}>
              No
            </Button>
          </div>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmationModal