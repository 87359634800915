import React, { useState } from "react";
import { Pagination } from "antd";
import LeftArrow from "../../../../assets/Icon/arrow-left-line.svg";
import "./DuePayments.scss";
import PaymentPopup from "../../../../components/Modal/paymentPopup/PaymentPopup";
import { useNavigate } from "react-router-dom";
import data from "../../../../data/payment.json";
import DuePaymentTable from "../../../../common/payment/DuePaymentTable";
import DuePaymentHeader from "../../../../common/payment/DuePaymentHeader";

const DuePayments = () => {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const payment = () => {
    navigate("/payment");
  };
  return (
    <section className="due-payment-screen">
      <div className="project-heading">
        <span onClick={payment} style={{ cursor: "pointer" }}>
          <img src={LeftArrow} alt="" />
        </span>
        <h2>Summary</h2>
      </div>
      <div className="list-table">
        <DuePaymentHeader />
        <DuePaymentTable data={data} />
        <div className="pagination-main">
          <Pagination total={100} />
        </div>
      </div>
      <PaymentPopup show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
};

export default DuePayments;
