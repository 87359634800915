import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Checkbox, Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./AddOpportunity.scss";
import { routes } from "../../../../constants";
import { reqToGetAllClients } from "../../../../reduxToolkit/services/clientServices";
import { durationData } from "../../../../constants/constants";
import { reqToAddOpportunity } from "../../../../reduxToolkit/services/opportunityServices";
import Loader from "../../../../components/Loader/Loader";
import { reqToGetMembersBytype } from "../../../../reduxToolkit/services/myTeamServices";

const AddOpportunity = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clientReducer = useSelector((state) => state.client);
  const { clients } = clientReducer;
  const opportunityReducer = useSelector((state) => state.opportunity);
  const teamReducer = useSelector((state) => state.myTeam);
  const { membersByType } = teamReducer;

  const [newClient, setNewClient] = useState(true);

  useEffect(() => {
    dispatch(reqToGetAllClients({ page: 1, limit: 1000000 }));
    dispatch(reqToGetMembersBytype({ page: 1, limit: 1000000, memberType: "managers" }));
  }, []);

  const goBackHandler = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentOpportunity}/page/1`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffOpportunity}/page/1`);
    } else {
      navigate(`${routes.opportunity}/page/1`);
    }
  };

  // const onSubmitHandler = () => {};

  const handleChange = () => {
    setNewClient(!newClient);
    if (form) {
      form.setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        client: "",
      });
    }
  };

  const handleClientChange = (val) => {
    const selectedClient = clients.find((client) => client.client.cid === val);

    if (form) {
      form.setFieldsValue({
        client: val,
        firstName: selectedClient.firstName,
        lastName: selectedClient.lastName,
        email: selectedClient.email,
        phone: selectedClient.phone,
      });
    }
  };

  const onSubmitHandler = (values) => {
    const data = {
      cid: values.client ? values.client : null,
      uid: values.manager,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      details: values.projectOverview,
      scope: values.scopeOfWork,
      remark: values.remark ? values.remark : null,
      budget: values.budget,
      duration: values.duration,
    };
    const route =
      userRole === "AGENCY_AGENT"
        ? `${routes.agentOpportunity}/page/1`
        : userRole === "AGENCY_STAFF"
        ? `${routes.staffOpportunity}/page/1`
        : `${routes.opportunity}/page/1`;
    dispatch(reqToAddOpportunity({ data: data, navigate, route: route }));
  };

  return (
    <>
      {(opportunityReducer.loader || teamReducer.loader || opportunityReducer.loader) && <Loader />}
      <section className="add-opportunity-screen">
        <div className="add-opportunity-header">
          <h1 className="heading">Add Opportunity</h1>
        </div>
        <Form className="add-opportunity-form" form={form} onFinish={onSubmitHandler}>
          <div className="d-flex gap-4 w-lg-50"></div>
          <div className="d-flex align-items-center gap-5 client-information mb-2">
            <h4 className="border-0 mb-1">Client Information</h4>
            <Checkbox onChange={handleChange}>Existing Client</Checkbox>
          </div>
          {!newClient ? (
            <>
              <div className="d-flex gap-md-4 flex-md-nowrap flex-wrap">
                <div className="w-md-50 w-100 mb-4">
                  <Form.Item
                    className="client-item-input"
                    label="Select Client*"
                    name="client"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Client!",
                      },
                    ]}
                  >
                    <Select onChange={handleClientChange}>
                      <Select.Option value="" disabled>
                        Select Client Name
                      </Select.Option>
                      {clients.length !== 0 &&
                        clients.map((client, i) => {
                          return (
                            <Select.Option key={i} value={client.client.cid}>
                              {client.firstName + " " + client.lastName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="d-flex w-md-50 w-100 gap-4">
                  <Form.Item className="w-50 mb-4" label="First Name*" name="firstName">
                    <Input placeholder="Enter First Name" name="firstName" disabled />
                  </Form.Item>

                  <Form.Item className="w-50 mb-4" label="Last Name*" name="lastName">
                    <Input placeholder="Enter Last Name" name="lastName" disabled />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex gap-4">
                <Form.Item className="w-50 mb-4" label="Email*" name="email">
                  <Input placeholder="abc@gmail.com" disabled />
                </Form.Item>

                <Form.Item className="w-50 mb-4" label="Phone*" name="phone">
                  <Input placeholder="+01 00000 00000" disabled />
                </Form.Item>
              </div>
            </>
          ) : (
            <div className="d-flex gap-md-4 flex-md-nowrap flex-wrap">
              <div className="d-flex w-md-50 w-100 gap-4">
                <Form.Item
                  className="w-50 mb-4"
                  label="First Name*"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "First name is required!",
                      transform: (value) => {
                        if (value) {
                          return value.trim();
                        }
                        return value;
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter first name" name="firstName" />
                </Form.Item>
                <Form.Item
                  className="w-50 mb-4"
                  label="Last Name*"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Last name is required!",
                      transform: (value) => {
                        if (value) {
                          return value.trim();
                        }
                        return value;
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter last name" name="lastName" />
                </Form.Item>
              </div>
              <div className="d-flex w-md-50 w-100 gap-4">
                <Form.Item
                  className="w-50 mb-4"
                  label="Email*"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email!",
                    },
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input placeholder="abc@gmail.com" name="email" />
                </Form.Item>
                <Form.Item
                  className="w-50 mb-4"
                  label="Phone*"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone number is required!",
                      transform: (value) => {
                        if (value) {
                          return value.trim();
                        }
                        return value;
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="+01 00000 00000"
                    name="phone"
                    onKeyPress={(e) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          <div className="w-md-50 w-100 mb-4">
            <Form.Item
              className="client-item-input"
              label="Select Manager*"
              name="manager"
              rules={[
                {
                  required: true,
                  message: "Please select a Manager!",
                },
              ]}
            >
              <Select name="manager" allowClear>
                <Select.Option value="" disabled>
                  Select Manager Name
                </Select.Option>
                {membersByType.length !== 0 &&
                  membersByType.map((manager, i) => {
                    return (
                      <Select.Option key={i} value={manager.uid}>
                        {manager.firstName + " " + manager.lastName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <h4>Opportunity Details</h4>
          <Form.Item
            className="mb-4"
            label="Project Overview*"
            name="projectOverview"
            rules={[
              {
                required: true,
                message: "Please describe project overview!",
                transform: (value) => {
                  if (value) {
                    return value.trim();
                  }
                  return value;
                },
              },
            ]}
          >
            <TextArea rows={4} placeholder="Write project overview" name="details" />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Scope of work*"
            name="scopeOfWork"
            rules={[
              {
                required: true,
                message: "Please describe scope of work!",
                transform: (value) => {
                  if (value) {
                    return value.trim();
                  }
                  return value;
                },
              },
            ]}
          >
            <TextArea rows={4} placeholder="Write scope of work" name="scope" />
          </Form.Item>
          <h4>Budget and time estimation</h4>
          <div className="d-flex gap-4">
            <Form.Item
              className="w-50 mb-4"
              label="Budget*"
              name="budget"
              rules={[
                {
                  required: true,
                  message: "Budget amount is required!",
                  transform: (value) => {
                    if (value) {
                      return value.trim();
                    }
                    return value;
                  },
                },
              ]}
            >
              <Input
                placeholder="$ 0000"
                name="budget*"
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-50 mb-4 duration-item-input"
              label="Duration*"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Please select a duration!",
                },
              ]}
            >
              <Select name="duration" allowClear>
                <Select.Option value="" disabled>
                  Select Duration
                </Select.Option>
                {durationData.map((c, i) => {
                  return (
                    <Select.Option key={i} value={c.value}>
                      {c.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <Form.Item className="mb-4" label="Remark" name="remark">
            <TextArea rows={4} placeholder="Write Remark" />
          </Form.Item>
          <div className="add-member-footer-btn d-flex w-50 ms-auto gap-4">
            <Button className="cancel-btn" onClick={() => goBackHandler()}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};

export default AddOpportunity;
