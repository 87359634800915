import React, { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import SettingIcon from "../../assets/Icon/setting-icon.svg";
import NotificationIcon from "../../assets/Icon/notification-icon.svg";
import ProfileImage from "../../assets/images/profile.jpg";
import ActiveIcon from "../../assets/Icon/active-status.svg";
import AiMessage from "../../assets/images/ai-message.png";
import Logo from "../../assets/images/logo.svg";
import BlackLogo from "../../assets/images/black-logo.svg";
import LogoutIcon from "../../assets/Icon/logout-icon.svg";
import "./Header.scss";
import { Button, Dropdown, Tab, Tabs } from "react-bootstrap";
import AiPopup from "../Modal/aiPopup/AiPopup";
import { Drawer } from "antd";
import ProfileModal from "../Admin/Modal/ProfileModal";
import PasswordModal from "../Admin/Modal/PasswordModal";
import { useNavigate } from "react-router-dom";
import { reqToUserLogout } from "../../reduxToolkit/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { FILE_BASE_URL } from "../../config";

const Header = ({ menu }) => {
  const dispatch = useDispatch();

  const userDetail = useSelector((state) => state.user.user);

  const [modalShow, setModalShow] = useState(false);
  const [profileModalShow, setProfileModalShow] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const showSidebarDrawer = () => {
    setOpenSidebar(true);
  };
  const closeSidebarDrawer = () => {
    setOpenSidebar(false);
  };

  const handleLogout = () => {
    dispatch(reqToUserLogout());
  };

  //mobile sidebar
  const mobileSidebar = () => {
    const openScreen = (url) => {
      navigate(url);
      closeSidebarDrawer();
    };

    return (
      <>
        <div className="logo">
          <img src={Logo} alt="" />
          <Icon.XLg onClick={closeSidebarDrawer} />
        </div>
        <div className="header-profile-box-mobile">
          <Dropdown className="profile-dropdown" align="end">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <img src={userDetail && userDetail.image ? FILE_BASE_URL + userDetail.image : ProfileImage} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="pt-4 px-0 profile-dropdown-content">
                <Dropdown.Item onClick={() => setProfileModalShow(true)}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={() => setPasswordModal(true)}>Change Password</Dropdown.Item>
              </div>
              <Dropdown.Item className="logout-btn" onClick={() => handleLogout()}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="person-status">
            <span className="person-name">
              {userDetail && userDetail.firstName && userDetail.lastName ? userDetail.firstName + " " + userDetail.lastName : ""}
            </span>
            <div className="stauts">
              <div className="circle">
                <img src={ActiveIcon} alt="" />
              </div>
              <span className="text">Active</span>
            </div>
          </div>
        </div>
        <div className="header-ai-box-mobile justify-content-between" onClick={() => setModalShow(true)}>
          <div className="credits">
            <Icon.CreditCard className="me-2" /> Avail. Credits
          </div>
          <div className="text">405</div>
          <Button>Add More</Button>
        </div>
        <div className="sidebar-slider">
          <div className="menu-main">
            {menu.map((item, i) => (
              <div className="list-main" key={i}>
                <Button disabled={item.disabled} className="list" onClick={() => openScreen(item.url)}>
                  <img className="list-icon" src={item.icon} alt="" />
                  <span className="list-item-name">{item.label}</span>
                </Button>
              </div>
            ))}
          </div>
          <div className="logout-main" role="button" onClick={() => handleLogout()}>
            <div className="logout-list" onClick={() => navigate("/")}>
              <img src={LogoutIcon} alt="" className="logout-icon" />
              <span className="logout-list-name">Logout</span>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <header>
        <div className="header-main-section">
          <div className="mobile-logo d-lg-none">
            <img src={BlackLogo} alt="" />
          </div>
          <div className="icon-group">
            <div className="icon">
              <img src={SettingIcon} alt="" />
            </div>
            <div className="icon">
              <img onClick={showDrawer} src={NotificationIcon} alt="" />
            </div>
            <Icon.List onClick={showSidebarDrawer} className="sidebar-icon d-lg-none" />
          </div>
          <div className="header-ai-box d-lg-flex d-none" onClick={() => setModalShow(true)}>
            <div className="ai-logo">
              <img src={AiMessage} alt="" />
            </div>
            <div className="ai-details">
              <div className="credits">Avail. Credits</div>
              <div className="text">
                <span>405</span>
                <Button>Add More</Button>
              </div>
            </div>
          </div>
          <div className="header-profile-box d-lg-flex d-none">
            <div className="person-status">
              <span className="person-name">{userDetail.firstName + " " + userDetail.lastName}</span>
              <div className="stauts">
                <div className="circle">
                  <img src={ActiveIcon} alt="" />
                </div>
                <span className="text">Active</span>
              </div>
            </div>
            <Dropdown className="profile-dropdown" align="end">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={ProfileImage} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="pt-4 px-0 profile-dropwon-content">
                  <Dropdown.Item onClick={() => setProfileModalShow(true)}>Profile</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPasswordModal(true)}>Change Password</Dropdown.Item>
                </div>
                <Dropdown.Item className="logout-btn" onClick={() => handleLogout()}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Drawer width={500} className="notification-drawer" title="Notification" placement="right" onClose={onClose} open={open}>
          <Tabs id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="all" title="All (10)">
              <div className="notification-body">
                <div className="notification-card">
                  <div className="d-flex align-items-center">
                    <div className="image">
                      <img src={ProfileImage} alt="" />
                    </div>
                    <div className="content">
                      <h3>Mikal Purchase you Pro Plan of $260.00</h3>
                      <div className="time">10 min ago</div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="unread" title="Unread (10)">
              b
            </Tab>
          </Tabs>
        </Drawer>
        <Drawer width={400} className="sidebar-drawer" title="sidebar" placement="left" onClose={closeSidebarDrawer} open={openSidebar}>
          {mobileSidebar()}
        </Drawer>
      </header>
      <AiPopup show={modalShow} onHide={() => setModalShow(false)} />
      <ProfileModal show={profileModalShow} onHide={() => setProfileModalShow(false)} />
      <PasswordModal show={passwordModal} onHide={() => setPasswordModal(false)} />
    </>
  );
};

export default Header;
