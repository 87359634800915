import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./AddClient.scss";
import { Form, Input } from "antd";

const AddClient = (props) => {
  const { onSubmitHandler, form } = props;

  return (
    <Modal {...props} size="md" className="add-member-modal" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <Form form={form} onFinish={onSubmitHandler}>
          <Form.Item
            className="mb-4"
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Email address is required!",
              },
            ]}
          >
            <Input placeholder="abc@gmail.com" />
          </Form.Item>
          <div className="footer-btn d-flex gap-4">
            <Button onClick={props.onHide} className="cancel-btn">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Invite
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddClient;
