import React, { useState } from 'react'
import { Table, InputGroup, Form, Dropdown, Modal, Button } from 'react-bootstrap'
import * as Icon from "react-bootstrap-icons"
import { Pagination } from 'antd'
import "./Agency.scss"
import PaymentPopup from '../../../components/Modal/paymentPopup/PaymentPopup'
import { useNavigate } from 'react-router-dom'

const Agency = () => {
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const agencyDetail = () => {
    navigate("/admin/agency-detail")
  }
  return (
    <section className="agency-screen">
      <div className="list-table">
        <div className="table-header">
          <div className="heading">
            <h2>Agency Lists</h2>
            <h3>Total: <span>600</span> & Active: <span>540</span></h3>
          </div>
          <div className="search-section d-lg-block d-none">
            <InputGroup>
              <Form.Control
                placeholder="Search"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text id="basic-addon2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="agent-dropdown">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span>Filter : </span>Statue
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="droup-item">
                  <span>All</span>
                </div>
                <div className="droup-item">
                  <span>Newest First</span>
                </div>
                <div className="droup-item">
                  <span>Oldest First</span>
                </div>
                <div className="droup-item">
                  <span>Dues Only</span>
                </div>
                <div className="droup-item">
                  <span>Received Only</span>
                </div>
                <div className="droup-item">
                  <span>High to Low</span>
                </div>
                <div className="droup-item">
                  <span>Low to High</span>
                </div>
                <div className="droup-item">
                  <span>A-Z</span>
                </div>

              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Table size="lg" responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Agency Name</th>
              <th>Mobile No.</th>
              <th>Email ID</th>
              <th>Status</th>
              <th>Subscription</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 10 }).map((item, i) => (
              <tr key={i}>
                <td>PB-102</td>
                <td>
                  <div className="client-details">
                    <div className="client-avatar">
                      <img src="https://picsum.photos/id/1/200/300" alt="" />
                    </div>
                    <div className="name">Solo Estate Ltd</div>
                  </div>
                </td>
                <td>01 01252 12523</td>
                <td>Solo@gmail.com</td>
                <td>Pending</td>
                <td>Pro / Monthly</td>
                <td>
                  <div className="d-flex justify-content-center icons">
                    <Icon.EyeFill onClick={agencyDetail} />
                    <Icon.TrashFill onClick={handleShow} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-main">
          <Pagination total={100} />
        </div>
      </div>
      <PaymentPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Modal
        show={show}
        onHide={handleClose}
        className="delete-modal"
        centered
      >
        <Modal.Body>
          <h2>Delete</h2>
          <div className="confirmation">Are you sure you want to delete this item ?</div>
          <div className="footer-btn d-flex">
            <Button className="cancel-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button>Delete</Button>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default Agency