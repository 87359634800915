import React from "react";
import "./AssignProject.scss";
import LeftArrow from "../../../../assets/Icon/arrow-left-line.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import AssignProjectTable from "../../../../common/project/assignProject/AssignProjectTable";
import { useSelector } from "react-redux";
import { routes } from "../../../../constants";

const AssignProject = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const location = useLocation();
  const { member } = location.state;

  const navigate = useNavigate();
  const myTeamDetail = () => {
    if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffMyTeam}/detail/${member.member.mid}`, { state: { member } });
    } else {
      navigate("/myteam/team-detail", { state: { member } });
    }
  };
  return (
    <div className="assign-project-screen">
      <div className="project-heading">
        <span onClick={myTeamDetail} style={{ cursor: "pointer" }}>
          <img src={LeftArrow} alt="" />
        </span>
        <h2>Back to list</h2>
      </div>
      <h1>Assigned Project to Mia Wright</h1>
      <div className="d-flex flex-column justify-content-between h-100">
        <AssignProjectTable />
        <div className="pagination-main">
          <Pagination total={100} />
        </div>
      </div>
    </div>
  );
};

export default AssignProject;
