import React from "react";
import PlusIcon from "../../../assets/Icon/plus-icon.svg";
import { Button, Dropdown, Tabs, Tab } from "react-bootstrap";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import "./ProjectScreen.scss";
import ProjectTable from "../../../common/project/ProjectTable";
import { useSelector } from "react-redux";
import { routes } from "../../../constants";

const ProjectScreen = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const navigate = useNavigate();
  const gotoProjectDetail = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentProject}/detail/12345`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffProject}/detail/abcde`);
    } else if (userRole === "AGENCY_FOREMAN") {
      navigate(`${routes.formanProject}/detail/abcde`);
    } else {
      navigate("/project-detail");
    }
  };
  return (
    <section className="project-screen-main">
      <div className="project-screen-header">
        <h1 className="heading">Projects</h1>
        {userRole !== "AGENCY_FOREMAN" && (
          <div className="create-project-btn">
            <Button>
              <img src={PlusIcon} alt="" />
              Create Project
            </Button>
          </div>
        )}
      </div>
      <div className="table-header">
        <div className="categories-main">
          <div className="agent-dropdown">
            <Dropdown className="d-lg-block d-none">
              <Dropdown.Toggle id="dropdown-basic">Agent Name</Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="droup-item">
                  <input type="checkbox" name="" id="" />
                  <span>Agent Name </span>
                </div>
                <div className="droup-item">
                  <input type="checkbox" name="" id="" />
                  <span>Agent Name </span>
                </div>
                <div className="droup-item">
                  <input type="checkbox" name="" id="" />
                  <span>Agent Name </span>
                </div>
                <div className="droup-item">
                  <input type="checkbox" name="" id="" />
                  <span>Agent Name </span>
                </div>
                <div className="droup-item">
                  <input type="checkbox" name="" id="" />
                  <span>Agent Name </span>
                </div>
                <div className="droup-item">
                  <input type="checkbox" name="" id="" />
                  <span>Agent Name </span>
                </div>
                <div className="droup-item">
                  <input type="checkbox" name="" id="" />
                  <span>Agent Name </span>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="tab-main">
            <Tabs id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="home" title="All (9)" className="active">
                <div className="project-list-table">
                  <ProjectTable gotoProjectDetail={gotoProjectDetail} />
                  <div className="pagination-main">
                    <Pagination total={100} />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Upcoming (2)">
                Tab content for Profile
              </Tab>
              <Tab eventKey="contact" title="Ongoing (7)">
                Tab content for Contact
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectScreen;
