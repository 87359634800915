import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import PlusIcon from "../../../assets/Icon/plus-icon.svg";
import "./OpportunityScreen.scss";
import OpportunityTab from "./components/OpportunityTab";
import ProposalTab from "./components/Proposal/ProposalTab";
import { useDispatch, useSelector } from "react-redux";
import { reqToGetOpportunities } from "../../../reduxToolkit/services/opportunityServices";
import { routes } from "../../../constants";
import Loader from "../../../components/Loader/Loader";
import { reqToGetProposals } from "../../../reduxToolkit/services/proposalServices";

const OpportunityScreen = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { page_number } = params;

  const [query, setQuery] = useState("");

  const opportunityReducer = useSelector((state) => state.opportunity);
  const proposalReducer = useSelector((state) => state.proposal);
  const { opportunities } = opportunityReducer;
  const { totalOpportunityCount } = opportunityReducer;

  const { proposals } = proposalReducer;
  const { totalProposalCount } = proposalReducer;

  const [show, setShow] = useState(false);
  const [proposal, setProposal] = useState(false);
  const [key, setKey] = useState("Opportunity");

  const [page, setPage] = useState(page_number);

  const [oppTabsStatus, setOppTabsStatus] = useState("");
  const [propTabsStatus, setPropTabsStatus] = useState("");

  useEffect(() => {
    dispatch(reqToGetOpportunities({ status: oppTabsStatus, page: page, limit: 10 }));
  }, []);

  const showHideModal = () => {
    setShow(!show);
  };

  const navigateToAddForm = (to) => {
    if (userRole === "AGENCY_AGENT") {
      if (to === "opportunity") {
        navigate(`${routes.addAgentOpportunity}`);
      } else {
        navigate(`${routes.createAgentProposal}`);
      }
    } else if (userRole === "AGENCY_STAFF") {
      if (to === "opportunity") {
        navigate("/staff-opportunity/add");
      } else {
        navigate("/staff-proposal/add");
      }
    } else {
      if (to === "opportunity") {
        navigate("/add-opportunity");
      } else {
        navigate("/create-proposal");
      }
    }
  };

  const onMainTabChange = (keyValue) => {
    setKey(keyValue);
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentOpportunity}/page/1`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffOpportunity}/page/1`);
    } else {
      navigate(`${routes.opportunity}/page/1`);
    }
    setPage("1");
    setQuery("");
    setOppTabsStatus("");
    setPropTabsStatus("");
    if (keyValue === "Proposal") {
      setProposal(true);
      dispatch(reqToGetProposals({ status: propTabsStatus, page: "1", limit: 10, query: "" }));
    } else {
      setProposal(false);
      dispatch(reqToGetOpportunities({ status: oppTabsStatus, page: "1", limit: 10, query: "" }));
    }
  };

  const onPageChange = (newPage) => {
    console.log("newPage", newPage);
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentOpportunity}/page/${newPage}`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffOpportunity}/page/${newPage}`);
    } else {
      navigate(`${routes.opportunity}/page/${newPage}`);
    }
    setPage(newPage);
    if (proposal) {
      dispatch(reqToGetProposals({ status: propTabsStatus, page: newPage, limit: 10, query: query }));
    } else {
      dispatch(reqToGetOpportunities({ status: oppTabsStatus, page: newPage, limit: 10, query: query }));
    }
  };

  const onOppTabChange = (val) => {
    setOppTabsStatus(val);
    if (page_number !== "1") {
      if (userRole === "AGENCY_AGENT") {
        navigate(`${routes.agentOpportunity}/page/1`);
      } else if (userRole === "AGENCY_STAFF") {
        navigate(`${routes.staffOpportunity}/page/1`);
      } else {
        navigate(`${routes.opportunity}/page/1`);
      }
    }
    setPage("1");
    dispatch(reqToGetOpportunities({ status: val, page: "1", limit: 10, query: query }));
  };

  const onPropTabChange = (val) => {
    setPropTabsStatus(val);
    if (page_number !== "1") {
      if (userRole === "AGENCY_AGENT") {
        navigate(`${routes.agentOpportunity}/page/1`);
      } else if (userRole === "AGENCY_STAFF") {
        navigate(`${routes.staffOpportunity}/page/1`);
      } else {
        navigate(`${routes.opportunity}/page/1`);
      }
    }
    setPage("1");
    dispatch(reqToGetProposals({ status: val, page: "1", limit: 10, query: query }));
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (page_number !== "1") {
      if (userRole === "AGENCY_AGENT") {
        navigate(`${routes.agentOpportunity}/page/1`);
      } else if (userRole === "AGENCY_STAFF") {
        navigate(`${routes.staffOpportunity}/page/1`);
      } else {
        navigate(`${routes.opportunity}/page/1`);
      }
    }
    setPage("1");
    if (proposal) {
      dispatch(reqToGetProposals({ status: propTabsStatus, page: "1", limit: 10, query: e.target.value }));
    } else {
      dispatch(reqToGetOpportunities({ status: oppTabsStatus, page: "1", limit: 10, query: e.target.value }));
    }
  };

  return (
    <>
      {(opportunityReducer.loader || proposalReducer.loader) && <Loader />}
      <section className="opportunity-table-main">
        <div className="project-list-header">
          <h1 className="heading">{!proposal ? "Opportunity" : "Proposal"}</h1>
          <div className="create-project-btn">
            {!proposal ? (
              <Button onClick={() => navigateToAddForm("opportunity")}>
                <img src={PlusIcon} alt="" />
                Add Opportunity
              </Button>
            ) : (
              <Button onClick={showHideModal}>
                <span>
                  <img src={PlusIcon} alt="" />
                </span>
                <span className="btn-text">Create Proposal</span>
              </Button>
            )}
          </div>
        </div>
        <div className="table-main-tab">
          <Tabs
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => onMainTabChange(k)}
            className="mb-3 opportunity-tab align-items-center"
          >
            <Tab eventKey="Opportunity" title="Opportunity">
              <OpportunityTab
                onPageChange={onPageChange}
                opportunities={opportunities}
                totalOpportunityCount={totalOpportunityCount}
                onOppTabChange={onOppTabChange}
                oppTabsStatus={oppTabsStatus}
                page={page}
                userRole={userRole}
              />
            </Tab>
            <Tab eventKey="Proposal" title="Proposal">
              <ProposalTab
                onPageChange={onPageChange}
                proposals={proposals}
                totalProposalCount={totalProposalCount}
                onPropTabChange={onPropTabChange}
                propTabsStatus={propTabsStatus}
                page={page}
                userRole={userRole}
              />
            </Tab>
            <Tab
              className="search"
              title={
                <div className="search-section d-sm-block">
                  <InputGroup>
                    <Form.Control disabled={proposal ? true : false} placeholder="Search" onChange={onQueryChange} value={query} />
                    <InputGroup.Text id="basic-addon2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              }
            ></Tab>
          </Tabs>
        </div>
        <Modal show={show} onHide={showHideModal} className="add-proposal-modal" centered>
          <Modal.Body>
            <div className="w-50 mx-auto">
              <div className="heading">Choose how you want create proposal</div>
              <Button onClick={() => navigateToAddForm("proposal")}>Create manually</Button>
              <Button>Create with AI</Button>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default OpportunityScreen;
