import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../helper/axios";
import { toastify } from "../../common/commonFunction";
import { apiendpoints, messages } from "../../constants";

//req to add Opportunity
export const reqToAddOpportunity = createAsyncThunk("reqToAddOpportunity", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.addOpportunity, data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.opportunity.addOpportunity);
      data.navigate(data.route);
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

// req To Get Opportunities
export const reqToGetOpportunities = createAsyncThunk("reqToGetOpportunities", async (data) => {
  try {
    const response = await Axios.get(
      `${apiendpoints.getOpportunities}?status=${data.status}&page=${data.page || 1}&limit=${data.limit || 10}&query=${data.query || ""}`,
      authHeaders()
    );
    if (response.status === 201 || response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});

//req to Update Opportunity
export const reqToUpdateOpportunity = createAsyncThunk("reqToUpdateOpportunity", async (data) => {
  try {
    const response = await Axios.put(apiendpoints.updateOpportunity.replace(":oprId", data.oprId), data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.opportunity.updateOpportunity);
      data.cb(response);
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

// req To Get Opportunity Details
export const reqToGetOpportunityDetails = createAsyncThunk("reqToGetOpportunityDetails", async (oprId) => {
  try {
    const response = await Axios.get(apiendpoints.getOpportunityDetail.replace(":oprId", oprId), authHeaders());
    if (response.status === 201 || response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});

//req to add Reminder
export const reqToAddReminder = createAsyncThunk("reqToAddReminder", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.addReminder.replace(":oprId", data.oprId), data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.opportunity.addReminder);
      if (data.onSuccess) {
        data.onSuccess(response.data);
      }
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

//req to Update Reminder
export const reqToUpdateReminder = createAsyncThunk("reqToUpdateReminder", async (data) => {
  try {
    const response = await Axios.put(apiendpoints.updateReminder.replace(":oprId", data.oprId).replace(":rid", data.rid), data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.opportunity.updateReminder);
      if (data.onSuccess) {
        data.onSuccess(response.data);
      }
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

//req to Delete Reminder
export const reqToDeleteReminder = createAsyncThunk("reqToDeleteReminder", async (data) => {
  try {
    const response = await Axios.delete(apiendpoints.deleteReminder.replace(":oprId", data.oprId).replace(":rid", data.rid), authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.opportunity.deleteReminder);
      if (data.onSuccess) {
        data.onSuccess(response.data);
      }
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

//req to add Note
export const reqToAddNote = createAsyncThunk("reqToAddNote", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.addNote.replace(":oprId", data.oprId), data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.opportunity.addNote);
      if (data.onSuccess) {
        data.onSuccess(response.data);
      }
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

//req to Update Note
export const reqToUpdateNote = createAsyncThunk("reqToUpdateNote", async (data) => {
  try {
    const response = await Axios.put(apiendpoints.updateNote.replace(":oprId", data.oprId).replace(":nid", data.nid), data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.opportunity.updateNote);
      if (data.onSuccess) {
        data.onSuccess(response.data);
      }
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

//req to Delete Note
export const reqToDeleteNote = createAsyncThunk("reqToDeleteNote", async (data) => {
  try {
    const response = await Axios.delete(apiendpoints.deleteNote.replace(":oprId", data.oprId).replace(":nid", data.nid), authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.opportunity.deleteNote);
      if (data.onSuccess) {
        data.onSuccess(response.data);
      }
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});
