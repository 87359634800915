import React from 'react'
import "./AppointmentChat.scss"
const AppointmentChat = () => {
  return (
      <section className='appointment-message-main'>
          <div className="messages">
              <div className="message-contents">
                  <div className="time">12:29 pm</div>
                  <p className='sender'> Schedule Appointment for today with mr. client Name  </p>
              </div>
          </div>
          <div className="messages owner">
              <div className="message-contents">
                  <div className="time">12:29 pm</div>
                  <div className="content-msg">
                      <p>sure as i see you have not scheduled any appointment on below time slot now select available times</p>
                      <div className="timing">
                          <div className="tag active">
                              03:00PM
                          </div>
                          <div className="tag">
                              03:30PM
                          </div>
                          <div className="tag">
                              04:00PM
                          </div>
                          <div className="tag">
                              04:30PM
                          </div>
                          <div className="tag">
                              05:00PM
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </section>
  )
}

export default AppointmentChat