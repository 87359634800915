import moment from "moment";
import { toast } from "react-toastify";

// One time toast message show //
const toastList = new Set();
const MAX_TOAST = 2;

export function notify(message, messageType) {
  if (toastList.size < MAX_TOAST) {
    let id;
    if (messageType === "success") {
      id = toast.success(message, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else {
      id = toast.error(message, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
}

export const toastify = (toastType, errorMsg) => {
  if (toastType === "error") {
    toast.error(errorMsg, {
      autoClose: 1500,
    });
  } else {
    toast.success(errorMsg, {
      autoClose: 1500,
    });
  }
};

export const textError = (props) => {
  return <span className="form-error">{props.children}</span>;
};

export const customEmailValidator = (email) => {
  const re = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!email) {
    return false;
  }
  let emailParts = email.split("@");

  if (emailParts.length !== 2) {
    return false;
  }

  let account = emailParts[0];
  let address = emailParts[1];

  if (account.length > 64 || address.length > 255) {
    return false;
  }

  let domainParts = address.split(".");
  if (domainParts.some((part) => part.length > 63) || !re.test(email)) {
    return false;
  }

  return true;
};
