import React from "react";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import "./DuePaymentHeader.scss";

const DuePaymentHeader = () => {
  return (
    <div className="due-table-header">
      <div className="heading">Recent Transction</div>
      <div className="search-section d-sm-block d-none">
        <InputGroup>
          <Form.Control placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2" />
          <InputGroup.Text id="basic-addon2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <div className="agent-dropdown">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <span>Filter : </span>Statue
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="droup-item">
              <span>All</span>
            </div>
            <div className="droup-item">
              <span>Newest First</span>
            </div>
            <div className="droup-item">
              <span>Oldest First</span>
            </div>
            <div className="droup-item">
              <span>Dues Only</span>
            </div>
            <div className="droup-item">
              <span>Received Only</span>
            </div>
            <div className="droup-item">
              <span>High to Low</span>
            </div>
            <div className="droup-item">
              <span>Low to High</span>
            </div>
            <div className="droup-item">
              <span>A-Z</span>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default DuePaymentHeader;
