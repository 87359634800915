import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import LeftArrow from "../../../assets/Icon/arrow-left-line.svg"
import project1 from "../../../assets/images/project1.png"
import "./AgencyDetail.scss"
const AgencyDetail = () => {
  const navigate = useNavigate();
  const agency = () => {
    navigate("/admin/agency")
  }
  return (
    <section className="agency-detail-main">
      <div className="title">
        <span onClick={agency} style={{ cursor: "pointer" }}><img src={LeftArrow} alt="" /></span>
        <h2>Back to list</h2>
      </div>
      <div className="d-flex align-items-start flex-md-row flex-column gap-4">
        <div className="image d-flex justify-content-center align-items-center">
          <img src={project1} alt="" />
        </div>
        <div className="agency-detail-section">
          <div className="agency-card d-flex justify-content-between">
            <div className='info'>
              <div className='card-title'>Personal Information</div>
              <div className="d-flex gap-1">
                <div className="name">
                  <h5>First Name</h5>
                  <h4>Martha</h4>
                </div>
                <div className="name">
                  <h5>Last Name</h5>
                  <h4>S.</h4>
                </div>
              </div>
              <h5>Company Name</h5>
              <h4>Solo Estate Ltd.</h4>
              <div className="d-flex">
                <div className="name">
                  <h5>Email Id</h5>
                  <h4>Solo@gmail.com</h4>
                </div>
                <div className="name">
                  <h5>Mobile No.</h5>
                  <h4>+01 01252 12523</h4>
                </div>
              </div>
              <h5>Address</h5>
              <h4>A-101 ABD street, LA, USA - 000001</h4>
            </div>
            <Button className='block-btn'>Block Account</Button>
          </div>
          <div className="agency-card d-flex justify-content-between">
            <div className='info'>
              <div className='card-title'>Subscription & Credit</div>
              <div className="d-flex gap-xl-5 gap-4 flex-xl-row flex-column">
                <div className="d-flex flex-column w-xl-50 w-100">
                  <h5>Current Subscription Plan</h5>
                  <div className="inner-card h-100">
                    <h3>PRO</h3>
                    <h5>Subscription Type</h5>
                    <h4>Monthly</h4>
                    <div className="d-flex">
                      <div className="name">
                        <h5>Plan start Date</h5>
                        <h4>07 July, 2023</h4>
                      </div>
                      <div className="name">
                        <h5>Plan End Date</h5>
                        <h4>06 Aug, 2023</h4>
                      </div>
                      <div className="name">
                        <h5>Days Remaining</h5>
                        <h4>29</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column w-xl-50 w-100">
                  <h5>Credit For Chatbot</h5>
                  <div className="inner-card green h-100">
                    <h5>Total Issued</h5>
                    <h4>5000</h4>
                    <h5>Total used</h5>
                    <h4>3000</h4>
                    <h5>Balance Credit</h5>
                    <h4 className='mb-0'>2000</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="add-member-footer-btn d-flex justify-content-end gap-4">
            <Button className="reset-btn">
              Send Reset Password Link
            </Button>
            <Button>Send Admin Login Details</Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AgencyDetail