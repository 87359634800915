import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./ClientDetail.scss";
import LeftArrow from "../../../../assets/Icon/arrow-left-line.svg";
import ClientDCard from "../../../../common/client/clientDetail/ClientDCard";
import ClientDTable from "../../../../common/client/clientDetail/ClientDTable";
import ClientDChat from "../../../../common/client/clientDetail/ClientDChat";
import { useSelector } from "react-redux";
import { routes } from "../../../../constants";

const ClientDetail = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const location = useLocation();
  const { clientD } = location.state;

  const navigate = useNavigate();
  const goBack = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentClient}/page/1`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffClient}/page/1`);
    } else {
      navigate("/client/page/1");
    }
  };
  return (
    <div className="client-detail-screen">
      <div className="project-heading">
        <span onClick={goBack} style={{ cursor: "pointer" }}>
          <img src={LeftArrow} alt="" />
        </span>
        <h2>Back to list</h2>
      </div>
      <div className="row client-detail-content">
        <div className="col-lg-6">
          <ClientDCard clientD={clientD} />
          <ClientDTable />
          <div className="customer-review">
            <h4>Customer Reviews</h4>
            <div className="review">
              Customer Satisfaction Score or CSAT is a simple customer satisfaction survey methodology that measures a customer's satisfaction with an
              organization.
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-lg-block d-none">
          <ClientDChat />
        </div>
      </div>
    </div>
  );
};

export default ClientDetail;
