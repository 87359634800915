import { Calendar, Form, Input } from "antd";
import React from "react";
import "./ScheduleDemo.scss";
import TextArea from "antd/es/input/TextArea";
import { Button } from "react-bootstrap";

const ScheduleDemo = () => {
  return (
    <Form className="schedul-demo-form">
      <Form.Item className="mb-4" label="To">
        <Input placeholder="Enter email Id" />
      </Form.Item>
      <Form.Item className="mb-4" label="Subject">
        <Input placeholder="Enter subject" />
      </Form.Item>
      <TextArea
        className="mb-4"
        rows={4}
        placeholder="Write project overview"
      />
      <Form.Item className="mb-4" label="Meeting Link">
        <Input placeholder="Enter meeting link" />
      </Form.Item>
      <div className="schedul-date">
        <Calendar fullscreen={false} placeholder="Enter message" />
      </div>
      <div className="add-member-footer-btn d-flex justify-content-end gap-4">
        <Button className="cancel-btn">Cancel</Button>
        <Button>Send</Button>
      </div>
    </Form>
  );
};

export default ScheduleDemo;
