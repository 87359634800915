import React from "react";
import { Line } from "react-chartjs-2";
import "./PerformaceSection.scss";

const labels = ["01:00 AM", "03:00 AM", "06:00 AM", "09:00 AM", "12:00 PM", "03:00 PM", "06:00 PM", "09:00 PM"];

const chartData = {
  labels: labels,
  datasets: [
    {
      label: "2023",
      backgroundColor: "#7E97F2",
      borderColor: "#2B4ED2",
      data: [0, 10, 5, 2, 20, 30, 45],
    },
  ],
};

const options = {
  plugins: {
    legend: false, // Hide legend
  },
  maintainAspectRatio: false,
};

const PerformaceSection = () => {
  return (
    <div className="section-content d-flex flex-md-row flex-column">
      <div className="monthly-lead-chart w-md-50">
        <div className="d-flex justify-content-between title">
          <h2>Real time Sales</h2>
          <h2>2023</h2>
        </div>
        <div className="chart">
          <Line data={chartData} options={options} width={"100"} height={"100"} />
        </div>
      </div>
      <div className="top-performed-plan w-md-50">
        <h2 className="title">Top performed Plans</h2>
        <div className="plan-card">
          <div className="d-flex">
            <div className="plan-title">Plan Type</div>
            <div className="plan-title">Plan Period</div>
            <div className="plan-title">User (%)</div>
          </div>
          <div className="d-flex dark">
            <div className="plan-title">Pro</div>
            <div className="plan-title">Monthly</div>
            <div className="plan-title">70%</div>
          </div>
          <div className="d-flex blue">
            <div className="plan-title">Business</div>
            <div className="plan-title">Monthly</div>
            <div className="plan-title">13%</div>
          </div>
          <div className="d-flex dark">
            <div className="plan-title">Pro</div>
            <div className="plan-title">Yearly</div>
            <div className="plan-title">7%</div>
          </div>
          <div className="d-flex blue">
            <div className="plan-title">Business</div>
            <div className="plan-title">Yearly</div>
            <div className="plan-title">10%</div>
          </div>
          <div className="d-flex dark total-activity">
            <div className="plan-title">Total Active Users</div>
            <div className="plan-title">600</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformaceSection;
