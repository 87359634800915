import React from "react";
import { Accordion, Button } from "react-bootstrap";
import defaultImage from "../../../assets/images/default-image.png"
import "./OppoDetail.scss";

const OppoDetail = ({ oppDetail }) => {
  console.log(oppDetail)
  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div className="opportunity-detail-content">
        <div className="d-flex gap-4">
          <div className="opportunity-header-card d-flex gap-1 flex-wrap">
            <div className="image">
              <img src={oppDetail?.image || defaultImage} alt="image" />
            </div>
            <div className="opportunity-card-detail">
              <h2>{oppDetail?.firstName + " " + oppDetail?.lastName}</h2>
              <h6>{oppDetail?.phone}</h6>
              <h6>{oppDetail?.email}</h6>
              <h6 className="id">ID - B-00012</h6>
            </div>
          </div>
          {
            oppDetail.manager ? <div className="opportunity-header-card d-flex justify-content-between">
              <div className="opportunity-card-detail">
                <h2>{oppDetail?.manager.firstName + " " + oppDetail?.manager.lastName}</h2>
                <h6>{oppDetail?.manager.phone}</h6>
                <h6>{oppDetail?.manager.email}</h6>
              </div>
              <Button className="change-btn">Change</Button>
            </div> : <div className="opportunity-header-card d-flex justify-content-center align-items-center">
              <Button className="add-btn">Add Project Manager</Button>
            </div>
          }

        </div>
        <Accordion className="member-activity-accordion" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Project Overview</Accordion.Header>
            <Accordion.Body>
              <div className="daily-activities">{oppDetail?.details}</div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="member-activity-accordion" defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Scope of Work</Accordion.Header>
            <Accordion.Body>
              <div className="daily-activities">{oppDetail?.scope}</div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="member-activity-accordion" defaultActiveKey="2">
          <Accordion.Item eventKey="2">
            <Accordion.Header>Budget & Duration</Accordion.Header>
            <Accordion.Body>
              <div className="daily-activities">
                <ul>
                  <li>My budget for this project is ${oppDetail?.budget}</li>
                  <li>I expect to finished this project with in {oppDetail?.duration}</li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      {/* <div className="d-flex justify-content-end footer-btn flex-md-nowrap flex-wrap">
        <Button className="chat-btn mb-4">
          <Icon.ChatLeft /> Chat
        </Button>
      </div> */}
    </div>
  );
};

export default OppoDetail;
