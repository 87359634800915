import React from 'react'
import { Modal } from 'react-bootstrap'
import * as Icon from "react-bootstrap-icons"
import "./AssignManager.scss"

const AssignManager = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      className="assign-manager-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="header d-flex justify-content-between align-items-center">
          <h1>Assign Manager</h1>
          <Icon.XLg onClick={props.onHide} />
        </div>
        <div className="content">
          <div className="manager-check-card">
            <div className="check-input">
              <input type="checkbox" name="" id="" className='check' />
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <h4>Project Manager</h4>
                  <h5>+1 12345 123645</h5>
                  <h5>info@gmail.com</h5>
                </div>
                <div className="project-assigned">3 Project Assigned</div>
              </div>
            </div>
          </div>
          <div className="manager-check-card">
            <div className="check-input">
              <input type="checkbox" name="" id="" className='check' />
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <h4>Project Manager</h4>
                  <h5>+1 12345 123645</h5>
                  <h5>info@gmail.com</h5>
                </div>
                <div className="project-assigned">3 Project Assigned</div>
              </div>
            </div>
          </div>
          <div className="manager-check-card">
            <div className="check-input">
              <input type="checkbox" name="" id="" className='check' />
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <h4>Project Manager</h4>
                  <h5>+1 12345 123645</h5>
                  <h5>info@gmail.com</h5>
                </div>
                <div className="project-assigned">3 Project Assigned</div>
              </div>
            </div>
          </div>
          <div className="manager-check-card">
            <div className="check-input">
              <input type="checkbox" name="" id="" className='check' />
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <h4>Project Manager</h4>
                  <h5>+1 12345 123645</h5>
                  <h5>info@gmail.com</h5>
                </div>
                <div className="project-assigned">3 Project Assigned</div>
              </div>
            </div>
          </div>
          <div className="manager-check-card">
            <div className="check-input">
              <input type="checkbox" name="" id="" className='check' />
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <h4>Project Manager</h4>
                  <h5>+1 12345 123645</h5>
                  <h5>info@gmail.com</h5>
                </div>
                <div className="project-assigned">3 Project Assigned</div>
              </div>
            </div>
          </div>
          <div className="manager-check-card">
            <div className="check-input">
              <input type="checkbox" name="" id="" className='check' />
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <h4>Project Manager</h4>
                  <h5>+1 12345 123645</h5>
                  <h5>info@gmail.com</h5>
                </div>
                <div className="project-assigned">3 Project Assigned</div>
              </div>
            </div>
          </div>
          <div className="manager-check-card">
            <div className="check-input">
              <input type="checkbox" name="" id="" className='check' />
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <h4>Project Manager</h4>
                  <h5>+1 12345 123645</h5>
                  <h5>info@gmail.com</h5>
                </div>
                <div className="project-assigned">3 Project Assigned</div>
              </div>
            </div>
          </div>
          <div className="manager-check-card">
            <div className="check-input">
              <input type="checkbox" name="" id="" className='check' />
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <h4>Project Manager</h4>
                  <h5>+1 12345 123645</h5>
                  <h5>info@gmail.com</h5>
                </div>
                <div className="project-assigned">3 Project Assigned</div>
              </div>
            </div>
          </div>
          <div className="manager-check-card">
            <div className="check-input">
              <input type="checkbox" name="" id="" className='check' />
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <h4>Project Manager</h4>
                  <h5>+1 12345 123645</h5>
                  <h5>info@gmail.com</h5>
                </div>
                <div className="project-assigned">3 Project Assigned</div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AssignManager