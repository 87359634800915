import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import "./ResultPreviewePopup.scss"
const ResultPreviewePopup = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='result-previwe-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    AI generated proposal
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="owner-company-details">
                    <div className="conpany-details">
                        [Your Company Name]
                    </div>
                    <div className="conpany-details">
                        [Your Address]
                    </div>
                    <div className="conpany-details">
                        [City, State, Zip Code]
                    </div>
                    <div className="conpany-details">
                        [Date]
                    </div>
                </div>
                <div className="client-company-details">
                    <div className="conpany-details">
                        [Client's Company Name]
                    </div>
                    <div className="conpany-details">
                        [Client's Address]
                    </div>
                    <div className="conpany-details">
                        [City, State, Zip Code]
                    </div>
                </div>
                <div className="subject">
                    Subject: Proposal for [Project Name]
                </div>
                <div className="client-details">
                    Dear [Client's Name],
                </div>
                <div className="result-formate">
                    <p className='m-0'>We are pleased to present this proposal for [Project Name], which outlines our comprehensive plan to address the needs and objectives outlined in our discussions. Our team at [Your Company Name] is excited about the opportunity to collaborate with [Client's Company Name] on this project and provide innovative solutions tailored to your requirements.</p>
                    <div className="result-points">
                        <p> <span>1. Project Overview: </span>
                            [Project Name] aims to [briefly describe the primary purpose and objectives of the project]. Our proposal outlines a detailed plan to accomplish these objectives efficiently and effectively.
                        </p>
                        <p> <span>2. Problem Statement: </span>
                            After careful analysis and discussions, we have identified the key challenges and opportunities that [Client's Company Name] faces in [relevant industry or sector]. Our proposal will address these challenges and leverage opportunities to achieve desired outcomes.
                        </p>
                        <p> <span>3. Proposed Solution: </span>
                            We propose a [briefly describe the proposed solution, products, or services] to meet the specific needs of [Client's Company Name]. Our team of experts will work closely with your team to ensure the successful implementation of this solution.
                        </p>
                        <p> <span>4. Methodology and Approach: </span>
                            We propose a [briefly describe the proposed solution, products, or services] to meet the specific needs of [Client's Company Name]. Our team of experts will work closely with your team to ensure the successful implementation of this solution.
                            <div className="sub-points">
                                <ol>
                                    <li>[Step 1: Describe the first step of the project]</li>
                                    <li>[Step 2: Describe the second step of the project]</li>
                                    <li>[Step 3: Describe the third step of the project]</li>
                                    <li>[and so on...]</li>
                                </ol>
                            </div>
                        </p>
                        <p> <span>1. Timeline:  </span>
                            We propose a [briefly describe the proposed solution, products, or services] to meet the specific needs of [Client's Company Name]. Our team of experts will work closely with your team to ensure the successful implementation of this solution.
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='regenerate-btn'>Regenerate</Button>
                <Button  className='sve-btn'>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ResultPreviewePopup