import React, {useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Accordion, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "./OppoDetail.scss";
import ConfirmationModal from "../../../components/Modal/common/ConfirmationModal";
import ReminderModal from "../../../components/Modal/reminderModal/ReminderModal";
import SetAppoinmentModal from "../../../components/Modal/setAppoinmentModal/SetAppoinmentModal";
import AddNoteModal from "../../../components/Modal/addNoteModal/AddNoteModal";
import { 
        reqToAddReminder, 
        reqToUpdateReminder, 
        reqToDeleteReminder,
        reqToAddNote,
        reqToUpdateNote,
        reqToDeleteNote,
         } from "../../../reduxToolkit/services/opportunityServices";
import moment from "moment";
import dayjs from 'dayjs';

const OppoDetailExtra = (props) => {
  const { oppDetail } = props;
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [appoinmentModalShow, setAppoinmentModalShow] = useState(false);
  const [noteModalShow, setNoteModalShow] = useState(false);
  const [note, setNote] = useState({});
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);

  const [reminder, setReminder] = useState({});
  const [showDeleteReminderModal, setShowDeleteReminderModal] = useState(false);

  //Reminder section--------------------
  const onReminderSubmitHandler = (values) => {
    const data = {
      "note": values.note,
      "dateTime": moment(new Date(values.date)).format("YYYY-MM-DD") + ' ' + dayjs(values.time).format("h:mm A")
    };
    
    if(reminder.rid) {
      dispatch(
        reqToUpdateReminder({
          oprId: oppDetail.oprId,
          rid: reminder.rid,
          data: data,
          onSuccess: (res) => { if (res) { setModalShow(false); } },
        })
      );
    } else {
      dispatch(
        reqToAddReminder({
          oprId: oppDetail.oprId,
          data: data,
          onSuccess: (res) => { if (res) { setModalShow(false); } },
        })
      );
    }
  };

  const deleteReminderHandler = () => {
    dispatch(
      reqToDeleteReminder({
        oprId: oppDetail.oprId,
        rid: reminder.rid,
        onSuccess: (res) => { if (res) { setShowDeleteReminderModal(false); } },
      })
    );
  }

  const addReminderHandler = () => {
    setReminder({});
    setModalShow(true);
  }

  const editReminderHandler = (reminder) => {
    setReminder(reminder);
    setModalShow(true);
  }

  const deleteReminderConfirmation = (reminder) => {
    setReminder(reminder);
    setShowDeleteReminderModal(true);
  }

  //Note section----------------
  const addNoteHandler = () => {
    setNote({});
    setNoteModalShow(true);
  }

  const editNoteHandler = (note) => {
    setNote(note);
    setNoteModalShow(true);
  }

  const deleteNoteConfirmation = (note) => {
    setNote(note);
    setShowDeleteNoteModal(true);
  }

  const deleteNoteHandler = () => {
    dispatch(
      reqToDeleteNote({
        oprId: oppDetail.oprId,
        nid: note.nid,
        onSuccess: (res) => { if (res) { setShowDeleteNoteModal(false); } },
      })
    );
  }

  const onNoteSubmitHandler = (values) => {
    const data = {
      "note": values.note,
    };
    
    if(note.nid) {
      dispatch(
        reqToUpdateNote({
          oprId: oppDetail.oprId,
          nid: note.nid,
          data: data,
          onSuccess: (res) => { if (res) { setNoteModalShow(false); } },
        })
      );
    } else {
      dispatch(
        reqToAddNote({
          oprId: oppDetail.oprId,
          data: data,
          onSuccess: (res) => { if (res) { setNoteModalShow(false); } },
        })
      );
    }
  };

  useEffect(() => {
  }, []);

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div className="opportunity-detail-content">
        <div className="d-flex gap-4">
          <Button className="add-btn w-50" onClick={() => setAppoinmentModalShow(true)}>Set appointment</Button>
          <Button className="add-btn w-50">Create Proposal</Button>
        </div>
        <Accordion className="member-activity-accordion" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex gap-3">
                  <Icon.ClockHistory />
                  Reminders
                </div>
                <div className="d-flex me-4">
                  <Button className="add-btn" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addReminderHandler();
                  }}>Add</Button>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              
            {oppDetail.reminders && oppDetail.reminders.length === 0 ? (
                  <div className="no-data-found">No Records</div>
              ) : (oppDetail.reminders && oppDetail.reminders.map((item, i) => (
              <Accordion className="member-activity-accordion m-0 mb-4">
                <Accordion.Item eventKey={i}>
                  <Accordion.Header>
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex gap-3"></div>
                    {moment(item.dateTime).format("D MMM, YYYY h:mm A")}
                    <div className="d-flex gap-4 me-4">
                        <Icon.Pencil onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            editReminderHandler(item);
                          }} />
                        <Icon.Trash onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            deleteReminderConfirmation(item);
                          }} />
                    </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="daily-activities">
                      {item.note}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))
            )}
                  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="member-activity-accordion" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex gap-3">
                  <Icon.Journal />
                  Notes
                </div>
                <div className="d-flex me-4">
                  <Button className="add-btn" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addNoteHandler();
                  }}>Add</Button>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {oppDetail.notes && oppDetail.notes.length === 0 ? (
                    <div className="no-data-found">No Records</div>
              ) : (oppDetail.notes && oppDetail.notes.map((item, i) => (
                <>
                {(i !== 0) ? <hr/> : ''}
                <div className="daily-activities">{item.note}</div>
                <div className="profile d-flex gap-2">
                  <div className="image">
                    <img src={`${item?.user?.image}`} alt="" />
                  </div>
                  <div className="content">
                    <div className="name">{`${item?.user?.firstName} ${item?.user?.lastName}`}</div>
                    <div className="time">{moment(item.dateCreated).format("D MMM, YYYY h:mm A")}</div>
                  </div>
                </div>
                <div className="d-flex gap-4 me-4">
                    <Icon.Pencil onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        editNoteHandler(item);
                      }} />
                    <Icon.Trash onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        deleteNoteConfirmation(item);
                      }} />
                </div>
                </>
              ))
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <SetAppoinmentModal
        show={appoinmentModalShow}
        onHide={() => setAppoinmentModalShow(false)}
      />
      <ReminderModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmitHandler={onReminderSubmitHandler}
        reminder={reminder}
      />
      <ConfirmationModal 
        msgText="Are you sure to delete this reminder?"
        show={showDeleteReminderModal}
        onYes={deleteReminderHandler}
        onNo={() => setShowDeleteReminderModal(false)}
      />
      <AddNoteModal
        show={noteModalShow}
        onHide={() => setNoteModalShow(false)}
        onSubmitHandler={onNoteSubmitHandler}
        note={note}
      />
      <ConfirmationModal 
        msgText="Are you sure to delete this note?"
        show={showDeleteNoteModal}
        onYes={deleteNoteHandler}
        onNo={() => setShowDeleteNoteModal(false)}
      />
    </div>
  );
};

export default OppoDetailExtra;
