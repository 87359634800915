import React from "react";
import { Tab, Table, Tabs } from "react-bootstrap";

import "./ClientDTable.scss";
import rightArrow from "../../../assets/Icon/right-arrow-icon.svg";

const ClientDTable = ({ userRole }) => {
  return (
    <Tabs className="client-detail-tab">
      <Tab eventKey="Opportunity" title="Projects (03)">
        <Table size="lg" responsive className="client-detail-table">
          <tbody>
            <tr>
              <td>17 Aug, 2023</td>
              <td>Antalia Tower</td>
              <td>Las Vegas</td>
              <td>
                <img src={rightArrow} alt="" />
              </td>
            </tr>
            <tr>
              <td>17 Aug, 2023</td>
              <td>Antalia Tower</td>
              <td>Las Vegas</td>
              <td>
                <img src={rightArrow} alt="" />
              </td>
            </tr>
            <tr>
              <td>17 Aug, 2023</td>
              <td>Antalia Tower</td>
              <td>Las Vegas</td>
              <td>
                <img src={rightArrow} alt="" />
              </td>
            </tr>
          </tbody>
        </Table>
      </Tab>
      {userRole !== "AGENCY_AGENT" && (
        <Tab eventKey="Proposal" title="Transaction">
          <Table size="lg">
            <tbody>
              <tr>
                <td>17 Aug, 2023</td>
                <td>Antalia Tower</td>
                <td>$ 3000.00</td>
              </tr>
              <tr>
                <td>17 Aug, 2023</td>
                <td>Antalia Tower</td>
                <td>$ 4000.00</td>
              </tr>
              <tr>
                <td>17 Aug, 2023</td>
                <td>Antalia Tower</td>
                <td>$ 3200.00</td>
              </tr>
            </tbody>
          </Table>
        </Tab>
      )}
    </Tabs>
  );
};

export default ClientDTable;
