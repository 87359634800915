import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import LeftArrow from "../../../../assets/Icon/arrow-left-line.svg";
import AddTaskModal from "../../../../components/Modal/addTask/AddTaskModal";
import AssignManager from "../../../../components/Modal/assignManager/AssignManager";
import ProjectDetailTask from "../../../../common/project/projectDetail/Task/ProjectDetailTask";
import ProjectDetailDocument from "../../../../common/project/projectDetail/Document/ProjectDetailDocument";
import ProjectDetailAssignMember from "../../../../components/Modal/assignMember/ProjectDetailAssignMember";
import ProjectDCard from "../../../../common/project/projectDetail/ProjectDCard/ProjectDCard";

const ClientProjectDetails = () => {
  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [assignManagerShow, setAssignManagerShow] = useState(false);
  const [assignMemberShow, setAssignMemberShow] = useState(false);
  const project = () => {
    navigate("/client-project/page/1");
  };

  const showAssignMember = () => {
    setAssignMemberShow(true);
  };

  return (
    <section className="project-detail-main">
      <div className="project-detail-header">
        <div className="title">
          <span onClick={project} style={{ cursor: "pointer" }}>
            <img src={LeftArrow} alt="" />
          </span>
          <h2>Back to list</h2>
        </div>
        <div className="create-project-btn"></div>
      </div>
      <ProjectDCard showAssignMember={showAssignMember} />
      <div className="project-detail-tabs">
        <Tabs id="controlled-tab-example" className="mb-3">
          <Tab eventKey="task" title="Task (12)">
            <ProjectDetailTask setModalShow={setModalShow} />
          </Tab>
          <Tab eventKey="document" title="Document (9)">
            <ProjectDetailDocument userType="client" />
          </Tab>
        </Tabs>
      </div>
      <AddTaskModal show={modalShow} onHide={() => setModalShow(false)} />
      <AssignManager show={assignManagerShow} onHide={() => setAssignManagerShow(false)} />
      <ProjectDetailAssignMember show={assignMemberShow} onHide={() => setAssignMemberShow(false)} />
    </section>
  );
};

export default ClientProjectDetails;
