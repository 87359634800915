import React, { useEffect } from "react";
import LoginImage from "../../../assets/images/login-image.png";
import BlackLogo from "../../../assets/images/black-logo.svg";
import VerifiedIcon from "../../../assets/Icon/verified.gif";
import "./EmailSuccess.scss";
import { useLocation } from "react-router-dom";

const CheckEmail = () => {
  const location = useLocation();
  const { type } = location?.state;

  return (
    <section className="email-success-section">
      <div className="row w-100">
        <div className="col-xl-7 p-0">
          <div className="email-success-main d-flex justify-content-center">
            <div className="form-inner">
              <div className="logo">
                <img src={BlackLogo} alt="" />
              </div>
              <div className="content">
                <div>
                  <h1>Check your email</h1>
                  <img src={VerifiedIcon} alt="" />
                  <h1>
                    To {type === "resetPassword" ? "reset your password" : "confirm your email address"}, tap the button in the email we sent to you.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 d-xl-block d-none p-0">
          <div className="sign-up-image-section">
            <img src={LoginImage} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckEmail;
