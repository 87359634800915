import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./css/App.scss";
import store, { persistor } from "./reduxToolkit";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <ToastContainer />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
