import React from 'react'

const OpportunityChat = () => {
    return (
        <section className='sent-message-main'>
            <div className="messages">
                <div className="message-contents">
                    <div className="time">12:29 pm</div>
                    <p> Create New opportunity details is here client name mr. Client name  </p>
                </div>
            </div>
            <div className="messages owner">
                <div className="message-contents">
                    <div className="time">12:29 pm</div>
                    <p>Sure! the  Antalia Tower Project Successful. i need more details to create complete project please write down more details    </p>
                </div>
            </div>
        </section>
    )
}

export default OpportunityChat