import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import "./OpportunityDetail.scss";
import LeftArrow from "../../../../assets/Icon/arrow-left-line.svg";
import rightArrow from "../../../../assets/Icon/right-arrow-icon.svg";
import ChangeManager from "../../../../components/Modal/changeManager/ChangeManager";
import Loader from "../../../../components/Loader/Loader";
import OppoDetail from "../../../../common/opportunity/oppoDetail/OppoDetail";
import OppoDetailExtra from "../../../../common/opportunity/oppoDetail/OppoDetailExtra";
import { reqToGetOpportunityDetails, reqToUpdateOpportunity } from "../../../../reduxToolkit/services/opportunityServices";
import { tHforAppoinment } from "../../../../constants/tableHeaders";
import { routes } from "../../../../constants";

const OpportunityDetail = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { opportunity_id } = params;

  const appoinmentReducer = useSelector((state) => state.appoinment);

  const { appoinments } = appoinmentReducer;

  const teamReducer = useSelector((state) => state.myTeam);
  const opportunityReducer = useSelector((state) => state.opportunity);
  const oppDetail = opportunityReducer.opportunityDetail;

  const [setStatusValue] = useState(oppDetail?.status ? oppDetail?.status : "");

  const opportunity = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentOpportunity}/page/1`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffOpportunity}/page/1`);
    } else {
      navigate("/opportunity");
    }
  };
  const onHideModalHandler = () => {
    setModalShow(false);
  };

  useEffect(() => {
    dispatch(reqToGetOpportunityDetails(opportunity_id));
  }, []);

  const handleStatusChange = (event) => {
    if (event !== oppDetail.status) {
      const data = {
        status: event,
      };

      dispatch(
        reqToUpdateOpportunity({
          oprId: opportunity_id,
          data: data,
          cb: (res) => {
            dispatch(reqToGetOpportunityDetails(opportunity_id));
          },
        })
      );
    }
    setStatusValue(event);
  };

  return (
    <>
      {(opportunityReducer.loader || teamReducer.loader) && <Loader />}
      <div className="opportunity-detail-screen">
        <div className="opportunity-header d-flex justify-content-between flex-md-nowrap flex-wrap">
          <div className="project-heading">
            <span onClick={opportunity} style={{ cursor: "pointer" }}>
              <img src={LeftArrow} alt="" />
            </span>
            <h2>Back to list</h2>
          </div>
          <div className="current-stage d-flex">
            <div className="part d-flex flex-column justify-content-center">
              <h4>Current Stage</h4>
              <h3 className="new">
                {oppDetail?.status === 2 ? "Follow up" : oppDetail?.status === 3 ? "Accept" : oppDetail?.status === 4 ? "Not Intrested" : "New"}
              </h3>
            </div>
            <div className="part d-flex flex-column justify-content-center">
              <h4>Last Update</h4>
              <h3>{moment.utc(oppDetail?.updatedAt).format("D MMM, YYYY")}</h3>
            </div>
          </div>
        </div>
        <Row>
          <Col md={8}>
            <OppoDetail oppDetail={oppDetail} />
          </Col>
          <Col md={4}>
            <OppoDetailExtra oppDetail={oppDetail} />
          </Col>
        </Row>
        <Table size="lg" responsive className="appoinment-table">
          <thead>
            <tr>
              {tHforAppoinment().map((th, i) => {
                return <th key={i}>{th.title}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {appoinments.length === 0 ? (
              <tr>
                <td colSpan={7} className="no-data-found">
                  No data found
                </td>
              </tr>
            ) : (
              appoinments.map((item, i) => (
                <tr key={i}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.contact}</td>
                  <td>{item.email}</td>
                  <td>
                    <div className="date-and-time">
                      <div className="date">{item.date + item.date}</div>
                    </div>
                  </td>
                  <td>{item.status}</td>
                  <td>
                    <img src={rightArrow} alt="" style={{ cursor: "pointer" }} />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <ChangeManager opportunity_id={opportunity_id} show={modalShow} onHide={onHideModalHandler} oppDetail={oppDetail} />
      </div>
    </>
  );
};

export default OpportunityDetail;
