import React, { useState } from 'react'
import Logo from "../../../assets/images/ai-logo.svg"
import LeftArrow from "../../../assets/Icon/arrow-left-line.svg"
import AiAddIcon from "../../../assets/Icon/tabler_message-2-plus.svg"
import { Button } from 'react-bootstrap'
import ResultPreviewePopup from '../../../components/Modal/resultPreviewPopup/ResultPreviewePopup';
import "./AppointmentScreenAi.scss"
import AppointmentChat from '../../../components/AppointmentChat/AppointmentChat';
import { useNavigate } from 'react-router-dom';
const AppointmentScreenAi = () => {
    const [resultPreviewShow, setResultPreviewShow] = useState(false);
    const navigate = useNavigate();
    const gotoAppointmentScreen = () => {
        navigate("/appointment")
    }
    return (
        <>
            <ResultPreviewePopup
                show={resultPreviewShow}
                onHide={() => setResultPreviewShow(false)}
            />
            <section className="appointment-screen-ai">
                <div className="project-heading">
                    <span onClick={gotoAppointmentScreen} style={{ cursor: "pointer" }}><img src={LeftArrow} alt="" /></span>
                    <h2>Appointment</h2>
                </div>
                <div className="project-screen-content">
                    <div className="row px-3">
                        <div className="col-3 p-0">
                            <div className="history-section-main">
                                <div className="history-header">
                                    <div className="heading">
                                        <h5>History</h5>
                                    </div>
                                    <div className="number">
                                        <span>06/50 </span>
                                    </div>
                                </div>
                                <div className="history-content">
                                    <div className="content-title">
                                        Yesterday
                                    </div>
                                    <div className="history-text">
                                        <p className='text'>Example Chat history goes here ... </p>
                                        <p className='text'>Example Chat history goes here ... </p>
                                    </div>
                                    <div className="content-title">
                                        23- Aug, 2023
                                    </div>
                                    <div className="history-text">
                                        <p className='text'>Example Chat history goes here ... </p>
                                        <p className='text'>Example Chat history goes here ... </p>
                                    </div>
                                    <div className="content-title">
                                        21- Aug, 2023
                                    </div>
                                    <div className="history-text">
                                        <p className='text'>Example Chat history goes here ... </p>
                                        <p className='text'>Example Chat history goes here ... </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 p-0">
                            <div className="project-chat-section">
                                <div className="project-chat-header">
                                    <div className="chat-logo">
                                        <img src={Logo} alt="" />
                                    </div>
                                    <div className="new-chat-btn">
                                        <Button><span>
                                            <img src={AiAddIcon} alt="" />
                                        </span> New Chat</Button>
                                    </div>
                                </div>
                                <div className="project-chat-content">
                                    <AppointmentChat />
                                    <div className="create-now">
                                        <span>Just click on the button to create a new project</span>
                                        <Button onClick={() => setResultPreviewShow(true)}>Create Now</Button>
                                    </div>
                                    <div className="input">
                                        <input type="text" placeholder='write a message...' />
                                        <div className="icon-group">
                                            <div className="mic">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <rect width="32" height="32" rx="6" fill="#E8ECF7" />
                                                    <path d="M16 7C17.8865 7 19.4158 8.83157 19.4158 11.0909V14.3636C19.4158 16.623 17.8865 18.4545 16 18.4545C14.1135 18.4545 12.5842 16.623 12.5842 14.3636V11.0909C12.5842 8.83157 14.1135 7 16 7ZM9.88904 15.1818H11.2663C11.5978 17.9573 13.5908 20.0909 16 20.0909C18.4091 20.0909 20.4022 17.9573 20.7337 15.1818H22.1109C21.7959 18.5949 19.533 21.305 16.6832 21.6824V25H15.3168V21.6824C12.4669 21.305 10.2041 18.5949 9.88904 15.1818Z" fill="#494C54" />
                                                </svg>

                                            </div>
                                            <div className="send">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <rect width="32" height="32" rx="6" fill="#2B4ED2" />
                                                    <path d="M9.26296 23.7285C9.0848 23.5796 8.95665 23.3796 8.89585 23.1555C8.83504 22.9314 8.8445 22.6941 8.92295 22.4756L10.8773 16.7647C10.8965 16.7082 10.933 16.6591 10.9816 16.6244C11.0301 16.5897 11.0883 16.571 11.148 16.5711L16.2846 16.5711C16.363 16.5713 16.4405 16.5553 16.5124 16.5243C16.5843 16.4932 16.6491 16.4477 16.7027 16.3906C16.7563 16.3334 16.7975 16.2659 16.8239 16.1921C16.8503 16.1184 16.8613 16.04 16.8561 15.9618C16.8431 15.8148 16.7751 15.6782 16.6657 15.5793C16.5563 15.4803 16.4135 15.4264 16.266 15.4282L11.1537 15.4282C11.0941 15.4283 11.036 15.4098 10.9874 15.3752C10.9389 15.3406 10.9024 15.2917 10.883 15.2353L8.92295 9.52086C8.84619 9.30181 8.83794 9.06457 8.89931 8.84072C8.96068 8.61687 9.08876 8.417 9.2665 8.26772C9.44424 8.11844 9.66322 8.02682 9.89431 8.00504C10.1254 7.98326 10.3576 8.03237 10.5601 8.14582L22.5612 14.9996C22.738 15.099 22.8851 15.2437 22.9875 15.4187C23.0898 15.5938 23.1438 15.7929 23.1438 15.9957C23.1438 16.1985 23.0898 16.3976 22.9875 16.5727C22.8851 16.7477 22.738 16.8924 22.5612 16.9918L10.5637 23.8499C10.3608 23.9661 10.1269 24.0168 9.89412 23.9951C9.66129 23.9733 9.44086 23.8802 9.26296 23.7285Z" fill="white" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-3 p-0">
                            <div className="project-filter-section">
                                <div className="view-all-btn">
                                    <Button className='mb-3' onClick={gotoAppointmentScreen}>View all Appointment</Button>
                                </div>
                                <div className="project-filter">
                                    <div className="project-details">
                                        <div className="details-main">
                                            <div className="details">
                                                <div className="text">
                                                    Today Created Appointment
                                                </div>
                                                <span>03</span>
                                            </div>
                                            <div className="details">
                                                <div className="text">
                                                    Upcoming
                                                </div>
                                                <span>10</span>
                                            </div>
                                            <div className="details">
                                                <div className="text">
                                                    Completed
                                                </div>
                                                <span>05</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ai-filters">
                                        <div className="filter-main">
                                            <div className="filter-heading">AI Filters</div>
                                            <div className="filters-tag">
                                                <div className="tag">Client</div>
                                                <div className="tag">Time</div>
                                                <div className="tag">Date</div>
                                                <div className="tag">Status</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AppointmentScreenAi