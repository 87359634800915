import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./MyTeamScreen.scss";

import PlusIcon from "../../../assets/Icon/plus-icon.svg";
import AddMember from "../../../components/Modal/addMember/AddMember";
import { reqToAddMember, reqToGetAllMembers } from "../../../reduxToolkit/services/myTeamServices";
import Loader from "../../../components/Loader/Loader";
import { routes } from "../../../constants";
import TeamTable from "../../../common/team/TeamTable";

const MyTeamScreen = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { page_number } = params;

  const myTeam = useSelector((state) => state.myTeam);
  const { members } = myTeam;
  const { totalMemberCount } = myTeam;

  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(page_number);

  useEffect(() => {
    dispatch(reqToGetAllMembers({ page: page, limit: 10 }));
  }, []);

  const onHideModalHandler = () => {
    setModalShow(false);
    form.setFieldsValue({
      role: "",
      email: "",
    });
  };

  const onSubmitHandler = (values) => {
    const data = values;
    dispatch(
      reqToAddMember({
        data,
        onSuccess: (res) => {
          if (res) {
            onHideModalHandler();
          }
        },
      })
    );
  };

  const goToTeamDetailPage = (member) => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentMyTeam}/detail/${member.member.mid}`, { state: { member } });
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffMyTeam}/detail/${member.member.mid}`, { state: { member } });
    } else if (userRole === "AGENCY_FOREMAN") {
      navigate(`${routes.formanMyTeam}/detail/${member.member.mid}`, { state: { member } });
    } else {
      navigate("/myteam/team-detail", { state: { member } });
    }
  };

  const onPageChange = (newPage) => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentMyTeam}/page/${newPage}`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffMyTeam}/page/${newPage}`);
    } else if (userRole === "AGENCY_FOREMAN") {
      navigate(`${routes.formanMyTeam}/page/${newPage}`);
    } else {
      navigate(`${routes.myTeam}/page/${newPage}`);
    }
    setPage(newPage);
    dispatch(reqToGetAllMembers({ page: newPage, limit: 10 }));
  };

  return (
    <>
      {myTeam.loader && <Loader />}
      <section className="my-team-screen">
        <div className="list-header">
          <h1 className="heading">My Team</h1>
          {userRole !== "AGENCY_AGENT" && userRole !== "AGENCY_FOREMAN" && (
            <div className="create-project-btn">
              <Button onClick={() => setModalShow(true)}>
                <img src={PlusIcon} alt="" />
                Add New Member
              </Button>
            </div>
          )}
        </div>
        <div className="list-table">
          <TeamTable members={members} goToTeamDetailPage={goToTeamDetailPage} />
          <div className="pagination-main">
            <Pagination total={totalMemberCount || 0} onChange={onPageChange} current={+page} />
          </div>
        </div>
        <AddMember show={modalShow} form={form} onHide={onHideModalHandler} onSubmitHandler={onSubmitHandler} />
      </section>
    </>
  );
};

export default MyTeamScreen;
