import React from "react";
import { Table } from "react-bootstrap";
import { Avatar, Tooltip, Progress } from "antd";
import { AntDesignOutlined } from "@ant-design/icons";

import "./ProjectTable.scss";
import rightArrow from "../../assets/Icon/right-arrow-icon.svg";

const ProjectTable = ({ gotoProjectDetail, userRole }) => {
  return (
    <Table size="lg" responsive className="project-table">
      <thead>
        <tr>
          <th>Project Name</th>
          <th>Location / Area</th>
          <th>Duration</th>
          <th>Completion</th>
          <th>Task</th>
          <th>Member</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={30} />
          </td>
          <td>10 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img onClick={gotoProjectDetail} src={rightArrow} alt="" style={{ cursor: "pointer" }} />
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td className="p-0 pt-1">
            <div className="completed">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={60} />
          </td>
          <td>10 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={10} />
          </td>
          <td>10 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td className="p-0 pt-1">
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={50} />
          </td>
          <td>10 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td className="p-0 pt-1">
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>35 Months</td>
          <td>
            <Progress percent={30} />
          </td>
          <td>10 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td className="p-0 pt-1">
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td className="p-0 pt-1">
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>

        <tr>
          <td>Erozona Tower</td>
          <td>Switzerland</td>
          <td>30 Months</td>
          <td className="p-0 pt-1">
            <div className="completed">
              {" "}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>20 Tasks</td>
          <td>
            <div className="d-flex align-items-center gap-3">
              <Avatar.Group>
                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                <Tooltip title="Ant User" placement="top">
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                </Tooltip>
                <Avatar
                  style={{
                    backgroundColor: "#1677ff",
                  }}
                  icon={<AntDesignOutlined />}
                />
              </Avatar.Group>
              <span>4 more</span>
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ProjectTable;
