import React from "react";
import { Button, Modal } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "./ProjectDetailUploadDocument.scss";
import { Form } from "antd";
import Input from "antd/es/input/Input";

const ProjectDetailUploadDocument = (props) => {
  return (
    <Modal {...props} size="md" className="project-detail-upload-document-modal" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="header d-flex justify-content-between align-items-center">
          <h1>Add Document</h1>
          <Icon.XLg onClick={props.onHide} />
        </div>
        <div className="d-flex mt-4">
          <div className="add-document-section d-flex w-100">
            <div className="document-card">
              <div className="img">
                <Icon.FiletypePdf />
                <p>Click here to upload your document</p>
              </div>
            </div>
            <Form className="w-100">
              <Form.Item className="mb-4" label="Enter Document Name">
                <Input placeholder="Document name" />
              </Form.Item>
              <div className="footer-btn d-flex gap-4">
                <Button onClick={props.onHide} className="cancel-btn">
                  Cancel
                </Button>
                <Button onClick={props.onHide}>Save</Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProjectDetailUploadDocument;
