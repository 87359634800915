import React from "react";
import "./OverviewSection.scss";

const OverviewSection = () => {
  return (
    <div className="overview-section d-flex flex-md-nowrap flex-wrap">
      <div className="part">
        <div className="number">100</div>
        <div className="title">New Agencies Purchased Plan</div>
      </div>
      <div className="part">
        <div className="number">$ 5k</div>
        <div className="title">Sales Done</div>
      </div>
      <div className="part">
        <div className="number">56</div>
        <div className="title">Demo Request</div>
      </div>
      <div className="part">
        <div className="number">1.1k</div>
        <div className="title">Website Visited</div>
      </div>
    </div>
  );
};

export default OverviewSection;
