import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../helper/axios";
import { notify, toastify } from "../../common/commonFunction";
import { apiendpoints, messages, routes } from "../../constants";

//registerUser
export const reqToRegisterUser = createAsyncThunk("reqToRegisterUser", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.signup, data.data);
    if (response.status === 201 || response.status === 200) {
      notify(messages.toast.auth.register, "success");
      data.navigate(routes.checkEmail, { state: { type: "registerUser" } });
      return response.data;
    }
  } catch (error) {
    notify(error.response.data.message || "Network Error");
    throw error;
  }
});

//LoginUser
export const reqToUserLogin = createAsyncThunk("reqToUserLogin", async (data, thunkAPI) => {
  try {
    const response = await Axios.post(apiendpoints.login, data);
    return response.data.user;
  } catch (error) {
    console.error("err =>", error);
    notify(error.response.data.message || "Network Error");
    throw error;
  }
});
// Request to user login
export const reqToVerifyEmail = createAsyncThunk("reqToVerifyEmail", async (data) => {
  try {
    const response = await Axios.post(`${apiendpoints.verifyEmail}${data.token}`);
    if (response.status === 201 || response.status === 200) {
      notify(messages.toast.auth.verifyEmail, "success");
      data.setIsVerified(true);
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    data.navigate(routes.login);
    throw error;
  }
});

// Request to get user detail
export const reqToGetUser = createAsyncThunk("reqToGetUser", async (data) => {
  try {
    const response = await Axios.get(apiendpoints.getUser, authHeaders());
    if (response.status === 201 || response.status === 200) {
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});

// Request to forget Password
export const reqToForgetPassword = createAsyncThunk("reqToForgetPassword", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.forgetPassword, data.data);
    if (response.status === 201 || response.status === 200) {
      notify(messages.toast.auth.reqToResetPassword, "success");
      data.navigate(routes.checkEmail, { state: { type: "resetPassword" } });
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});

// Request to forget Password
export const verifyForgetPassword = createAsyncThunk("verifyForgetPassword", async (data) => {
  try {
    const response = await Axios.post(`${apiendpoints.verifyForgetPassword}${data.token}`, data.data);
    if (response.status === 201 || response.status === 200) {
      notify(messages.toast.auth.passwordChanged, "success");
      data.navigate(routes.login);
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});

//
export const changePassword = createAsyncThunk("changePassword", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.changePassword, data.data);
    console.log("response", response);
    if (response.status === 201 || response.status === 200) {
      notify(messages.toast.auth.passwordChanged, "success");
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});
