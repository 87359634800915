import React from 'react'
import "./ProjectChat.scss"
const ProjectChat = () => {
  return (
    <section className='project-chat-main'>
      <div className="messages">
        <div className="message-contents">
          <div className="time">12:29 pm</div>
          <p>Create New Project (Projects name is Antalia Tower)</p>
        </div>
      </div>
      <div className="messages owner">
        <div className="message-contents">
          <div className="time">12:29 pm</div>
          <p>Sure! the  Antalia Tower Project Successful. i need more details to create complete project please write down more details   </p>
        </div>
      </div>
      <div className="messages">
        <div className="message-contents">
          <div className="time">12:29 pm</div>
          <p>I would say yeah, like last time, about a month to two months. </p>
        </div>
      </div>
      <div className="messages owner">
        <div className="message-contents">
          <div className="time">12:29 pm</div>
          <p>I wanted to spend the first part of this call going through some of the technical aspects of Plaid's partnership with Duala and how that integration would work. Given that you are recording it and it will go to your engineers</p>
        </div>
      </div>
      <div className="messages">
        <div className="message-contents">
          <div className="time">12:29 pm</div>
          <p>Create New Project (Projects name is Antalia Tower)</p>
        </div>
      </div>
      <div className="messages owner">
        <div className="message-contents">
          <div className="time">12:29 pm</div>
          <p>Sure! the  Antalia Tower Project Successful. i need more details to create complete project please write down more details   </p>
        </div>
      </div>
      <div className="messages">
        <div className="message-contents">
          <div className="time">12:29 pm</div>
          <p>Create New Project (Projects name is Antalia Tower)</p>
        </div>
      </div>
      <div className="messages owner">
        <div className="message-contents">
          <div className="time">12:29 pm</div>
          <p>Sure! the  Antalia Tower Project Successful. i need more details to create complete project please write down more details   </p>
        </div>
      </div>
    </section>
  )
}

export default ProjectChat