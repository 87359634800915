import React, { useEffect, useState } from "react";

import "./MessageScreen.scss";
import messageIcon from "../../../assets/Icon/message-icon.png";
import ProfileImage1 from "../../../assets/images/chat-profile1.jpg";
import ProfileImage2 from "../../../assets/images/chat-profile2.jpg";
import ProfileImage3 from "../../../assets/images/chat-profile3.jpg";
import MsgFilter from "../../../common/message/MsgFilter";
import MsgUserList from "../../../common/message/MsgUserList";
import MsgContent from "../../../common/message/MsgContent";
import ChatAnalyze from "../../../common/message/ChatAnalyze";

const data = [
  {
    image: ProfileImage1,
    name: "John Kate",
    desg: "Project Manager",
    message: "Send you the File",
    pendingMess: "5",
  },
  {
    image: ProfileImage2,
    name: "Kavita.W",
    desg: "Client",
    message: "Send you the File",
    pendingMess: "15",
  },
  {
    image: ProfileImage3,
    name: "Project Name",
    desg: "#group",
    message: "Send you the File",
    pendingMess: "15",
  },
  {
    image: ProfileImage1,
    name: "John Kate",
    desg: "Project Manager",
    message: "Send you the File",
    pendingMess: "5",
  },
];

const MessageScreen = () => {
  const [msgContent, setMsgContent] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const showMsgContent = () => {
    setMsgContent(true);
  };
  const hideMsgContent = () => {
    setMsgContent(false);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 992;
      console.log(ismobile);
      if (ismobile !== isMobile) setIsMobile(ismobile);
    });
  }, [isMobile]);
  return (
    <>
      <section className="message-section-main">
        <div className="section-header">
          <h1 className="heading">
            Messages <img src={messageIcon} alt="" />
          </h1>
        </div>
        <div className="section-content">
          <div className="row px-3">
            {
              <div
                className={`col-xl-3 col-lg-5 
              ${!msgContent || !isMobile ? "d-block" : "d-none"} chat-box p-0`}
              >
                <MsgFilter />
                <MsgUserList data={data} showMsgContent={showMsgContent} />
              </div>
            }
            {
              <div className={`col-xl-6 col-lg-7 ${msgContent || !isMobile ? "d-block" : "d-none"} message-box p-0`}>
                <MsgContent msgContent={msgContent} hideMsgContent={hideMsgContent} />
              </div>
            }
            <ChatAnalyze />
          </div>
        </div>
      </section>
    </>
  );
};

export default MessageScreen;
