import { createSlice } from "@reduxjs/toolkit";
import { reqToAddClient, reqToGetAllClients, reqToSignupClient, reqToVerifyClientToken } from "../services/clientServices";

const initialState = {
  loader: false,
  error: "",
  clients: [],
  totalClientCount: 0,
};

// Create Cleint slice
const clientlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //req To Add Client
    builder.addCase(reqToAddClient.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddClient.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToAddClient.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Verify Client Token
    builder.addCase(reqToVerifyClientToken.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToVerifyClientToken.fulfilled, (state, action) => {
      state.loader = false;
      state.email = action.payload.data.email;
    });
    builder.addCase(reqToVerifyClientToken.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Signup Client
    builder.addCase(reqToSignupClient.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToSignupClient.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToSignupClient.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Get All Clients
    builder.addCase(reqToGetAllClients.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetAllClients.fulfilled, (state, action) => {
      state.clients = action.payload.rows;
      state.totalClientCount = action.payload.count;
      state.loader = false;
    });
    builder.addCase(reqToGetAllClients.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
  },
});

export default clientlice.reducer;
