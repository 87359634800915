import React, { useEffect, useState } from "react";
import "./AddAppointment.scss";
import { Button } from "react-bootstrap";
import { Checkbox, DatePicker, Form, Input, Select, TimePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../constants";
import { reqToGetAllClients } from "../../../../reduxToolkit/services/clientServices";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { reqToCreateAppoinment } from "../../../../reduxToolkit/services/appoinmentServices";
import Loader from "../../../../components/Loader/Loader";
import { reqToGetMembersBytype } from "../../../../reduxToolkit/services/myTeamServices";

const AddAppointment = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appoinmentReducer = useSelector((state) => state.appoinment);
  const clientReducer = useSelector((state) => state.client);
  const { clients } = clientReducer;

  const teamReducer = useSelector((state) => state.myTeam);
  const { membersByType } = teamReducer;
  console.log("membersByType====>", membersByType);

  const [newClient, setNewClient] = useState(true);

  useEffect(() => {
    dispatch(reqToGetAllClients({ page: 1, limit: 1000000 }));
    dispatch(reqToGetMembersBytype({ page: 1, limit: 1000000, memberType: "managers" }));
  }, []);

  const handleChange = () => {
    setNewClient(!newClient);
    if (form) {
      form.setFieldsValue({
        client: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      });
    }
  };

  const goBackHandler = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentAppointment}/page/1`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffAppointment}/page/1`);
    } else {
      navigate(`${routes.appointment}/page/1`);
    }
  };

  const handleClientChange = (val) => {
    const selectedClient = clients.find((client) => client.client.cid === val);

    if (form) {
      form.setFieldsValue({
        client: val,
        firstName: selectedClient.firstName,
        lastName: selectedClient.lastName,
        email: selectedClient.email,
        phone: selectedClient.phone,
      });
    }
  };

  const onSubmitHandler = (values) => {
    const data = {
      cid: values.client ? values.client : null,
      uid: values.manager,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: +values.phone,
      dateTime: moment(new Date(values.date)).format("YYYY-MM-DD") + " " + moment(new Date(values.time)).format("h:mm A"),
      description: values.description ? values.description : null,
    };

    const route =
      userRole === "AGENCY_AGENT"
        ? `${routes.agentAppointment}/page/1`
        : userRole === "AGENCY_STAFF"
        ? `${routes.staffAppointment}/page/1`
        : `${routes.appointment}/page/1`;
    dispatch(reqToCreateAppoinment({ data: data, navigate, route: route }));
  };

  return (
    <>
      {(clientReducer.loader || appoinmentReducer.loader || teamReducer.loader) && <Loader />}
      <section className="add-appointment-screen">
        <div className="add-appointment-header">
          <h1 className="heading">Set New Appointment</h1>
        </div>
        <Form className="add-appointment-form" form={form} onFinish={onSubmitHandler}>
          <div className="d-flex align-items-center gap-5 client-information mb-2">
            <h4 className="border-0 mb-1">Client Information</h4>
            <Checkbox className="pt-3" onChange={handleChange}>
              Existing Client
            </Checkbox>
          </div>
          {!newClient ? (
            <>
              <div className="d-flex gap-md-4 flex-md-nowrap flex-wrap">
                <div className="w-md-50 w-100 mb-4">
                  <Form.Item
                    className="client-item-input"
                    label="Select Manager*"
                    name="manager"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Manager!",
                      },
                    ]}
                  >
                    <Select name="manager" allowClear>
                      <Select.Option value="" disabled>
                        Select Manager Name
                      </Select.Option>
                      {membersByType.length !== 0 &&
                        membersByType.map((manager, i) => {
                          return (
                            <Select.Option key={i} value={manager.uid}>
                              {manager.firstName + " " + manager.lastName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="w-md-50 w-100 mb-4">
                  <Form.Item
                    className="client-item-input"
                    label="Select Client*"
                    name="client"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Client!",
                      },
                    ]}
                  >
                    <Select onChange={handleClientChange}>
                      <Select.Option value="" disabled>
                        Select Client Name
                      </Select.Option>
                      {clients.length !== 0 &&
                        clients.map((client, i) => {
                          return (
                            <Select.Option key={i} value={client.client.cid}>
                              {client.firstName + " " + client.lastName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex gap-md-4 flex-md-nowrap flex-wrap">
                <div className="d-flex w-md-50 w-100 gap-4">
                  <Form.Item className="w-50 mb-4" label="Email*" name="email">
                    <Input placeholder="abc@gmail.com" disabled />
                  </Form.Item>
                  <Form.Item className="w-50 mb-4" label="Phone*" name="phone">
                    <Input placeholder="+01 00000 00000" disabled />
                  </Form.Item>
                </div>
                <div className="d-flex w-md-50 w-100 gap-4">
                  <Form.Item className="w-50 mb-4" label="First Name*" name="firstName">
                    <Input placeholder="Enter first name" disabled />
                  </Form.Item>
                  <Form.Item className="w-50 mb-4" label="Last Name*" name="lastName">
                    <Input placeholder="Enter last name" disabled />
                  </Form.Item>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex gap-md-4 flex-md-nowrap flex-wrap">
                <div className="w-md-50 w-100 mb-4">
                  <Form.Item
                    className="client-item-input"
                    label="Select Manager*"
                    name="manager"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Manager!",
                      },
                    ]}
                  >
                    <Select name="manager" allowClear>
                      <Select.Option value="" disabled>
                        Select Manager Name
                      </Select.Option>
                      {membersByType.length !== 0 &&
                        membersByType.map((manager, i) => {
                          return (
                            <Select.Option key={i} value={manager.uid}>
                              {manager.firstName + " " + manager.lastName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="d-flex w-md-50 w-100 gap-4">
                  <Form.Item
                    className="w-50 mb-4"
                    label="First Name*"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "First name is required!",
                        transform: (value) => {
                          if (value) {
                            return value.trim();
                          }
                          return value;
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Enter first name" name="firstName" />
                  </Form.Item>
                  <Form.Item
                    className="w-50 mb-4"
                    label="Last Name*"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Last name is required!",
                        transform: (value) => {
                          if (value) {
                            return value.trim();
                          }
                          return value;
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Enter last name" name="lastName" />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex w-100 gap-4">
                <Form.Item
                  className="w-50 mb-4"
                  label="Email*"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email!",
                    },
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input placeholder="abc@gmail.com" name="email" />
                </Form.Item>
                <Form.Item
                  className="w-50 mb-4"
                  label="Phone*"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone number is required!",
                      transform: (value) => {
                        if (value) {
                          return value.trim();
                        }
                        return value;
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="+01 00000 00000"
                    name="phone"
                    onKeyPress={(e) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </>
          )}
          <h4 className="mt-1">Select Date & time*</h4>
          <div className="d-flex mb-4 gap-5 flex-md-nowrap flex-wrap">
            <Form.Item
              className="form-date-picker select-date-time w-md-50 w-100"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select a date!",
                },
              ]}
            >
              <DatePicker placeholder="dd-mm-yyyy" format={"DD-MM-YYYY"} />
            </Form.Item>
            <Form.Item
              className="form-date-picker select-date-time w-md-50 w-100"
              name="time"
              rules={[
                {
                  required: true,
                  message: "Please select a time!",
                },
              ]}
            >
              <TimePicker format={"h:mm a"} minuteStep={15} />
            </Form.Item>
          </div>
          <Form.Item
            className="mb-4"
            label="Description*"
            name="description"
            rules={[
              {
                required: true,
                message: "Description is required!",
                transform: (value) => {
                  if (value) {
                    return value.trim();
                  }
                  return value;
                },
              },
            ]}
          >
            <TextArea rows={4} placeholder="Write description" />
          </Form.Item>
          <div className="add-member-footer-btn d-flex w-50 ms-auto gap-4">
            <Button className="cancel-btn" onClick={() => goBackHandler()}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};

export default AddAppointment;
