import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./PaymentPopup.scss"

const PaymentPopup = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            className="payment-modal-main"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="heading">
                    Transaction Details
                </div>
                <div className="transaction-details">
                    <div className="transcation">
                        <div className="tag">Transaction Date</div>
                        <div className="text">18/08/2023 06:31 PM</div>
                    </div>
                    <div className="transcation">
                        <div className="tag">Project Name</div>
                        <div className="text">Antalia Tower</div>
                    </div>
                    <div className="transcation">
                        <div className="tag">Ref ID</div>
                        <div className="text">180720231831</div>
                    </div>
                    <div className="transcation">
                        <div className="tag">Amount</div>
                        <div className="text">$2000</div>
                    </div>
                    <div className="transcation">
                        <div className="tag">Status</div>
                        <div className="text">Complete</div>
                    </div>
                    <div className="transcation">
                        <div className="tag">Customer Name</div>
                        <div className="text">John Doe</div>
                    </div>
                    <div className="transcation">
                        <div className="tag">Remark</div>
                        <div className="text">NO remark</div>
                    </div>
                </div>
                <div className="ok-btn" >
                    <Button onClick={props.onHide}>OK</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PaymentPopup