export const tHforAppoinment = (userType) => {
  console.log("userType", userType);
  if (userType === "client") {
    return [
      { title: "Reference ID" },
      { title: "Agent Name" },
      { title: "Contact No." },
      { title: "Email Id" },
      { title: "Date & Time" },
      { title: "Status" },
      { title: "#" },
    ];
  } else {
    return [
      { title: "Reference ID" },
      { title: "Client Name" },
      { title: "Contact No." },
      { title: "Email Id" },
      { title: "Date & Time" },
      { title: "Status" },
      { title: "#" },
    ];
  }
};
