import React from 'react';
import DashboardIcon from "../../../assets/Icon/home-icon.svg"
import ProjectIcon from "../../../assets/Icon/project-icon.svg"
import OpportunityIcon from "../../../assets/Icon/opportunity-icon.svg"
import AppointmentIcon from "../../../assets/Icon/appointment-icon.svg"
import TeamIcon from "../../../assets/Icon/myTeam-icon.svg"
import LogoutIcon from "../../../assets/Icon/logout-icon.svg"
import Logo from "../../../assets/images/logo.svg"
import "./AdminLayout.scss"
import { Outlet, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import AdminHeader from '../Header/AdminHeader';
import { useDispatch } from 'react-redux';
import { reqToUserLogout } from '../../../reduxToolkit/slice/userSlice';

const menu = [
  { label: "Dashboard", icon: DashboardIcon, url: "/admin" },
  { label: "Agencies", icon: ProjectIcon, url: "/admin/agency" },
  { label: "Plans", icon: OpportunityIcon, url: "/admin/plan" },
  { label: "Payments", icon: AppointmentIcon, url: "/admin/payment" },
  { label: "Help & Support", icon: TeamIcon, url: "/admin/help" },
]
const AdminLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(reqToUserLogout())
  }
  return (
    <section className='admin-layout-section row m-0'>
      <div className="side-bar-menu-main d-lg-block d-none col-xl-2 col-lg-3 px-0">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="sidebar-slider">
          <div className="menu-main">
            {menu.map((item, i) => (
              <div className="list-main" key={i}>
                <Button disabled={item.disabled} className="list" onClick={() => navigate(item.url)}>
                  <img className='list-icon' src={item.icon} alt="" />
                  <span className='list-item-name'>{item.label}</span>
                </Button>
              </div>
            ))}
          </div>
          <div className="logout-main">
            <div className="logout-list" onClick={() => handleLogout()}>
              <img src={LogoutIcon} alt="" className='logout-icon' />
              <span className='logout-list-name'>Logout</span>
            </div>
          </div>
        </div>
      </div>
      <div className="body-section col-xl-10 col-lg-9 px-0">
        <div className="header">
          <AdminHeader menu={menu} />
        </div>
        <div className="body-container">
          <Outlet />
        </div>
      </div>
    </section>
  )
}

export default AdminLayout