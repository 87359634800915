import React from 'react'
import * as Icon from "react-bootstrap-icons"
import { Button, Col, Row, Table } from 'react-bootstrap'
import rightArrow from "../../../../assets/Icon/right-arrow-icon.svg"
import "./ProjectDetailMember.scss"

const ProjectDetailMember = ({ setAssignMemberShow }) => {
  return (
    <div className='project-detail-member'>
      <div className="d-flex justify-content-between align-items-center total-document">
        <h4>10 Member</h4>
        <Button onClick={() => setAssignMemberShow(true)}><Icon.PlusLg />Assign New Member</Button>
      </div>
      <Row className='gap-lg-0 gap-4'>
        <Col lg={6}>
          <div className="member-table">
            <Table size="lg" responsive>
              <thead>
                <tr>
                  <th>Member ID</th>
                  <th>Member Details</th>
                  <th>Assigned Task</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <div className="client-details">
                      <div className="client-avatar">
                        <img src="https://picsum.photos/id/1/200/300" alt="" />
                      </div>
                      <div className="detail">
                        <div className="name">Martha S.</div>
                        <div className="email">+1 12321 12321</div>
                        <div className="email">marthas123@gmail.com</div>
                      </div>
                    </div>
                  </td>
                  <td>2 Tasks</td>
                  <td>
                    <img src={rightArrow} alt="" style={{ cursor: "pointer" }} />
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <div className="client-details">
                      <div className="client-avatar">
                        <img src="https://picsum.photos/id/1/200/300" alt="" />
                      </div>
                      <div className="detail">
                        <div className="name">Martha S.</div>
                        <div className="email">+1 12321 12321</div>
                        <div className="email">marthas123@gmail.com</div>
                      </div>
                    </div>
                  </td>
                  <td>2 Tasks</td>
                  <td>
                    <img src={rightArrow} alt="" style={{ cursor: "pointer" }} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
        <Col lg={6}>
          <div className="member-assigned-task">
            <div className="header d-flex align-items-center">
              <div className="client-avatar">
                <img src="https://picsum.photos/id/1/200/300" alt="" />
              </div>
              <div className="name">Martha's Assigned Task</div>
            </div>
            <div className="content">
              <ol>
                <li>
                  <h2> Sample task identify and engage with all relevant</h2>
                  <p>Sample task identify and engage with all relevant. Sample task identify and engage with all relevant. Sample task identify and engage with all relevant. Sample task identify and engage with all relevant.</p>
                  <div className="due-date">Due Date:
                    <span>19/07/2023 (Delay or Onetime Status)</span>
                  </div>
                </li>
                <li>
                  <h2> Sample task identify and engage with all relevant</h2>
                  <p>Sample task identify and engage with all relevant. Sample task identify and engage with all relevant. Sample task identify and engage with all relevant. Sample task identify and engage with all relevant.</p>
                  <div className="due-date">Due Date:
                    <span>19/07/2023 (Delay or Onetime Status)</span>
                  </div>
                </li>
              </ol>

            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ProjectDetailMember
