import React, { useState } from "react";
import { Table, InputGroup, Form, Modal, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { Pagination } from "antd";
import "./Plan.scss";
import PaymentPopup from "../../../components/Modal/paymentPopup/PaymentPopup";
import { useNavigate } from "react-router-dom";

const Plan = () => {
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const planDetail = () => {
    navigate("/admin/plan-detail");
  };
  const createPlan = () => {
    navigate("/admin/create-plan");
  };
  return (
    <section className="plan-screen">
      <div className="list-table">
        <div className="table-header">
          <div className="heading">
            <h2>Current Plans</h2>
            <h3>
              Total Plans: <span>4</span>
            </h3>
          </div>
          <div className="search-section d-lg-block d-none">
            <InputGroup>
              <Form.Control placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2" />
              <InputGroup.Text id="basic-addon2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <Button className="create-plan-btn" onClick={createPlan}>
            <Icon.PlusLg /> Create New Plan
          </Button>
        </div>
        <Table size="lg" responsive>
          <thead>
            <tr>
              <th>Plan Name</th>
              <th>Price</th>
              <th>Period</th>
              <th>Discount</th>
              <th>Coupon Code</th>
              <th>Plan Issue On</th>
              <th>Expiry On</th>
              <th>Users</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 10 }).map((item, i) => (
              <tr key={i}>
                <td>FREE</td>
                <td>$ 00</td>
                <td>Monthly</td>
                <td>30%</td>
                <td>BAR30</td>
                <td>08 July,2023</td>
                <td>--</td>
                <td>100</td>
                <td>
                  <div className="d-flex justify-content-center align-items-center icons">
                    <Icon.EyeFill onClick={planDetail} />
                    <Icon.TrashFill onClick={handleShow} />
                    <Form className="input-switch">
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                      />
                    </Form>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-main">
          <Pagination total={100} />
        </div>
      </div>
      <PaymentPopup show={modalShow} onHide={() => setModalShow(false)} />
      <Modal show={show} onHide={handleClose} className="delete-modal" centered>
        <Modal.Body>
          <h2>Delete</h2>
          <div className="confirmation">Are you sure you want to delete this item ?</div>
          <div className="footer-btn d-flex">
            <Button className="cancel-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button>Delete</Button>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Plan;
