import React from "react";
import LeftArrow from "../../../assets/Icon/arrow-left-line.svg";
import "./CreatePlan.scss";
import addIcon from "../../../assets/Icon/add-icon.png";
import { Button } from "react-bootstrap";
import { Form, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";

const CreatePlan = () => {
  const navigate = useNavigate();
  const opportunity = () => {
    navigate("/admin/plan");
  };
  const plan = () => {
    navigate("/admin/plan");
  };
  return (
    <section className="create-plan-screen">
      <div className="title">
        <span onClick={plan} style={{ cursor: "pointer" }}>
          <img src={LeftArrow} alt="" />
        </span>
        <h2>Back to list</h2>
      </div>
      <Form className="create-plan-form d-flex gap-5 flex-sm-row flex-column">
        <div className="w-sm-50 w-100">
          <h4>Plan Details</h4>
          <Form.Item className="mb-4" label="Plan Name*">
            <Input placeholder="Enter Plan Name" />
          </Form.Item>
          <Form.Item className="mb-4" label="Price*">
            <Input placeholder="$ 0000.00" />
          </Form.Item>
          <Form.Item className="mb-4" label="Select Period*">
            <Select>
              <Select.Option value="demo">Monthly</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item className="mb-4" label="Offered Discount (%) (Option)">
            <Input placeholder="ie. 10%" />
          </Form.Item>
          <Form.Item className="mb-4" label="Coupon Code (Option)">
            <Input placeholder="ie. 10%" />
          </Form.Item>
          <Form.Item className="mb-4" label="Expiry Date (Option)">
            <Input placeholder="ie. 10%" />
          </Form.Item>
        </div>
        <div className="w-sm-50 w-100">
          <h4>Plan Features</h4>
          <Form.Item className="mb-4" label="Features*">
            <Input placeholder="Enter Plan Feature" />
          </Form.Item>
          <Button className="add-btn">
            <img src={addIcon} alt="" /> Add More
          </Button>
          <Form.Item className="mb-4" label="Key Features*">
            <Input placeholder="Enter Plan Key Feature" />
          </Form.Item>
          <Button className="add-btn">
            <img src={addIcon} alt="" /> Add More
          </Button>
        </div>
      </Form>
      <div className="add-member-footer-btn d-flex justify-content-end gap-4">
        <Button className="cancel-btn" onClick={opportunity}>
          Cancel
        </Button>
        <Button>Submit</Button>
      </div>
    </section>
  );
};

export default CreatePlan;
