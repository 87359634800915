import { createSlice } from "@reduxjs/toolkit";
import { reqToAddMember, reqToGetAllMembers, reqToGetMembersBytype, reqToSignupMember, reqToVerifyMemberToken } from "../services/myTeamServices";

const initialState = {
  loader: false,
  error: "",
  members: [],
  totalMemberCount: 0,
  membersByType: [],
  totalMembersByTypeCount: 0,
};

// Create myTeam slice
const myTeamSlice = createSlice({
  name: "myTeam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //reqToAddMember
    builder.addCase(reqToAddMember.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddMember.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToAddMember.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Verify Member Token
    builder.addCase(reqToVerifyMemberToken.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToVerifyMemberToken.fulfilled, (state, action) => {
      state.loader = false;
      state.email = action.payload.data.email;
    });
    builder.addCase(reqToVerifyMemberToken.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Signup Member
    builder.addCase(reqToSignupMember.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToSignupMember.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToSignupMember.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Get All Members
    builder.addCase(reqToGetAllMembers.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetAllMembers.fulfilled, (state, action) => {
      state.members = action.payload.rows;
      state.totalMemberCount = action.payload.count;
      state.loader = false;
    });
    builder.addCase(reqToGetAllMembers.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Get All Members
    builder.addCase(reqToGetMembersBytype.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMembersBytype.fulfilled, (state, action) => {
      state.membersByType = action.payload.rows;
      state.totalMembersByTypeCount = action.payload.count;
      state.loader = false;
    });
    builder.addCase(reqToGetMembersBytype.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
  },
});

export default myTeamSlice.reducer;
