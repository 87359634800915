import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, Button } from "react-bootstrap";
import { Pagination } from "antd";
// import { useDispatch } from "react-redux";

import data from "../../../data/appointment.json";
import PlusIcon from "../../../assets/Icon/plus-icon.svg";
import { routes } from "../../../constants";
import AppointmentTable from "../../../common/appoinment/AppointmentTable";

const ClientAppoinment = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const params = useParams();
  const { page_number } = params;

  const [page, setPage] = useState(page_number);
  const [tab, setTab] = useState("today");

  const bookAppointment = () => {
    navigate(routes.clientbookAppointment);
  };

  const navigateToDetailPage = () => {
    navigate(routes.clientAppointmentDetail);
  };

  const onTabChange = (val) => {
    setTab(val);
  };

  return (
    <>
      {" "}
      <section className="appointment-table-main">
        <div className="list-header">
          <h1 className="heading">Appointment</h1>
          <div className="create-project-btn">
            <Button onClick={bookAppointment}>
              <img src={PlusIcon} alt="" />
              Book Appointment
            </Button>
          </div>
        </div>
        <div className="table-header">
          <div className="categories-main">
            <div className="tab-main">
              <Tabs id="uncontrolled-tab-example" className="mb-3" onSelect={(e) => onTabChange(e)}>
                <Tab eventKey="today" title="Today">
                  <div className="list-table">
                    <AppointmentTable tab={tab} data={data} goToDetailPage={navigateToDetailPage} userType={"client"} />
                    <div className="pagination-main">
                      <Pagination
                        total={100}
                        // onChange={onPageChange}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="completed" title="Completed">
                  <div className="list-table">
                    <AppointmentTable tab={tab} data={data} goToDetailPage={navigateToDetailPage} userType={"client"} />
                    <div className="pagination-main">
                      <Pagination
                        total={100}
                        // onChange={onPageChange}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientAppoinment;
