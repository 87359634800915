import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";

import "./SignUp.scss";
import AuthImage from "../../../assets/images/auth-image.png";
import BlackLogo from "../../../assets/images/black-logo.svg";
import EyeImage from "../../../assets/Icon/eye.png";
import { routes } from "../../../constants";
import Loader from "../../../components/Loader/Loader";
import { reqToRegisterUser } from "../../../reduxToolkit/services/userServices";
import { textError } from "../../../common/commonFunction";
import { agencyValidation, commonSignupFormValidation } from "../../../validation/AuthValidation";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userReducer = useSelector((state) => state.user);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    isAgency: false,
    agencyName: "",
    phone: "",
    termsPolicy: false,
  };

  const SignUpValidation = Yup.lazy((values) => (values.isAgency ? agencyValidation : commonSignupFormValidation));

  const handleSubmit = (values, { setSubmitting }) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      isAgency: values.isAgency || null,
      agencyName: values.isAgency ? values.agencyName : null,
      phone: values.isAgency ? values.phone : null,
    };
    dispatch(reqToRegisterUser({ data, navigate }));
    setSubmitting(false);
  };

  const handleChangeForAgancy = (e, setFieldValue, setFieldTouched) => {
    const { name, checked } = e.target;

    setFieldValue("agencyName", "");
    setFieldValue("phone", "");
    setFieldTouched("agencyName", false);
    setFieldTouched("phone", false);

    setFieldValue(name, checked);
  };

  const handleChangeForPhone = (e, setFieldValue) => {
    const { name, value } = e.target;
    const numericInput = value.replace(/[^0-9]/g, "");
    setFieldValue(name, numericInput);
  };

  return (
    <>
      {userReducer.loader && <Loader />}
      <section className="sign-up-section-main">
        <div className="row w-100">
          <div className="col-xl-7 p-0">
            <div className="signup-form-main d-flex justify-content-center">
              <div className="form-inner">
                <div className="logo">
                  <img src={BlackLogo} alt="" />
                </div>
                <Formik initialValues={initialValues} validationSchema={SignUpValidation} onSubmit={handleSubmit} enableReinitialize>
                  {({ isSubmitting, values, setFieldValue, setFieldTouched }) => {
                    return (
                      <Form>
                        <div className="form-header">
                          <h1>Create an account</h1>
                          <h4>Please create the account with your details.</h4>
                        </div>
                        <div className="form-content">
                          <div className="d-flex gap-md-4">
                            <div className="w-50">
                              <label htmlFor="fName">First name</label>
                              <div className="input-field">
                                <Field id="fName" type="text" name="firstName" placeholder="First Name" />
                                <ErrorMessage name="firstName" component={textError} />
                              </div>
                            </div>
                            <div className="w-50">
                              <label htmlFor="lName">Last name</label>
                              <div className="input-field">
                                <Field id="lName" type="text" name="lastName" placeholder="Last Name" />
                                <ErrorMessage name="lastName" component={textError} />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-md-4">
                            <div className="w-50">
                              <label htmlFor="email">Email address</label>
                              <div className="input-field">
                                <Field id="email" type="email" name="email" placeholder="Email" />
                                {/* <div className="icon">
                            <img src={CheckIcon} alt="" />
                          </div> */}
                                <ErrorMessage name="email" component={textError} />
                              </div>
                            </div>
                            <div className="w-50">
                              <label htmlFor="password">Password</label>
                              <div className="input-field">
                                <Field id="password" type="password" name="password" placeholder="Password" />
                                <div className="icon">
                                  <img src={EyeImage} alt="" />
                                </div>
                                <ErrorMessage name="password" component={textError} />
                              </div>
                            </div>
                          </div>
                          <div className="signup-agency">
                            <Field
                              type="checkbox"
                              name="isAgency"
                              className="check"
                              onChange={(e) => handleChangeForAgancy(e, setFieldValue, setFieldTouched)}
                            />
                            <label htmlFor="signup-agency">Signup as an Agency</label>
                          </div>
                          {values.isAgency && (
                            <div className="d-flex gap-md-4">
                              <div className="input-field w-50">
                                <label htmlFor="agencyName">Agency Name</label>
                                <Field id="agencyName" type="text" name="agencyName" placeholder="Agency Name" />
                                <ErrorMessage name="agencyName" component={textError} />
                              </div>
                              <div className="input-field w-50">
                                <label htmlFor="phone">Mobile Number</label>
                                <Field
                                  id="phone"
                                  type="text"
                                  name="phone"
                                  placeholder="Mobile Number"
                                  onChange={(e) => handleChangeForPhone(e, setFieldValue)}
                                />
                                <ErrorMessage name="phone" component={textError} />
                              </div>
                            </div>
                          )}
                          <div className="terms-policy">
                            <Field type="checkbox" name="termsPolicy" id="termsPolicy" className="check" />
                            <span>
                              You are about to sign up, please review out <a href="">terms of use policy</a>
                            </span>
                          </div>
                          <ErrorMessage name="termsPolicy" component={textError} />
                          {/* <div className="login-screen-divider ">
                    <hr />
                    <div className="text">Or connect via</div>
                    <hr />
                  </div>
                  <div className="sign-up-with-icon-group">
                    <div className="google-icon icon">
                      <img src={GoogleIcon} alt="" />
                    </div>
                    <div className="facebook-icon icon">
                      <img src={FacebookIcon} alt="" />
                    </div>
                    <div className="aplle-icon icon">
                      <img src={AppleIcon} alt="" />
                    </div>
                  </div> */}
                          <Button className="sign-up-btn" type="submit" disabled={isSubmitting}>
                            SIGN UP
                          </Button>
                          <div className="login-idicator">
                            Already a member please <span onClick={() => navigate(routes.login)}>Login to your account</span>
                          </div>
                        </div>
                        {/* <div className="please-verification-msg">
                  <div className="d-flex title">
                    <Icon.CheckCircleFill />A verification link has been sent to your email account
                  </div>
                  <div className="content">Please click on the link that has just been sent to your email account to verify your email and continue the registration process.</div>
                </div> */}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-xl-5 d-xl-block d-none p-0">
            <div className="sign-up-image-section">
              <img src={AuthImage} alt="auth img" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
