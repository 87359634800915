import React from "react";
import { Table } from "react-bootstrap";

import "./TransactionTable.scss";
import ProfileImage1 from "../../assets/images/chat-profile1.jpg";
import ProfileImage2 from "../../assets/images/chat-profile2.jpg";
import ProfileImage3 from "../../assets/images/chat-profile3.jpg";
import invoiceIcon from "../../assets/Icon/invoice.png";

const data = [
  {
    image: ProfileImage1,
    id: "TID-2012362254",
    name: "Solo estate Ltd.",
    email: "Solo@gmail.com",
    plan: "Pro/ Monthly",
    amount: "$ 398.52",
    date: "08 July,2023 01:57 PM",
  },
  {
    image: ProfileImage2,
    id: "TID-2012362254",
    name: "Solo estate Ltd.",
    email: "Solo@gmail.com",
    plan: "Pro/ Monthly",
    amount: "$ 398.52",
    date: "08 July,2023 01:57 PM",
  },
  {
    image: ProfileImage3,
    id: "TID-2012362254",
    name: "Solo estate Ltd.",
    email: "Solo@gmail.com",
    plan: "Pro/ Monthly",
    amount: "$ 398.52",
    date: "08 July,2023 01:57 PM",
  },
];

const TransactionTable = () => {
  return (
    <Table size="lg" responsive className="transaction-table">
      <thead>
        <tr>
          <th>Transaction ID</th>
          <th>Agency Details</th>
          <th>Plan Details</th>
          <th>Amount</th>
          <th>Date/Time</th>
          <th>Invoice</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => (
          <tr key={i}>
            <td>{item.id}</td>
            <td>
              <div className="client-details">
                <div className="client-avatar">
                  <img src="https://picsum.photos/id/1/200/300" alt="" />
                </div>
                <div className="detail">
                  <div className="name">{item.name}</div>
                  <div className="email">{item.email}</div>
                </div>
              </div>
            </td>
            <td>{item.plan}</td>
            <td>{item.amount}</td>
            <td>{item.date}</td>
            <td>
              <img src={invoiceIcon} alt="" />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TransactionTable;
