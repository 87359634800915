import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Pagination } from "antd";
import "./OpportunityTab.scss";
import { useNavigate } from "react-router-dom";
import OppTable from "../../../../common/opportunity/OppTable";
import { useDispatch } from "react-redux";
import { reqToGetOpportunities, reqToUpdateOpportunity } from "../../../../reduxToolkit/services/opportunityServices";
import { routes } from "../../../../constants";

const OpportunityTab = ({ onPageChange, opportunities, totalOpportunityCount, onOppTabChange, oppTabsStatus, page, userRole }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToDetailPage = (oppDetail) => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentOpportunity}/detail/${oppDetail.oprId}`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`/staff-opportunity/detail/${oppDetail.oprId}`);
    } else {
      navigate(`/opportunity-detail/${oppDetail.oprId}`);
    }
  };

  const opportunitiesStatus = [
    { key: "", label: "All" },
    { key: "1", label: "New" },
    { key: "2", label: "Follow Up" },
    { key: "3", label: "Accept" },
    { key: "4", label: "Not Intrested" },
  ];

  const onChangeOppStatus = (e, opp) => {
    const data = {
      status: +e.target.value,
    };

    dispatch(
      reqToUpdateOpportunity({
        oprId: opp.oprId,
        data: data,
        cb: (res) => {
          dispatch(reqToGetOpportunities({ status: oppTabsStatus, page: page, limit: 10 }));
        },
      })
    );
  };

  return (
    <section className="opportunity-table">
      {/* <div className="agent-dropdown">
        <Dropdown className="d-lg-block d-none">
          <Dropdown.Toggle id="dropdown-basic">All</Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="droup-item">
              <input type="checkbox" name="" id="" />
              <span>All </span>
            </div>
            <div className="droup-item">
              <input type="checkbox" name="" id="" />
              <span>Admin</span>
            </div>
            <div className="droup-item">
              <input type="checkbox" name="" id="" />
              <span>Member Name </span>
            </div>
            <div className="droup-item">
              <input type="checkbox" name="" id="" />
              <span>Member Name</span>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
      <Tabs id="uncontrolled-tab-example" className="mb-3" onSelect={(e) => onOppTabChange(e)} activeKey={oppTabsStatus}>
        {opportunitiesStatus.map((oppStatus, i) => (
          <Tab eventKey={oppStatus.key} title={oppStatus.label} key={i}>
            <OppTable data={opportunities} goToDetailPage={goToDetailPage} onChangeOppStatus={onChangeOppStatus} />
            <div className="pagination-main">
              <Pagination total={totalOpportunityCount} current={+page} onChange={onPageChange} />
            </div>
          </Tab>
        ))}
      </Tabs>
    </section>
  );
};

export default OpportunityTab;
