import React from "react";
import { Table } from "react-bootstrap";
import rightArrow from "../../assets/Icon/right-arrow-icon.svg";
import "./OppTable.scss";
import moment from "moment";

const OppTable = ({ data, goToDetailPage, onChangeOppStatus }) => {
  return (
    <Table size="lg" responsive className="opportunity-table">
      <thead>
        <tr>
          <th>Opportunity ID</th>
          <th>Client Name</th>
          <th>Contact No.</th>
          <th>Email Id</th>
          <th>Date</th>
          <th>Status</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 ? (
          <tr>
            <td colSpan={7} className="no-data-found">
              No data found
            </td>
          </tr>
        ) : (
          data.map((item, i) => (
            <tr key={i}>
              <td>{item.oprId}</td>
              <td>{item.firstName + " " + item.lastName}</td>
              <td>{item.phone}</td>
              <td>{item.email}</td>
              <td>{moment(item.createdAt).format("D MMM, YYYY")}</td>
              <td>
                <select name="" id="" value={item.status} onChange={(e) => onChangeOppStatus(e, item)}>
                  <option value={1} className="new">
                    New
                  </option>
                  <option value={2} className="follow-up">
                    Follow Up
                  </option>
                  <option value={3} className="sold">
                    Accept
                  </option>
                  <option value={4} className="not-relevant">
                    Not Intrested
                  </option>
                </select>
              </td>
              <td>
                <img src={rightArrow} style={{ cursor: "pointer" }} onClick={() => goToDetailPage(item)} alt="" />
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default OppTable;
