import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Formik, Form } from "formik";

import "./ForgetPassword.scss";
import LoginImage from "../../../assets/images/login-image.png";
import BlackLogo from "../../../assets/images/black-logo.svg";
import { forgetPassEmailValidation } from "../../../validation/AuthValidation";
import { routes } from "../../../constants";
import { textError } from "../../../common/commonFunction";
import { reqToForgetPassword } from "../../../reduxToolkit/services/userServices";
import Loader from "../../../components/Loader/Loader";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userReducer = useSelector((state) => state.user);

  const initialValues = {
    email: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const data = {
      email: values.email,
    };
    dispatch(reqToForgetPassword({ data, navigate }));
    setSubmitting(false);
  };

  return (
    <>
      {userReducer.loader && <Loader />}
      <section className="login-section-main">
        <div className="row w-100">
          <div className="col-xl-7 p-0">
            <div className="signup-form-main d-flex justify-content-center">
              <div className="form-inner">
                <div className="logo">
                  <img src={BlackLogo} alt="" />
                </div>
                <Formik initialValues={initialValues} validationSchema={forgetPassEmailValidation} onSubmit={handleSubmit}>
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="form-header">
                        <h1>Reset Password</h1>
                        <h4>Please enter your account email address</h4>
                      </div>
                      <div className="form-content">
                        <label htmlFor="email">Email address</label>
                        <div className="input-field">
                          <Field id="email" type="email" name="email" placeholder="Email" />

                          <ErrorMessage name="email" component={textError} />
                        </div>

                        <div className="remember-me">
                          <div className="check-input">
                            <div></div>
                          </div>
                          <Link to={routes.login}>Login ?</Link>
                        </div>
                        <Button className="sign-up-btn" type="submit" disabled={isSubmitting}>
                          SUBMIT
                        </Button>
                        {/* <div className="login-idicator">
                          Don’t have an account please <span onClick={() => navigate(routes.signup)}>Sign up</span>
                        </div> */}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-xl-5 d-xl-block d-none p-0">
            <div className="sign-up-image-section">
              <img src={LoginImage} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
