import React from "react";
import { Table } from "react-bootstrap";
import "./AppointmentTable.scss";
import rightArrow from "../../assets/Icon/right-arrow-icon.svg";
import moment from "moment";
import { tHforAppoinment } from "../../constants/tableHeaders";

const AppointmentTable = ({ tab, data, goToDetailPage, userType }) => {
  return (
    <Table size="lg" responsive className="appoinment-table">
      <thead>
        <tr>
          {tHforAppoinment(userType).map((th, i) => {
            return <th key={i}>{th.title}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.length === 0 ? (
          <tr>
            <td colSpan={7} className="no-data-found">
              No data found
            </td>
          </tr>
        ) : (
          data.map((item, i) =>
            userType === "client" ? (
              <tr key={i}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.contact}</td>
                <td>{item.email}</td>
                <td>
                  <div className="date-and-time">
                    <div className="date">{item.date + item.date}</div>
                  </div>
                </td>
                <td>{item.status}</td>
                <td>
                  <img src={rightArrow} onClick={() => goToDetailPage()} alt="" style={{ cursor: "pointer" }} />
                </td>
              </tr>
            ) : (
              <tr key={i}>
                <td>{item.apmtId}</td>
                <td>{item.firstName + " " + item.lastName}</td>
                <td>{item.phone}</td>
                <td>{item.email}</td>
                <td>
                  <div className="date-and-time">
                    <div className="date">{moment.utc(item.dateTime).format("D MMM, YYYY h:mm a")}</div>
                  </div>
                </td>
                <td>{item.status || tab}</td>
                <td>
                  <img src={rightArrow} onClick={() => goToDetailPage(item)} alt="" style={{ cursor: "pointer" }} />
                </td>
              </tr>
            )
          )
        )}
      </tbody>
    </Table>
  );
};

export default AppointmentTable;
