import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import { createLogger } from "redux-logger";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
// import localStorage from "redux-persist/es/storage";
import sessionStorage from "redux-persist/es/storage/session";
import myTeamReducer from "./slice/myTeamSlice";
import clientReducer from "./slice/clientSlice";
import appoinmentReducer from "./slice/appoinmentSlice";
import opportunityReducer from "./slice/opportunitySlice";
import proposalReducer from "./slice/proposalSlice";

const getConfig = (key, whitelist) => {
  const persistConfig = {
    key: key,
    storage: sessionStorage,
    whitelist,
  };
  return persistConfig;
};

const reducer = combineReducers({
  user: persistReducer(getConfig("user", ["user"]), userReducer),
  myTeam: myTeamReducer,
  client: clientReducer,
  appoinment: appoinmentReducer,
  opportunity: opportunityReducer,
  proposal: proposalReducer,
});

// middleware that logs actions
const logger = createLogger({ predicate: () => true });

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});
export const persistor = persistStore(store);

export default store;
