import React from "react";
import { Button } from "react-bootstrap";
import errorIcon from "../../assets/Icon/error.png";
import recentViewIcon from "../../assets/Icon/recentView.png";
import ProfileImage1 from "../../assets/images/chat-profile1.jpg";
import ProfileImage2 from "../../assets/images/chat-profile2.jpg";
import ProfileImage3 from "../../assets/images/chat-profile3.jpg";
import "./Dashboard.scss";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import UpComingPaySection from "../../common/dashboard/UpComingPaySection";
import MsgSection from "../../common/dashboard/MsgSection";

const data = [
  {
    image: ProfileImage1,
    name: "John Kate",
    desg: "Project Manager",
    message: "Send you the File",
    pendingMess: "5",
  },
  {
    image: ProfileImage2,
    name: "Kavita.W",
    desg: "Client",
    message: "Send you the File",
    pendingMess: "15",
  },
  {
    image: ProfileImage3,
    name: "Project Name",
    desg: "#group",
    message: "Send you the File",
    pendingMess: "15",
  },
  {
    image: ProfileImage1,
    name: "John Kate",
    desg: "Project Manager",
    message: "Send you the File",
    pendingMess: "5",
  },
];

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

const chartData = {
  labels: labels,
  datasets: [
    {
      label: "2023",
      backgroundColor: "#7E97F2",
      borderColor: "rgb(255, 99, 132)",
      data: [0, 10, 5, 2, 20, 30, 45],
    },
  ],
};

const options = {
  plugins: {
    legend: false, // Hide legend
  },
  maintainAspectRatio: false,
};

const Dashboard = () => {
  return (
    <>
      <section className="dashboard-section">
        <div className="section-header">
          <h1 className="heading">Dashboard</h1>
          <div className="upgrad-plan d-flex">
            <div className="d-flex flex-column align-items-end d-lg-flex d-none">
              <p className="m-0">
                <img src={errorIcon} alt="" />
                You are using BARCCI free plan
              </p>
              <h4>10 Credit Left</h4>
            </div>
            <Button className="upgrad-btn">Upgrade Plan</Button>
          </div>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-xl-8 col-lg-7 left p-0">
              <div className="row">
                <div className="col-xl-8 p-0 pe-xl-4">
                  <UpComingPaySection />
                </div>
                <div className="col-xl-4 p-0 project">
                  <div className="row flex-xl-column flex-row h-100 justify-content-between">
                    <div className="box mt-xl-0 mt-4">
                      <div className="title">Project Running</div>
                      <div className="d-flex align-items-center gap-4">
                        <img src={recentViewIcon} alt="" />
                        <h2>203</h2>
                      </div>
                    </div>
                    <div className="box mt-xl-0 mt-4">
                      <div className="title">Project Complete</div>
                      <div className="d-flex align-items-center gap-4">
                        <img src={recentViewIcon} alt="" />
                        <h2>1000</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="monthly-lead-chart">
                  <div className="d-flex justify-content-between title">
                    <h2>Monthly generated leads</h2>
                    <h2>2023</h2>
                  </div>
                  <div className="dashboard-chart">
                    <Bar data={chartData} options={options} height={"100"} width={"100"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 ps-lg-4 p-0 mt-lg-0 mt-4">
              <MsgSection data={data} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
