import React from "react";
import "./MsgSection.scss";

const MsgSection = ({ data }) => {
  return (
    <div className="chat-box h-100">
      <div className="chat-box-header">
        <div className="title">
          New Messages
          <span>20</span>
        </div>
        <div className="see-all">See All</div>
      </div>
      <div className="other-messages">
        {data.map((item, i) => (
          <div key={i} className="person-details">
            <div className="d-flex align-items-center">
              <div className="profile-img me-4">
                <img src={item.image} alt="" />
                <div className="circle">
                  <div className="small-circle"></div>
                </div>
              </div>
              <div className="personal-details">
                <div className="name">{item.name}</div>
                <div className="designation">{item.desg}</div>
                <div className="meassage">{item.message}</div>
              </div>
            </div>
            <div className="pending-msg">{item.pendingMess}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MsgSection;
