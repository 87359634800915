import React from "react";
import { Table } from "react-bootstrap";
import { FILE_BASE_URL } from "../../config";
import rightArrow from "../../assets/Icon/right-arrow-icon.svg";
import "./TeamTable.scss"

const TeamTable = ({ members, goToTeamDetailPage }) => {
  return (
    <Table size="lg" responsive className="myteam-table">
      <thead>
        <tr>
          <th>Member ID</th>
          <th>Member Details</th>
          <th>Contact No.</th>
          <th>Email Id</th>
          <th>Experience</th>
          <th>KPI</th>
          <th>Assigned Project</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        {/* {data.map((item, i) => (
      <tr key={i}>
        <td>{item.id}</td>
        <td>
          <div className="client-details">
            <div className="client-avatar">
              <img src="https://picsum.photos/id/1/200/300" alt="" />
            </div>
            <div className="name">{item.name}</div>
          </div>
        </td>
        <td>{item.contact}</td>
        <td>{item.email}</td>
        <td>{item.exp}</td>
        <td>{item.kpi}</td>
        <td>{item.project}</td>
        <td>
          <img onClick={teamDetail} style={{ cursor: "pointer" }} src={rightArrow} alt="" />
        </td>
      </tr>
    ))} */}
        {members.length === 0 ? (
          <tr>
            <td colSpan={8} className="no-data-found">
              No data found
            </td>
          </tr>
        ) : (
          members.map((member, i) => {
            return (
              <tr key={i}>
                <td>{member.member.mid}</td>
                <td>
                  <div className="client-details">
                    <div className="client-avatar">
                      <img src={member.image ? FILE_BASE_URL + member.image : "https://picsum.photos/id/1/200/300"} alt="" />
                    </div>
                    <div className="name">{member.firstName + " " + member.lastName}</div>
                  </div>
                </td>
                <td>{member.phone}</td>
                <td>{member.email}</td>
                <td>{member.exp ? member.exp + " Yrs" : "0 Yrs"}</td>
                <td>{member.kpi ? member.kpi : "-"}</td>
                <td>{member.project ? member.project : 0}</td>
                <td>
                  <img onClick={() => goToTeamDetailPage(member)} style={{ cursor: "pointer" }} src={rightArrow} alt="" />
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
};

export default TeamTable;
