import React from 'react'
import { Modal } from 'react-bootstrap'
import Logo from "../../../assets/images/barcci-ai.png"
import ProjectIcon from "../../../assets/Icon/project-icon.svg"
import starIcon from "../../../assets/Icon/star.svg"
import calendarIcon from "../../../assets/Icon/calendar.svg"
import userIcon1 from "../../../assets/Icon/user.svg"
import userIcon2 from "../../../assets/Icon/user2.svg"
import paymentIcon from "../../../assets/Icon/coin-dollar.svg"
import "./AiPopup.scss"
import { useNavigate } from 'react-router-dom'
const AiPopup = (props) => {
    console.log(props)
    const navigate = useNavigate()
    const project = () => {
        navigate("/project-ai")
        props.onHide()
    }
    const opportunity = () => {
        navigate("/opportunity-ai")
        props.onHide()
    }
    const appointment = () => {
        navigate("/appointment-ai")
        props.onHide()
    }
    const myTeam = () => {
        navigate("/myteam-ai")
        props.onHide()
    }
    const client = () => {
        navigate("/client-ai")
        props.onHide()
    }
    const payment = () => {
        navigate("/payment-ai")
        props.onHide()
    }
    return (
        <Modal
            className="ai-popup-main p-0"
            {...props}
            size="lg"
            centered
        >
            <Modal.Header closeButton className='header-modal'>
                <Modal.Title id="contained-modal-title-vcenter">
                    Topic Option
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="logo">
                    <img src={Logo} alt="" />
                </div>
                <div className="ai-message">
                    Hey ! 🖐
                </div>
                <span className='question'>Want to Talk With BARCCI AI ?</span>
                <span className='topic'>Please Select Topic</span>
                <div className="topic-list">
                    <div className="list" onClick={project}>
                        <div className="icon">
                            <img src={ProjectIcon} alt="" />
                        </div>
                        <h4>Projects</h4>
                    </div>
                    <div className="list" onClick={opportunity}>
                        <div className="icon">
                            <img src={starIcon} alt="" />
                        </div>
                        <h4>Opportunity</h4>
                    </div>
                    <div className="list" onClick={appointment}>
                        <div className="icon">
                            <img src={calendarIcon} alt="" />
                        </div>
                        <h4>Appointment</h4>
                    </div>
                    <div className="list" onClick={myTeam}>
                        <div className="icon">
                            <img src={userIcon1} alt="" />
                        </div>
                        <h4>My Team</h4>
                    </div>
                    <div className="list" onClick={client}>
                        <div className="icon">
                            <img src={userIcon2} alt="" />
                        </div>
                        <h4>Client</h4>
                    </div>
                    <div className="list" onClick={payment}>
                        <div className="icon">
                            <img src={paymentIcon} alt="" />
                        </div>
                        <h4>Payment</h4>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AiPopup