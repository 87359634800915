import React from "react";
import { Progress } from "antd";
import { Table } from "react-bootstrap";
import rightArrow from "../../../assets/Icon/right-arrow-icon.svg";
import "./TeamDTable.scss";

const TeamDTable = () => {
  return (
    <Table size="lg" responsive className="team-detail-table">
      <thead>
        <tr>
          <th>Project Name</th>
          <th>Location / Area</th>
          <th>Completion</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>
            <Progress percent={30} />
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>
            <div className="completed">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
        <tr>
          <td>Antalia Tower</td>
          <td>Las Vegas</td>
          <td>
            <div className="completed">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path
                    d="M9.30563 0.679688L11.3451 5.77569H17.945L12.6056 8.92519L14.645 14.0212L9.30563 10.8717L3.96622 14.0212L6.00569 8.92519L0.66628 5.77569H7.26616L9.30563 0.679688Z"
                    fill="#2B4ED2"
                  />
                </svg>
              </span>
              Completed
            </div>
          </td>
          <td>
            <img src={rightArrow} alt="" />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TeamDTable;
