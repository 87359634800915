import React, { useState } from 'react'
import { Table, InputGroup, Form, Modal, Button } from 'react-bootstrap'
import { Pagination } from 'antd'
import invoiceIcon from "../../../assets/Icon/invoice.png"
import "./AdminPayment.scss"
import PaymentPopup from '../../../components/Modal/paymentPopup/PaymentPopup'

const AdminPayment = () => {
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section className="admin-payment-screen">
      <div className="list-table">
        <div className="table-header">
          <div className="heading">
            <h2>Transaction</h2>
            <h3>List of all your transaction</h3>
          </div>
          <div className="search-section d-lg-block d-none">
            <InputGroup>
              <Form.Control
                placeholder="Search"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text id="basic-addon2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className='total-received'>
            <div className="title">Total Received</div>
            <div className="amount">$8542.00</div>
          </div>
        </div>
        <Table size="lg" responsive>
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Agency Details</th>
              <th>Email</th>
              <th>Plan Details</th>
              <th>Amount</th>
              <th>Date/Time</th>
              <th>Invoice</th>
              <th>Status</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 10 }).map((item, i) => (
              <tr key={i}>
                <td>TID-2012362254</td>
                <td>
                  <div className="client-details">
                    <div className="client-avatar">
                      <img src="https://picsum.photos/id/1/200/300" alt="" />
                    </div>
                    <div className="name">Solo Estate Ltd</div>
                  </div>
                </td>
                <td>Solo@gmail.com</td>
                <td>Pro/ Monthly</td>
                <td>$ 398.52</td>
                <td>08 July,2023 01:57 PM</td>
                <td>#BR-001 <img className='invoice-icon' src={invoiceIcon} alt="" /></td>
                <td>Invoice Remining</td>
                <td>
                  <Button className='send-btn'>Send</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-main">
          <Pagination total={100} />
        </div>
      </div>
      <PaymentPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Modal
        show={show}
        onHide={handleClose}
        className="delete-modal"
        centered
      >
        <Modal.Body>
          <h2>Delete</h2>
          <div className="confirmation">Are you sure you want to delete this item ?</div>
          <div className="footer-btn d-flex">
            <Button className="cancel-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button>Delete</Button>
          </div>
        </Modal.Body>
      </Modal>
    </section >
  )
}

export default AdminPayment