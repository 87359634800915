import React from "react";
import LeftArrow from "../../../../assets/Icon/arrow-left-line.svg";
import { useLocation, useNavigate } from "react-router-dom";
// import * as Icon from "react-bootstrap-icons";
import "./AppointmentDetail.scss";
import AppoinmentChat from "../../../../common/appoinment/AppoinmentChat";
import AppoinmentDCard from "../../../../common/appoinment/AppoinmentDCard";
import { useSelector } from "react-redux";
import { routes } from "../../../../constants";

const AppointmentDetail = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = user && user.role;

  const navigate = useNavigate();
  const location = useLocation();

  const appDetail = location?.state?.apt;

  const goBack = () => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`${routes.agentAppointment}/page/1`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`${routes.staffAppointment}/page/1`);
    } else {
      navigate("/appointment/page/1");
    }
  };
  return (
    <div className="appointment-detail-screen">
      <div className="project-heading">
        <span onClick={goBack} style={{ cursor: "pointer" }}>
          <img src={LeftArrow} alt="" />
        </span>
        <h2>Back to list</h2>
      </div>
      <div className="row appointment-detail-content">
        <div className="col-lg-6">
          <AppoinmentDCard appDetail={appDetail} />
          {/* <h2 className="project-document">Project document Shared by Client</h2>
          <div className="document-section">
            <div className="document-card">
              <div className="img">
                <Icon.FiletypePdf />
              </div>
              <div className="name">Document Name</div>
              <div className="d-flex justify-content-between">
                <div className="date">18 July, 2023</div>
                <div className="time">14:29</div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-lg-6">
          <AppoinmentChat />
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetail;
