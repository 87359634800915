import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { DatePicker, Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { routes } from "../../../../constants";
// import { useDispatch, useSelector } from "react-redux";
// import moment from "moment/moment";

const BookAppoinment = () => {
  const navigate = useNavigate();

  const timeArr = [
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "08:00 PM",
    "09:00 PM",
  ];

  const goBackHandler = () => {
    navigate(`${routes.clientAppointment}/page/1`);
  };

  return (
    <>
      <section className="add-appointment-screen">
        <div className="add-appointment-header">
          <h1 className="heading">Book Appointment</h1>
        </div>
        <Form className="add-appointment-form">
          <h4>Client Information</h4>
          <div className="d-flex gap-md-4 flex-md-nowrap flex-wrap">
            <Form.Item className="w-50 mb-4" label="Your Name">
              <Input placeholder="Enter Your Name" />
            </Form.Item>
            <Form.Item className="w-50 mb-4" label="Contact Number">
              <Input placeholder="+01 00000 00000" />
            </Form.Item>
            <Form.Item className="w-50 mb-4" label="Email">
              <Input placeholder="abc@gmail.com" />
            </Form.Item>
          </div>
          <h4 className="mt-1">Select Date & time</h4>
          <div className="d-flex mb-4 gap-5 flex-md-nowrap flex-wrap">
            <div className="form-date-picker select-date-time w-md-50 w-100">
              <DatePicker placeholder="dd-mm-yyyy" disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))} format={"DD-MM-YYYY"} />
            </div>
            <div className="select-available-time w-md-50 w-100">
              <h2> Select Available times for July 2023</h2>
              <div className="d-flex flex-wrap gap-4">
                {timeArr.map((time, i) => (
                  <Button
                    key={i}
                    // className={`${values.time && values.time === time && "selected-time"}`}
                    // onClick={() => onChangeHandler(time, "time")}
                  >
                    {time}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <Form.Item className="mb-4" label="Subject*">
            <TextArea rows={4} placeholder="Write description" />
          </Form.Item>
          <div className="add-member-footer-btn d-flex w-50 ms-auto gap-4">
            <Button className="cancel-btn" onClick={() => goBackHandler()}>
              Cancel
            </Button>
            <Button
            // onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};

export default BookAppoinment;
