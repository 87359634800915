import React, { useEffect, useState } from "react";
import LoginImage from "../../../assets/images/login-image.png";
import BlackLogo from "../../../assets/images/black-logo.svg";
import VerifiedIcon from "../../../assets/Icon/verified.gif";
import "./EmailSuccess.scss";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyRequest } from "../../../redux/actions/AuthAction";
import { routes } from "../../../constants";
import { reqToVerifyEmail } from "../../../reduxToolkit/services/userServices";

const EmailSuccess = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigateToLogin = () => {
    navigate(routes.login);
  };

  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    dispatch(reqToVerifyEmail({ token: token, setIsVerified, navigate }));
  }, []);

  return (
    <section className="email-success-section">
      <div className="row w-100">
        <div className="col-xl-7 p-0">
          <div className="email-success-main d-flex justify-content-center">
            <div className="form-inner">
              <div className="logo">
                <img src={BlackLogo} alt="" />
              </div>
              <div className="content">
                {isVerified ? (
                  <div>
                    <img src={VerifiedIcon} alt="verified icon" />
                    <h1>Your Email has been Verified</h1>
                    <Button className="login-btn" onClick={() => navigateToLogin()}>
                      LOGIN
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Spinner className="mb-4" animation="border" variant="primary" />
                    <h1>Verifing your email...</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 d-xl-block d-none p-0">
          <div className="sign-up-image-section">
            <img src={LoginImage} alt="login icon" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmailSuccess;
