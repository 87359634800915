import React from "react";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

import LeftArrow from "../../../../assets/Icon/arrow-left-line.svg";
import AppoinmentDCard from "../../../../common/appoinment/AppoinmentDCard";
import AppoinmentChat from "../../../../common/appoinment/AppoinmentChat";

const ClientAppoinmentDetail = () => {
  const navigate = useNavigate();
  const appointment = () => {
    navigate("/client-appoinment/page/1");
  };
  return (
    <div className="appointment-detail-screen">
      <div className="project-heading">
        <span onClick={appointment} style={{ cursor: "pointer" }}>
          <img src={LeftArrow} alt="" />
        </span>
        <h2>Back to list</h2>
      </div>
      <div className="row appointment-detail-content">
        <div className="col-lg-6">
          <AppoinmentDCard />
          <h2 className="project-document">Project document Shared by You</h2>
          <div className="document-section">
            <div className="document-card">
              <div className="img">
                <Icon.FiletypePdf />
              </div>
              <div className="name">Document Name</div>
              <div className="d-flex justify-content-between">
                <div className="date">18 July, 2023</div>
                <div className="time">14:29</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <AppoinmentChat />
        </div>
      </div>
    </div>
  );
};

export default ClientAppoinmentDetail;
