import React from "react";
import { Navigate } from "react-router-dom";
import { routes } from "./constants";
import {
  agencyAdminRoutes,
  agentRoutes,
  authRoutes,
  clientRoutes,
  formanRoutes,
  otherRoutes,
  staffRoutes,
  superAdminRoutes,
} from "./constants/allRoutes";

// Common Layout Module //
import AuthLayout from "./components/AuthLayout/AuthLayout";
import Layout from "./components/Layout/Layout";
import AdminLayout from "./components/Admin/AdminLayout/AdminLayout";
//

// Page not found
import PageNotFound from "./screens/PageNotFound";
//

const getRoutes = (user) => {
  console.log("user ==>", user);
  return [
    {
      path: routes.homePage,
      element: !user ? (
        <AuthLayout />
      ) : user.role === "AGENCY_ADMIN" ||
        user.role === "CLIENT" ||
        user.role === "AGENCY_AGENT" ||
        user.role === "AGENCY_STAFF" ||
        user.role === "AGENCY_FOREMAN" ? (
        <Navigate to={routes.dashboard} />
      ) : (
        <Navigate to={routes.adminDashboard} />
      ),
      children: authRoutes,
    },
    {
      path: routes.homePage,
      element: user ? (
        user.role === "AGENCY_ADMIN" ||
        user.role === "CLIENT" ||
        user.role === "AGENCY_AGENT" ||
        user.role === "AGENCY_STAFF" ||
        user.role === "AGENCY_FOREMAN" ? (
          <Layout />
        ) : (
          <AdminLayout />
        )
      ) : (
        <Navigate to={routes.login} />
      ),
      children:
        user && user.role === "AGENCY_ADMIN"
          ? agencyAdminRoutes
          : user && user.role === "CLIENT"
          ? clientRoutes
          : user && user.role === "AGENCY_AGENT"
          ? agentRoutes
          : user && user.role === "AGENCY_STAFF"
          ? staffRoutes
          : user && user.role === "AGENCY_FOREMAN"
          ? formanRoutes
          : superAdminRoutes,
    },
    {
      path: "",
      element: !user ? <Navigate to={routes.login} /> : <PageNotFound />,
      children: otherRoutes,
    },
  ];
};

export default getRoutes;
