import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./AddTaskModal.scss"
import { DatePicker, Form, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const AddTaskModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      className="payment-modal-main"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Form>
          <Form.Item className="mb-4" label="Task">
            <TextArea rows={4} placeholder="Write task summary here" />
          </Form.Item>
          <Form.Item className="mb-4" label="Select Members for task (Assign Task)">
            <Select>
              <Select.Option value="demo">Select Member</Select.Option>
            </Select>
          </Form.Item>
          <div className="d-flex gap-4">
            <Form.Item className="w-50 mb-4" label="Start Date">
              <DatePicker placeholder="dd - mm - yyyy" />
            </Form.Item>
            <Form.Item className="w-50 mb-4" label="End Date">
              <DatePicker placeholder="dd - mm - yyyy" />
            </Form.Item>
          </div>
        </Form>
        <div className="footer-btn d-flex gap-4" >
          <Button onClick={props.onHide} className='cancel-btn'>Cancel</Button>
          <Button onClick={props.onHide}>Save</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddTaskModal