import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../helper/axios";
import { toastify } from "../../common/commonFunction";
import { apiendpoints, messages, routes } from "../../constants";

//req to add Proposal
export const reqToAddProposal = createAsyncThunk("reqToAddProposal", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.addProposal, data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.proposal.addProposal);
      data.navigate(data.route, { state: { mainTab: "proposal" } });
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

// req To Get Proposals
export const reqToGetProposals = createAsyncThunk("reqToGetProposals", async (data) => {
  try {
    const response = await Axios.get(
      `${apiendpoints.getProposals}?status=${data.status}&page=${data.page || 1}&limit=${data.limit || 10}&query=${data.query || ""}`,
      authHeaders()
    );
    if (response.status === 201 || response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});

//req to Update Proposal
export const reqToUpdateProposal = createAsyncThunk("reqToUpdateProposal", async (data) => {
  try {
    const response = await Axios.put(apiendpoints.updateProposal.replace(":propId", data.propId), data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.proposal.updateProposal);
      data.cb(response);
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

// req To Get Proposal Details
export const reqToGetProposalDetails = createAsyncThunk("reqToGetProposalDetails", async (propId) => {
  try {
    const response = await Axios.get(apiendpoints.getProposalDetail.replace(":propId", propId), authHeaders());
    if (response.status === 201 || response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});
