import React from "react";
import "./ProposalTab.scss";
import { Tabs, Tab } from "react-bootstrap";
import { Pagination } from "antd";
import ProposalTable from "../../../../../common/proposal/ProposalTable";
import { useNavigate } from "react-router-dom";

const ProposalTab = ({ proposals, totalProposalCount, onPageChange, onPropTabChange, propTabsStatus, page, userRole }) => {
  const navigate = useNavigate();

  const goToDetailPage = (propDetail) => {
    if (userRole === "AGENCY_AGENT") {
      navigate(`/agent-proposal/preview/${propDetail.propId}`);
    } else if (userRole === "AGENCY_STAFF") {
      navigate(`/staff-proposal/preview/${propDetail.propId}`);
    } else {
      navigate(`/proposal-preview/${propDetail.propId}`);
    }
  };

  const proposalsStatus = [
    { key: "", label: "All" },
    { key: "1", label: "Pending" },
    { key: "2", label: "Approved" },
    { key: "3", label: "Rejected" },
  ];

  return (
    <section className="proposal-table">
      <Tabs id="uncontrolled-tab-example" className="mb-3" onSelect={(e) => onPropTabChange(e)} activeKey={propTabsStatus}>
        {proposalsStatus.map((propStatus, i) => (
          <Tab eventKey={propStatus.key} title={propStatus.label} key={i}>
            <div className="list-table">
              <ProposalTable data={proposals} goToDetailPage={goToDetailPage} />
              <div className="pagination-main">
                <Pagination total={totalProposalCount} current={+page} onChange={onPageChange} />
              </div>
            </div>
          </Tab>
        ))}
      </Tabs>
    </section>
  );
};

export default ProposalTab;
