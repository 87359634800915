export const routes = {
  all: "*",
  homePage: "/",

  // AUTH MODULE ////
  login: "/login",
  signup: "/sign-up",
  forgetPassword: "/forget-password",
  verifyPassword: "/password/verified/:token",
  verifyEmail: "/email/verified/:token",
  checkEmail: "/email/check-email",
  singupForMember: "/signup/member/:token",
  singupForClient: "/signup/client/:token",

  //// COMMON DASHBOARD MODULE ////
  dashboard: "/dashboard",
  adminDashboard: "/admin",

  //// AGANCY_ADMIN MODULE ROUTES START////

  // project
  project: "/project",
  projectDetail: "/project-detail",
  // project-ai
  projectAi: "/project-ai",
  // opportunity
  opportunity: "/opportunity",
  opportunityByPage: "/opportunity/page/:page_number",
  addOpportunity: "/add-opportunity",
  opportunityDetail: "/opportunity-detail/:opportunity_id",
  // opportunity-ai
  opportunityAi: "/opportunity-ai",
  // appointment
  appointment: "/appointment",
  appointmentByPage: "/appointment/page/:page_number",
  addAppointment: "/add-appointment",
  appointmentDetail: "/appointment-detail/:appointment_id",
  // appointment-ai
  appointmentAi: "/appointment-ai",
  // team
  myTeam: "/myteam",
  myTeamByPage: "/myteam/page/:page_number",
  myTeamDetail: "/myteam/team-detail",
  teamTable: "/myteam/team-table",
  memberActivities: "/myteam/member-activities",
  assignProject: "/assign-project",
  // myteam-ai
  myteamAi: "/myteam-ai",
  // client
  client: "/client",
  clientByPage: "/client/page/:page_number",
  clientDetail: "/client/detail/:client_id",
  // client-ai
  clientAi: "/client-ai",
  // payment
  payment: "/payment",
  duePayment: "/due-payment",
  // payment-ai
  paymentAi: "/payment-ai",
  // message
  message: "/message",
  // proposal
  createProposal: "/create-proposal",
  proposalPreview: "/proposal-preview/:proposal_id",

  //// AGANCY_ADMIN MODULE ROUTES END ////

  //// CLIENT MODULE START ////
  // appoinment
  clientAppointment: "/client-appoinment",
  clientAppointmentByPage: "/client-appoinment/page/:page_number",
  clientAppointmentDetail: "/client-appoinment/appoinment",
  clientbookAppointment: "/client-appoinment/book-appoinment",
  // proposal
  clientProposal: "/client-proposal",
  clientProposalByPage: "/client-proposal/page/:page_number",
  clientProposalDetails: "/client-proposal/details",
  //project
  clientProject: "/client-project",
  clientProjectByPage: "/client-project/page/:page_number",
  clientProjectDetail: "/client-project/details",
  //payment
  clientPayment: "/client-payment",
  clientAddMoney: "/client-payment/add-money",
  //messages
  clientMessages: "/client-messages",
  // report
  clientReport: "/client-reports",

  //// CLIENT MODULE END ////

  //// AGENT MODULE (AGENCY MANAGER) START////
  //project
  agentProject: "/agent-project",
  agentProjectByPage: "/agent-project/page/:page_number",
  agentProjectDetail: "/agent-project/detail/:project_id",
  addAgentProject: "/agent-project/add",
  // Opportunity
  agentOpportunity: "/agent-opportunity",
  agentOpportunityByPage: "/agent-opportunity/page/:page_number",
  addAgentOpportunity: "/agent-opportunity/add",
  agentOpportunityDetail: "/agent-opportunity/detail/:opportunity_id",
  // proposal
  createAgentProposal: "/agent-proposal/add",
  agentProposalPreview: "/agent-proposal/preview/:proposal_id",
  // appoinment
  agentAppointment: "/agent-appointment",
  agentAppointmentByPage: "/agent-appointment/page/:page_number",
  agentAppointmentDetail: "/agent-appointment/appointment/:appointment_id",
  agentAddAppointment: "/agent-appointment/set-appointment",
  // team
  agentMyTeam: "/agent-myteam",
  agentMyTeamByPage: "/agent-myteam/page/:page_number",
  agentMyTeamDetail: "/agent-myteam/detail/:member_id",
  // client
  agentClient: "/agent-client",
  agentClientByPage: "/agent-client/page/:page_number",
  addAgentClient: "/agent-client/add",
  agentClientDetail: "/agent-client/detail/:client_id",
  // message
  agentMessage: "/agent-message",
  // report
  agentReport: "/agent-report",
  //// AGENT MODULE (AGENCY MANAGER) END////

  //// OFFICE STAFF (AGENCY_STAFF) START ////

  //project
  staffProject: "/staff-project",
  staffProjectByPage: "/staff-project/page/:page_number",
  staffProjectDetail: "/staff-project/detail/:project_id",
  staffAddProject: "/staff-project/add",
  // Opportunity
  staffOpportunity: "/staff-opportunity",
  staffOpportunityByPage: "/staff-opportunity/page/:page_number",
  staffAddOpportunity: "/staff-opportunity/add",
  staffOpportunityDetail: "/staff-opportunity/detail/:opportunity_id",
  // proposal
  staffAddProposal: "/staff-proposal/add",
  staffProposalPreview: "/staff-proposal/preview/:proposal_id",
  // appoinment
  staffAppointment: "/staff-appointment",
  staffAppointmentByPage: "/staff-appointment/page/:page_number",
  staffAppointmentDetail: "/staff-appointment/appointment/:appointment_id",
  staffSetAppointment: "/staff-appointment/set-appointment",
  // team
  staffMyTeam: "/staff-myteam",
  staffMyTeamByPage: "/staff-myteam/page/:page_number",
  staffAddNewAgent: "/staff-myteam/add-agent",
  staffMyTeamDetail: "/staff-myteam/detail/:member_id",
  staffMyTeamAssign: "/staff-myteam/detail/:member_id/assign-project",
  // client
  staffClient: "/staff-client",
  staffClientByPage: "/staff-client/page/:page_number",
  staffAddClient: "/staff-client/add",
  staffClientDetail: "/staff-client/detail/:client_id",
  // messages
  staffMessage: "/staff-message",
  // report
  staffReport: "/staff-report",
  //// OFFICE STAFF (AGENCY_STAFF) END ////

  //// FORMAN MODULE START ////
  //project
  formanProject: "/forman-project",
  formanProjectByPage: "/forman-project/page/:page_number",
  formanProjectDetail: "/forman-project/detail/:project_id",
  //team
  formanMyTeam: "/forman-myteam",
  formanMyTeamByPage: "/forman-myteam/page/:page_number",
  formanMyTeamDetail: "/forman-myteam/detail/:member_id",
  // messages
  formanMessage: "/forman-message",
  // report
  formanReport: "/forman-report",
  //// FORMAN MODULE END ////

  //// SUPER_ADMIN MODULE START ////
  //agency
  agency: "/admin/agency",
  agencyDetail: "/admin/agency-detail",
  //Plan
  plan: "/admin/plan",
  planDetail: "/admin/plan-detail",
  createPlan: "/admin/create-plan",
  adminPayment: "/admin/payment",
  help: "/admin/help",
  //// SUPER_ADMIN MODULE END ////
};

export const apiendpoints = {
  // Auth APIs

  login: "/auth/login",
  signup: "/auth/signup",
  forgetPassword: "/auth/forgot-password",
  verifyForgetPassword: "/auth/password/verify/",
  verifyEmail: "/auth/email/verify/",
  getUser: "/user",
  changePassword: "/auth/change-password",

  // My Teams APIs
  addNewMember: "/member/invitation/send",
  verifyInvitationToken: "/member/invitation/verify/",
  signupMember: "/member/add",
  getAllMember: "/member",

  // Client APIs
  addClient: "/client/invitation/send",
  verifyInvitationClientToken: "/client/invitation/verify/",
  singupClient: "/client/add",
  getAllClient: "/client",

  // Appoinment
  createAppoinment: "/appointment",
  getAppoinments: "/appointment",

  // Opportunity
  addOpportunity: "/opportunity",
  getOpportunities: "/opportunity",
  updateOpportunity: "/opportunity/:oprId",
  getOpportunityDetail: "/opportunity/:oprId",

  //Opportunity Reminder
  addReminder: "/opportunity/:oprId/reminder",
  updateReminder: "/opportunity/:oprId/reminder/:rid",
  deleteReminder: "/opportunity/:oprId/reminder/:rid",

  //Opportunity Note
  addNote: "/opportunity/:oprId/note",
  updateNote: "/opportunity/:oprId/note/:nid",
  deleteNote: "/opportunity/:oprId/note/:nid",


  // proposal
  addProposal: "/proposal",
  getProposals: "/proposal",
  updateProposal: "/proposal/:propId",
  getProposalDetail: "/proposal/:propId",
};

export const messages = {
  toast: {
    auth: {
      logout: "Logout Successfully!",
      login: "Welcome Back!",
      register: "User registered successfully! Please check your email!",
      verifyEmail: "Email has been verified!",
      reqToResetPassword: "Please check your email to reset Password!",
      passwordChanged: "Password has been changed!",
    },
    client: {
      addClient: "Add Client request has been send to client's Email!",
      signUpClient: "Details has been submitted!",
    },
    myTeam: {
      addMember: "Add Member request has been send to member's Email!",
      signUpMember: "Details has been submitted!",
    },
    appointment: {
      createAppoinment: "Appoinment has been created!",
    },
    opportunity: {
      addOpportunity: "Opportunity has been added!",
      updateOpportunity: "Opportunity has been updated!",
      addReminder: "Reminder has been added!",
      updateReminder: "Reminder has been updated!",
      deleteReminder: "Reminder is deleted!",
      addNote: "Note has been added!",
      updateNote: "Note has been updated!",
      deleteNote: "Note is deleted!",
    },
    proposal: {
      addProposal: "Proposal has been added!",
      updateProposal: "Proposal has been updated!",
    },
  },
};
