import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./AddMember.scss";
import { Form, Input, Select } from "antd";

const AddMember = (props) => {
  const { onSubmitHandler, form } = props;

  return (
    <Modal {...props} size="md" className="add-member-modal" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <Form form={form} onFinish={onSubmitHandler}>
          <Form.Item
            className="mb-4"
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Email address is required!",
              },
            ]}
          >
            <Input placeholder="abc@gmail.com" />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Select Role"
            name="role"
            rules={[
              {
                required: true,
                message: "Please select a role!",
              },
            ]}
          >
            <Select placeholder="Select Role">
              <Select.Option value="" disabled>
                Select Role
              </Select.Option>
              <Select.Option value="AGENCY_AGENT">Manager</Select.Option>
              <Select.Option value="AGENCY_STAFF">Office staff</Select.Option>
              <Select.Option value="AGENCY_FOREMAN">Foreman</Select.Option>
            </Select>
          </Form.Item>
          <div className="footer-btn d-flex gap-4">
            <Button onClick={props.onHide} className="cancel-btn">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Invite
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddMember;
