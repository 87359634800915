import moment from "moment";
import React from "react";

const ProposalDHead = ({ proposalDetail }) => {
  const getStatus = (statusId) => {
    let status = "";
    if (statusId === 1) {
      status = "Pending";
    } else if (statusId === 2) {
      status = "Approved";
    } else if (statusId === 3) {
      status = "Rejected";
    }

    return status;
  };

  return (
    <div className="d-flex justify-content-between flex-sm-nowrap flex-wrap">
      <div className="left w-sm-50 w-100">
        <h4>[Your Company Name]</h4>
        <h4>[Your Address]</h4>
        <h4>[City, State, Zip Code]</h4>
        <h4>{moment.utc(proposalDetail.createdAt).format("D MMM, YYYY")}</h4>
        <h4 className="mt-sm-5 mt-4">[Client's Company Name]</h4>
        <h4>[Client's Address]</h4>
        <h4>[City, State, Zip Code]</h4>
      </div>
      <div className="right w-sm-50 w-100 text-sm-end text-start mt-sm-0 mt-4">
        {proposalDetail.propId && (
          <h4>
            Proposal No : <span>{proposalDetail.propId} </span>
          </h4>
        )}
        {proposalDetail.status && (
          <h4>
            Approval Status : <span>{getStatus(proposalDetail.status)}</span>
          </h4>
        )}
      </div>
    </div>
  );
};

export default ProposalDHead;
