export const durationData = [
  { value: "1|week", label: "1 Week" },
  { value: "2|week", label: "2 Week" },
  { value: "3|week", label: "3 Week" },
  { value: "4|week", label: "4 Week" },
  { value: "1|month", label: "1 Month" },
  { value: "2|month", label: "2 Months" },
  { value: "3|month", label: "3 Months" },
  { value: "4|month", label: "4 Months" },
  { value: "5|month", label: "5 Months" },
  { value: "6|month", label: "6 Months" },
  { value: "1|year", label: "1 Year" },
  { value: "2|year", label: "2 Year" },
  { value: "3|year", label: "3 Year" },
];
