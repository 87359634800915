import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./ProfileModal.scss";
import { Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../reduxToolkit/services/userServices";

const PasswordModal = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    dispatch(changePassword({ data }));
  };

  return (
    <Modal show={show} onHide={onHide} size="md" className="profile-modal" centered>
      <Modal.Body>
        <h1>Change Password</h1>
        <Form className="add-opportunity-form" onFinish={onFinish} form={form}>
          <Form.Item
            className="mb-4"
            label="Enter Old Password"
            name="oldPassword"
            rules={[{ required: true, message: "Please input your old password!" }]}
          >
            <Input placeholder="abc@gmail.com" />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Enter New Password"
            name="newPassword"
            rules={[{ required: true, message: "Please input your new password!" }]}
          >
            <Input type="password" placeholder="abc@gmail.com" />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords that you entered do not match!"));
                },
              }),
            ]}
          >
            <Input type="password" placeholder="+01 12345 56123" />
          </Form.Item>
          <div className="add-member-footer-btn d-flex justify-content-center gap-4">
            <Button className="cancel-btn" onClick={onHide}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save Change
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordModal;
