import axios from "axios";
import { API_URL } from "../../config";
import { useSelector } from "react-redux";

export const authHeaders = () => {
  const token = sessionStorage.getItem("persist:user");
  const parsedData = JSON.parse(token);
  const userToken = parsedData && parsedData.user ? JSON.parse(parsedData.user).token : null;

  return {
    headers: {
      Authorization: `Bearer ${ userToken }`,
    },
  };
};

const Axios = axios.create({
  baseURL: `${ API_URL }`,
});

export default Axios;
