import { routes } from ".";
import { Navigate } from "react-router-dom";

//// Auth Module Start ////
import SignUp from "../screens/auth/signup/SignUp";
import Login from "../screens/auth/login/Login";
import EmailSuccess from "../screens/auth/email/EmailSuccess";
import ForgetPassword from "../screens/auth/forgetPassword/ForgetPassword";
import CheckEmail from "../screens/auth/email/CheckEmail";
import VerifyPassword from "../screens/auth/forgetPassword/VerifyPassword";
import AddNewMember from "../screens/agencyAdmin/myTeam/components/AddNewMember";
import AddClient from "../screens/agencyAdmin/client/components/AddClient";
//// Auth Module End ////

import Dashboard from "../screens/dashboard/Dashboard";

//// Agency Admin Module Start ////
//Message
import MessageScreen from "../screens/agencyAdmin/message/MessageScreen";
//Project
import ProjectScreen from "../screens/agencyAdmin/project/ProjectScreen";
import ProjectDetail from "../screens/agencyAdmin/project/components/ProjectDetail";
import AddProject from "../screens/agencyAdmin/project/components/AddProject";

//Opportunity
import OpportunityScreen from "../screens/agencyAdmin/opportunity/OpportunityScreen";
import AddOpportunity from "../screens/agencyAdmin/opportunity/components/AddOpportunity";
import OpportunityDetail from "../screens/agencyAdmin/opportunity/components/OpportunityDetail";
//Proposal
import CreateProposal from "../screens/agencyAdmin/opportunity/components/Proposal/CreateProposal";
import ProposalPreview from "../screens/agencyAdmin/opportunity/components/Proposal/ProposalPreview";
//Appointment
import AppointmentScreen from "../screens/agencyAdmin/appointment/AppointmentScreen";
import AddAppointment from "../screens/agencyAdmin/appointment/components/AddAppointment";
import AppointmentDetail from "../screens/agencyAdmin/appointment/components/AppointmentDetail";
//MyTeam
import MyTeamScreen from "../screens/agencyAdmin/myTeam/MyTeamScreen";
import TeamDetail from "../screens/agencyAdmin/myTeam/components/TeamDetail";
import AssignProject from "../screens/agencyAdmin/myTeam/components/AssignProject";
import Activities from "../screens/agencyAdmin/myTeam/components/Activities";
import TeamTable from "../screens/agencyAdmin/myTeam/MyTeamScreen";
//Client
import ClientScreen from "../screens/agencyAdmin/client/ClientScreen";
import ClientDetail from "../screens/agencyAdmin/client/components/ClientDetail";
//Payment
import PaymentScreen from "../screens/agencyAdmin/payment/PaymentScreen";
import DuePayments from "../screens/agencyAdmin/payment/components/DuePayments";
//report
import ReportScreen from "../screens/agencyAdmin/report/ReportScreen";

//// Agency Admin Module End ////

//// Client Start ////
//Appoinment
import ClientAppoinment from "../screens/client/appoinment/ClientAppoinment";
import ClientAppoinmentDetail from "../screens/client/appoinment/components/ClientAppoinmentDetail";
import BookAppoinment from "../screens/client/appoinment/components/BookAppoinment";
//Proposal
import ClientProposal from "../screens/client/proposal/ClientProposal";
import ClientProposalDetails from "../screens/client/proposal/components/ClientProposalDetails";
//Project
import ClientProject from "../screens/client/projectStatus/ClientProject";
import ClientProjectDetails from "../screens/client/projectStatus/components/ClientProjectDetails";
//Payment
import ClientPayment from "../screens/client/payment/ClientPayment";
import AddMoney from "../screens/client/payment/components/AddMoney";
//Messages
import ClientMessages from "../screens/client/messages/ClientMessages";
//Report
import ClientReport from "../screens/client/report/ClientReport";
//// Client end ////

//// Super Admin Module Start ////
import AdminDashboard from "../screens/admin/adminDashboard/AdminDashboard";
//Agency
import Agency from "../screens/admin/agency/Agency";
import AgencyDetail from "../screens/admin/agency/AgencyDetail";
//Plan
import Plan from "../screens/admin/plan/Plan";
import PlanDetail from "../screens/admin/plan/PlanDetail";
import CreatePlan from "../screens/admin/plan/CreatePlan";
//Payment
import AdminPayment from "../screens/admin/payment/AdminPayment";
//Help
import Help from "../screens/admin/help/Help";
//// Super Admin Module End ////

//// AI screen ////
import ProjectScreenAi from "../screens/ai/projectAi/ProjectScreenAi";
import OpportunityScreenAi from "../screens/ai/opportunityAi/OpportunityScreenAi";
import AppointmentScreenAi from "../screens/ai/appointmentAi/AppointmentScreenAi";
import ClientScreenAi from "../screens/ai/clientAi/ClientScreenAi";
import MyTeamAiScreen from "../screens/ai/myTeamAi/MyTeamAiScreen";
import PaymentScreenAi from "../screens/ai/paymentAi/PaymentScreenAI";

////

// AUTH ROUTES
export const authRoutes = [
  { path: routes.homePage, element: <Navigate to={routes.login} /> },
  { path: routes.login, element: <Login /> },
  { path: routes.signup, element: <SignUp /> },
  { path: routes.forgetPassword, element: <ForgetPassword /> },
  { path: routes.verifyPassword, element: <VerifyPassword /> },
  { path: routes.verifyEmail, element: <EmailSuccess /> },
  { path: routes.checkEmail, element: <CheckEmail /> },
  { path: routes.singupForMember, element: <AddNewMember /> },
  { path: routes.singupForClient, element: <AddClient /> },
];

// AGENCY ADMIN ROUTES
export const agencyAdminRoutes = [
  //dashboard
  { path: routes.dashboard, element: <Dashboard /> },

  //message
  { path: routes.message, element: <MessageScreen /> },

  // Project
  { path: routes.project, element: <ProjectScreen /> },
  { path: routes.projectDetail, element: <ProjectDetail /> },
  { path: routes.assignProject, element: <AssignProject /> },

  // Opportunity
  { path: routes.opportunity, element: <OpportunityScreen /> },
  { path: routes.opportunityByPage, element: <OpportunityScreen /> },
  { path: routes.addOpportunity, element: <AddOpportunity /> },
  { path: routes.opportunityDetail, element: <OpportunityDetail /> },

  // proposal
  { path: routes.createProposal, element: <CreateProposal /> },
  { path: routes.proposalPreview, element: <ProposalPreview /> },

  // Appointment
  { path: routes.appointment, element: <AppointmentScreen /> },
  { path: routes.appointmentByPage, element: <AppointmentScreen /> },
  { path: routes.addAppointment, element: <AddAppointment /> },
  { path: routes.appointmentDetail, element: <AppointmentDetail /> },

  // team
  { path: routes.myTeam, element: <MyTeamScreen /> },
  { path: routes.myTeamByPage, element: <MyTeamScreen /> },
  { path: routes.myTeamDetail, element: <TeamDetail /> },
  { path: routes.memberActivities, element: <Activities /> },
  { path: routes.teamTable, element: <TeamTable /> },

  // Client
  { path: routes.client, element: <ClientScreen /> },
  { path: routes.clientByPage, element: <ClientScreen /> },
  { path: routes.clientDetail, element: <ClientDetail /> },

  // Payment
  { path: routes.payment, element: <PaymentScreen /> },
  { path: routes.duePayment, element: <DuePayments /> },
  { path: routes.duePayment, element: <DuePayments /> },

  // AI
  { path: routes.projectAi, element: <ProjectScreenAi /> },
  { path: routes.opportunityAi, element: <OpportunityScreenAi /> },
  { path: routes.appointmentAi, element: <AppointmentScreenAi /> },
  { path: routes.myteamAi, element: <MyTeamAiScreen /> },
  { path: routes.clientAi, element: <ClientScreenAi /> },
  { path: routes.paymentAi, element: <PaymentScreenAi /> },
];

// CLIENT ROUTES
export const clientRoutes = [
  // appoinment
  { path: routes.dashboard, element: <Dashboard /> },
  { path: routes.clientAppointment, element: <ClientAppoinment /> },
  { path: routes.clientAppointmentByPage, element: <ClientAppoinment /> },
  { path: routes.clientAppointmentDetail, element: <ClientAppoinmentDetail /> },
  { path: routes.clientbookAppointment, element: <BookAppoinment /> },
  // Proposal
  { path: routes.clientProposal, element: <ClientProposal /> },
  { path: routes.clientProposalByPage, element: <ClientProposal /> },
  { path: routes.clientProposalDetails, element: <ClientProposalDetails /> },
  // Project
  { path: routes.clientProject, element: <ClientProject /> },
  { path: routes.clientProjectByPage, element: <ClientProject /> },
  { path: routes.clientProjectDetail, element: <ClientProjectDetails /> },
  // Payment
  { path: routes.clientPayment, element: <ClientPayment /> },
  { path: routes.clientAddMoney, element: <AddMoney /> },
  // messages
  { path: routes.clientMessages, element: <ClientMessages /> },
  //reports
  { path: routes.clientReport, element: <ClientReport /> },
];

// AGENT ROUTES
export const agentRoutes = [
  { path: routes.dashboard, element: <Dashboard /> },
  //project
  { path: routes.agentProject, element: <ProjectScreen /> },
  { path: routes.agentProjectByPage, element: <ProjectScreen /> },
  { path: routes.agentProjectDetail, element: <ProjectDetail /> },
  { path: routes.addAgentProject, element: <AddProject /> },
  // Opportunity
  { path: routes.agentOpportunity, element: <OpportunityScreen /> },
  { path: routes.agentOpportunityByPage, element: <OpportunityScreen /> },
  { path: routes.agentOpportunityDetail, element: <OpportunityDetail /> },
  { path: routes.addAgentOpportunity, element: <AddOpportunity /> },
  // Proposal
  { path: routes.createAgentProposal, element: <CreateProposal /> },
  { path: routes.agentProposalPreview, element: <ProposalPreview /> },
  // appointment
  { path: routes.agentAppointment, element: <AppointmentScreen /> },
  { path: routes.agentAppointmentByPage, element: <AppointmentScreen /> },
  { path: routes.agentAppointmentDetail, element: <AppointmentDetail /> },
  { path: routes.agentAddAppointment, element: <AddAppointment /> },
  // myTeam
  { path: routes.agentMyTeam, element: <MyTeamScreen /> },
  { path: routes.agentMyTeamByPage, element: <MyTeamScreen /> },
  { path: routes.agentMyTeamDetail, element: <TeamDetail /> },
  // client
  { path: routes.agentClient, element: <ClientScreen /> },
  { path: routes.agentClientByPage, element: <ClientScreen /> },
  { path: routes.agentClientDetail, element: <ClientDetail /> },
  // messages
  { path: routes.agentMessage, element: <MessageScreen /> },
  //reports
  { path: routes.agentReport, element: <ReportScreen /> },
];
//

// AGENCY STAFF ROUTES
export const staffRoutes = [
  { path: routes.dashboard, element: <Dashboard /> },
  //project
  { path: routes.staffProject, element: <ProjectScreen /> },
  { path: routes.staffProjectByPage, element: <ProjectScreen /> },
  { path: routes.staffProjectDetail, element: <ProjectDetail /> },
  { path: routes.staffAddProject, element: <AddProject /> },
  // opportunity
  { path: routes.staffOpportunity, element: <OpportunityScreen /> },
  { path: routes.staffOpportunityByPage, element: <OpportunityScreen /> },
  { path: routes.staffAddOpportunity, element: <AddOpportunity /> },
  { path: routes.staffOpportunityDetail, element: <OpportunityDetail /> },
  // proposal
  { path: routes.staffAddProposal, element: <CreateProposal /> },
  { path: routes.staffProposalPreview, element: <ProposalPreview /> },
  // appointment
  { path: routes.staffAppointment, element: <AppointmentScreen /> },
  { path: routes.staffAppointmentByPage, element: <AppointmentScreen /> },
  { path: routes.staffAppointmentDetail, element: <AppointmentDetail /> },
  { path: routes.staffSetAppointment, element: <AddAppointment /> },
  //team
  { path: routes.staffMyTeam, element: <MyTeamScreen /> },
  { path: routes.staffMyTeamByPage, element: <MyTeamScreen /> },
  { path: routes.staffMyTeamDetail, element: <TeamDetail /> },
  { path: routes.staffMyTeamAssign, element: <AssignProject /> },
  //client
  { path: routes.staffClient, element: <ClientScreen /> },
  { path: routes.staffClientByPage, element: <ClientScreen /> },
  { path: routes.staffClientDetail, element: <ClientDetail /> },
  //message
  { path: routes.staffMessage, element: <MessageScreen /> },
  // report
  { path: routes.staffReport, element: <ReportScreen /> },
];
//

// AGENCY FORMAN ROUTES
export const formanRoutes = [
  { path: routes.dashboard, element: <Dashboard /> },
  //project
  { path: routes.formanProject, element: <ProjectScreen /> },
  { path: routes.formanProjectByPage, element: <ProjectScreen /> },
  { path: routes.formanProjectDetail, element: <ProjectDetail /> },
  //team
  { path: routes.formanMyTeam, element: <MyTeamScreen /> },
  { path: routes.formanMyTeamByPage, element: <MyTeamScreen /> },
  { path: routes.formanMyTeamDetail, element: <TeamDetail /> },
  //messages
  { path: routes.formanMessage, element: <MessageScreen /> },
  //report
  { path: routes.formanReport, element: <ReportScreen /> },
];

//

// SUPER ADMIN ROUTES
export const superAdminRoutes = [
  //adminDashboard
  { path: routes.adminDashboard, element: <AdminDashboard /> },
  //agency
  { path: routes.agency, element: <Agency /> },
  { path: routes.agencyDetail, element: <AgencyDetail /> },
  //plan
  { path: routes.plan, element: <Plan /> },
  { path: routes.planDetail, element: <PlanDetail /> },
  { path: routes.createPlan, element: <CreatePlan /> },
  //Payment
  { path: routes.adminPayment, element: <AdminPayment /> },
  //help
  { path: routes.help, element: <Help /> },
];

// OTHER PAGE 404 ROUTES
export const otherRoutes = [
  { path: routes.login, element: <Login /> },
  { path: "*", element: <Login /> },
];
