import React from "react";
import LogoutIcon from "../../assets/Icon/logout-icon.svg";
import Logo from "../../assets/images/logo.svg";
import Header from "../Header/Header";
import "./Layout.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { reqToUserLogout } from "../../reduxToolkit/slice/userSlice";
import { agancyHeadMenu, agentMenu, clientMenu, officeFormanMenu, officeStaffMenu } from "../../constants/menuList";

const Layout = () => {
  const user = useSelector((state) => state.user.user);

  const menu =
    user && user.role === "AGENCY_ADMIN"
      ? agancyHeadMenu
      : user && user.role === "CLIENT"
      ? clientMenu
      : user && user.role === "AGENCY_AGENT"
      ? agentMenu
      : user && user.role === "AGENCY_STAFF"
      ? officeStaffMenu
      : user && user.role === "AGENCY_FOREMAN"
      ? officeFormanMenu
      : [];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(reqToUserLogout());
  };

  return (
    <section className="layout row m-0">
      <div className="side-bar-menu-main d-lg-block d-none col-xl-2 col-lg-3 px-0">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="sidebar-slider">
          <div className="menu-main">
            {menu.map((item, i) => (
              <div className="list-main" key={i}>
                <Button disabled={item.disabled} className="list" onClick={() => navigate(item.url)}>
                  <img className="list-icon" src={item.icon} alt="" />
                  <span className="list-item-name">{item.label}</span>
                </Button>
              </div>
            ))}
          </div>
          <div className="logout-main">
            <div className="logout-list" onClick={() => handleLogout()}>
              <img src={LogoutIcon} alt="" className="logout-icon" />
              <span className="logout-list-name">Logout</span>
            </div>
          </div>
        </div>
      </div>
      <div className="body-section col-xl-10 col-lg-9 px-0">
        <header className="header">
          <Header menu={menu} />
        </header>
        <div className="body-container">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default Layout;
