import React from "react";
import { Button, Table } from "react-bootstrap";
import "./DuePaymentTable.scss";

const DuePaymentTable = ({ data }) => {
  return (
    <Table size="lg" responsive className="due-payment-table">
      <thead>
        <tr>
          <th>Due date</th>
          <th>Project Name</th>
          <th>Due Amount</th>
          <th style={{ textAlign: "center" }}>#</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => (
          <tr key={i}>
            <td>{item.date}</td>
            <td>{item.pname}</td>
            <td>{item.amt}</td>
            <td>
              <Button className="remind-btn">Remind</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DuePaymentTable;
