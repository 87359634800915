import React from "react";
import { Button, Dropdown } from "react-bootstrap";

import "./ClientDCard.scss";
import { FILE_BASE_URL } from "../../../config";
import profileImg from "../../../assets/images/profile.jpg";
import * as Icon from "react-bootstrap-icons";

const ClientDCard = ({ clientD }) => {
  return (
    <div className="client-detail-card">
      <div className="d-flex profile-container flex-xl-row flex-lg-column flex-sm-nowrap flex-wrap">
        <div className="image m-xl-0 mx-lg-auto m-sm-0 mx-auto">
          <img src={clientD.image ? FILE_BASE_URL + clientD.image : profileImg} alt="" />
        </div>
        <div className="detail">
          <Button>{clientD.client.cid}</Button>
          <h2>{clientD.firstName + " " + clientD.lastName}</h2>
          <h4>{clientD.phone}</h4>
          <h4>{clientD.email}</h4>
        </div>
        <Dropdown className="more-dropdown" align="end">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <Icon.ThreeDots />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Edit Profile</Dropdown.Item>
            <Dropdown.Item>Block</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex profile-footer flex-xl-nowrap flex-wrap">
        <div className="status w-xl-25 w-lg-100 w-sm-25 w-100">
          <h5>Status</h5>
          <h4>Active</h4>
        </div>
        <div className="satisfaction w-xl-75 w-lg-100 w-sm-75 w-100">
          <h5>Customer Satisfaction Score</h5>
          <h4>Neutral</h4>
        </div>
      </div>
    </div>
  );
};

export default ClientDCard;
