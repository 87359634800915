import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./ReminderModal.scss"
import { DatePicker, Form, TimePicker } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import moment from "moment";
import dayjs from 'dayjs';
// import dayjsPluginUTC from 'dayjs-plugin-utc'
// const utc = require('dayjs/plugin/utc');

const ReminderModal = (props) => {
  const { onSubmitHandler, reminder } = props;
  // dayjs.extend(dayjsPluginUTC, { parseToLocal: true })

  const initialValues = {
    date: reminder.dateTime ? dayjs(reminder.dateTime) : '',
    time: reminder.dateTime ? moment(reminder.dateTime) : '',
    note: reminder.note ? reminder.note : '',
  };

  return (
    <Modal
      show= {props.show}
      onHide= {props.onHide}
      size="md"
      className="reminder-modal p-0"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h1>Reminder</h1>
        <Form className="add-opportunity-form" onFinish={onSubmitHandler} initialValues={initialValues}>
          <Form.Item
            className="form-date-picker select-date-time"
            name="date"
            label="Select Date"
            rules={[
              {
                required: true,
                message: "Please select a date!",
              },
            ]}
          >
            <DatePicker placeholder="dd-mm-yyyy" format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            className="form-date-picker select-date-time"
            name="time"
            label="Select Time"
            rules={[
              {
                required: true,
                message: "Please select a time!",
              },
            ]}
          >
            <TimePicker format={"h:mm A"} minuteStep={15} />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Description*"
            name="note"
            rules={[
              {
                required: true,
                message: "Description is required!",
                transform: (value) => {
                  if (value) {
                    return value.trim();
                  }
                  return value;
                },
              },
            ]}
          >
            <TextArea rows={4} placeholder="Write description" />
          </Form.Item>
          <div className="add-member-footer-btn d-flex justify-content-center gap-4">
            <Button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">Save</Button>
          </div>
        </Form>
        
      </Modal.Body>
    </Modal>
  )
}

export default ReminderModal