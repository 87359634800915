import React from "react";
import "./Activities.scss";
import LeftArrow from "../../../../assets/Icon/arrow-left-line.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Accordion } from "react-bootstrap";

const Activities = () => {
  const location = useLocation();
  const { member } = location.state;

  const navigate = useNavigate();
  const myTeamDetail = () => {
    navigate("/myteam/team-detail", { state: { member } });
  };
  return (
    <div className="member-activity-screen">
      <div className="project-heading">
        <span onClick={myTeamDetail} style={{ cursor: "pointer" }}>
          <img src={LeftArrow} alt="" />
        </span>
        <h2>Back to list</h2>
      </div>
      <h1>Mia Wright's activities</h1>;
      <Accordion className="member-activity-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Today</Accordion.Header>
          <Accordion.Body>
            <div className="daily-activities">
              <div className="activity">
                <div className="time">05:30 PM</div>
                <div className="work">Chats Conversation with Client name</div>
              </div>
              <div className="activity">
                <div className="time">03:30 PM</div>
                <div className="work">Proposal crate for project Name</div>
              </div>
              <div className="activity">
                <div className="time">01:30 PM</div>
                <div className="work">Appointment Done with Client Name</div>
              </div>
              <div className="activity">
                <div className="time">12:00 PM</div>
                <div className="work">Send Proposal to client name</div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion className="member-activity-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>14 Aug,2023</Accordion.Header>
          <Accordion.Body>
            <div className="daily-activities">
              <div className="activity">
                <div className="time">05:30 PM</div>
                <div className="work">Chats Conversation with Client name</div>
              </div>
              <div className="activity">
                <div className="time">03:30 PM</div>
                <div className="work">Proposal crate for project Name</div>
              </div>
              <div className="activity">
                <div className="time">01:30 PM</div>
                <div className="work">Appointment Done with Client Name</div>
              </div>
              <div className="activity">
                <div className="time">12:00 PM</div>
                <div className="work">Send Proposal to client name</div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion className="member-activity-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>13 Aug,2023</Accordion.Header>
          <Accordion.Body>
            <div className="daily-activities">
              <div className="activity">
                <div className="time">05:30 PM</div>
                <div className="work">Chats Conversation with Client name</div>
              </div>
              <div className="activity">
                <div className="time">03:30 PM</div>
                <div className="work">Proposal crate for project Name</div>
              </div>
              <div className="activity">
                <div className="time">01:30 PM</div>
                <div className="work">Appointment Done with Client Name</div>
              </div>
              <div className="activity">
                <div className="time">12:00 PM</div>
                <div className="work">Send Proposal to client name</div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion className="member-activity-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>12 Aug,2023</Accordion.Header>
          <Accordion.Body>
            <div className="daily-activities">
              <div className="activity">
                <div className="time">05:30 PM</div>
                <div className="work">Chats Conversation with Client name</div>
              </div>
              <div className="activity">
                <div className="time">03:30 PM</div>
                <div className="work">Proposal crate for project Name</div>
              </div>
              <div className="activity">
                <div className="time">01:30 PM</div>
                <div className="work">Appointment Done with Client Name</div>
              </div>
              <div className="activity">
                <div className="time">12:00 PM</div>
                <div className="work">Send Proposal to client name</div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Activities;
