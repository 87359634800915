import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./AddNoteModal.scss"
import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const AddNoteModal = (props) => {
  const { onSubmitHandler, note } = props;

  const initialValues = {
    note: note.note ? note.note : '',
  };

  return (
    <Modal
      show= {props.show}
      onHide= {props.onHide}
      size="md"
      className="add-note-modal p-0"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h1>Add Note</h1>
        <Form className="add-opportunity-form" onFinish={onSubmitHandler} initialValues={initialValues}>
          <Form.Item
            className="mb-4"
            label="Description*"
            name="note"
            rules={[
              {
                required: true,
                message: "Description is required!",
                transform: (value) => {
                  if (value) {
                    return value.trim();
                  }
                  return value;
                }
              }
            ]}
          >
            <TextArea rows={4} placeholder="Write description" />
          </Form.Item>
          <div className="add-member-footer-btn d-flex justify-content-center gap-4">
            <Button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </Button>
            <Button type="submit" className="primary">Save</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AddNoteModal