import React from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import * as Icon from "react-bootstrap-icons"
import "./ProjectDetailAssignMember.scss"

const ProjectDetailAssignMember = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      className="project-detail-assign-member-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="header d-flex justify-content-between align-items-center">
          <h1>Assign Manager</h1>
          <Icon.XLg onClick={props.onHide} />
        </div>
        <div className="content">
          <Dropdown autoClose="outside" onSelect={console.log}>
            <Dropdown.Toggle id="dropdown-autoclose-inside">
              Select Member
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" value="test">
                <div className="manager-check-card">
                  <div className="check-input">
                    <input type="checkbox" name="" id="" className='check' />
                    <div className="client-details">
                      <div className="client-avatar">
                        <img src="https://picsum.photos/id/1/200/300" alt="" />
                      </div>
                      <div className="detail">
                        <h4>Project Manager</h4>
                        <h5>+1 12345 123645</h5>
                        <h5>info@gmail.com</h5>
                      </div>
                      <div className="project-assigned">3 Project Assigned</div>
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#">Menu Item</Dropdown.Item>
              <Dropdown.Item href="#">Menu Item</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ProjectDetailAssignMember