import React from 'react'

const ClientChat = () => {
  return (
      <section className='team-message-main'>
          <div className="messages">
              <div className="message-contents">
                  <div className="time">12:29 pm</div>
                  <p className='sender'>Show me the list of client whose project currently on going </p>
              </div>
          </div>
          <div className="messages owner">
              <div className="message-contents">
                  <div className="time">12:29 pm</div>
                  <div className="team-member-details">
                      <p>sure here the List of active client list- </p>
                      <div className="professinal-details">
                          <ol>
                              <li>client Name - Project Name 60% Complete</li>
                              <li>client Name - Project Name 40% Complete</li>
                              <li>client Name - Project Name 30% Complete</li>
                              <li>client Name - Project Name 10% Complete</li>
                          </ol>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}

export default ClientChat