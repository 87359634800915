import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./ChangeManager.scss";
import { Form, Select } from "antd";
import { reqToGetMembersBytype } from "../../../reduxToolkit/services/myTeamServices";
import { useDispatch, useSelector } from "react-redux";
import { reqToGetOpportunityDetails, reqToUpdateOpportunity } from "../../../reduxToolkit/services/opportunityServices";

const ChangeManager = ({ show, onHide, opportunity_id, oppDetail }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const teamReducer = useSelector((state) => state.myTeam);
  const { membersByType } = teamReducer;

  const initialValues = {
    manager: oppDetail?.manager?.uid,
  };

  useEffect(() => {
    dispatch(reqToGetMembersBytype({ page: 1, limit: 1000000, memberType: "managers" }));
  }, []);

  const onSubmitHandler = (values) => {
    const data = {
      status: oppDetail?.status,
      uid: values.manager,
    };

    dispatch(
      reqToUpdateOpportunity({
        oprId: opportunity_id,
        data: data,
        cb: (res) => {
          dispatch(reqToGetOpportunityDetails(opportunity_id));
          onHide();
        },
      })
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="md" className="add-member-modal" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <Form form={form} onFinish={onSubmitHandler} initialValues={initialValues}>
          <Form.Item
            className="mb-4"
            label="Select Manager"
            name="manager"
            rules={[
              {
                required: true,
                message: "Please select a manager!",
              },
            ]}
          >
            <Select placeholder="Select Manager">
              <Select.Option value="" disabled>
                Select Manager
              </Select.Option>
              {membersByType.length !== 0 &&
                membersByType.map((manager, i) => {
                  return (
                    <Select.Option key={i} value={manager.uid}>
                      {manager.firstName + " " + manager.lastName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <div className="footer-btn d-flex gap-4">
            <Button onClick={onHide} className="cancel-btn">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Change
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeManager;
