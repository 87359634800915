import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../helper/axios";
import { toastify } from "../../common/commonFunction";
import { apiendpoints, messages, routes } from "../../constants";

//registerUser
export const reqToAddClient = createAsyncThunk("reqToAddClient", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.addClient, data.data, authHeaders());

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.client.addClient);
      if (data.onSuccess) {
        data.onSuccess(response.data);
      }
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

// Request to verify invitation token
export const reqToVerifyClientToken = createAsyncThunk("reqToVerifyClientToken", async (data) => {
  try {
    const response = await Axios.post(`${apiendpoints.verifyInvitationClientToken}${data.token}`);
    if (response.status === 201 || response.status === 200) {
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    data.navigate(routes.login);
    throw error;
  }
});

// Signup Client
export const reqToSignupClient = createAsyncThunk("reqToSignupClient", async (data, thunkAPI) => {
  try {
    const response = await Axios.post(apiendpoints.singupClient, data.data);

    if (response.status === 201 || response.status === 200) {
      toastify("success", messages.toast.client.signUpClient);
      data.navigate(routes.login);
      return response.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message || "Something went wrong!");
    throw error;
  }
});

// get Members
export const reqToGetAllClients = createAsyncThunk("reqToGetAllClients", async (data) => {
  try {
    const response = await Axios.get(`${apiendpoints.getAllClient}?page=${data.page || 1}&limit=${data.limit || 10}`, authHeaders());
    if (response.status === 201 || response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    toastify("error", error.response.data.message);
    throw error;
  }
});
