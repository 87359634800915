import React from "react";
import { Table } from "react-bootstrap";
import "./PaymentTable.scss";

const PaymentTable = ({ data, setModalShow }) => {
  return (
    <Table size="lg" responsive className="payment-table">
      <thead>
        <tr>
          <th>Truncation Date</th>
          <th>Project Name</th>
          <th>Ref ID</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Remark</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => (
          <tr key={i}>
            <td>{item.date}</td>
            <td>{item.pname}</td>
            <td>{item.refid}</td>
            <td>{item.amt}</td>
            <td>{item.stauts}</td>
            <td>{item.remark}</td>
            <td>
              <div className="dots" style={{ cursor: "pointer" }} onClick={() => setModalShow(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-three-dots-vertical"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PaymentTable;
