import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import ProfileImage from "../../../assets/images/chat-profile1.jpg"
import "./ProfileModal.scss"
import { Form, Input } from 'antd'

const ProfileModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      className="profile-modal p-0"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h1>Profile</h1>
        <div className="image">
          <img src={ProfileImage} alt="" />
        </div>
        <Button className='update-btn'>Update Profile Image</Button>
        <Form className="add-opportunity-form">
          <Form.Item className="mb-4" label="Full Name">
            <Input placeholder="John William" />
          </Form.Item>
          <Form.Item className="mb-4" label="Email ID">
            <Input placeholder="abc@gmail.com" />
          </Form.Item>
          <Form.Item className="mb-4" label="Phone Number">
            <Input placeholder="+01 12345 56123" />
          </Form.Item>
        </Form>
        <div className="add-member-footer-btn d-flex justify-content-center gap-4">
          <Button className="cancel-btn" onClick={props.onHide}>
            Cancel
          </Button>
          <Button>Save Change</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ProfileModal