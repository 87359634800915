import { createSlice } from "@reduxjs/toolkit";
import { reqToAddProposal, reqToGetProposalDetails, reqToGetProposals, reqToUpdateProposal } from "../services/proposalServices";

const initialState = {
  loader: false,
  error: "",
  proposals: [],
  totalProposalCount: 0,
  proposalDetail: {},
};

//  Create proposal Slice
const proposalSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //req To add proposal
    builder.addCase(reqToAddProposal.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddProposal.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToAddProposal.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Get proposals
    builder.addCase(reqToGetProposals.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetProposals.fulfilled, (state, action) => {
      state.loader = false;
      state.proposals = action.payload.rows;
      state.totalProposalCount = action.payload.count;
    });
    builder.addCase(reqToGetProposals.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
    //req To update proposal
    builder.addCase(reqToUpdateProposal.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUpdateProposal.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(reqToUpdateProposal.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    //req To Get proposal details
    builder.addCase(reqToGetProposalDetails.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetProposalDetails.fulfilled, (state, action) => {
      state.loader = false;
      state.proposalDetail = action.payload;
    });
    builder.addCase(reqToGetProposalDetails.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
  },
});

export default proposalSlice.reducer;
