import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "react-bootstrap";
import "./ChatAnalyze.scss";

const ChatAnalyze = () => {
  return (
    <div className="col-xl-3 d-xl-block d-none chat-analyze p-0">
      <div className="chat-analyze-header">
        <div className="heading">Chat Analyze</div>
        <div className="thread-btn">
          <Button>Thread</Button>
        </div>
      </div>
      <div className="chat-analyze-content">
        <Accordion alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>AI Filters</Accordion.Header>
            <Accordion.Body>
              <div className="tags-main">
                <div className="tags">
                  <span className="tag-name">Question </span>
                  <div className="count">37</div>
                </div>
                <div className="tags">
                  <span className="tag-name">Task </span>
                  <div className="count">5</div>
                </div>
                <div className="tags">
                  <span className="tag-name">Date & Time </span>
                  <div className="count">8</div>
                </div>
                <div className="tags">
                  <span className="tag-name">Pricing</span>
                  <div className="count">16</div>
                </div>
                <div className="tags">
                  <span className="tag-name">Metrics</span>
                  <div className="count">85</div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Sentimental Filters</Accordion.Header>
            <Accordion.Body>
              <div className="sentimentals-tag-main">
                <div className="tags" style={{ backgroundColor: "#fae88c" }}>
                  <div className="name">Neutral</div>
                  <div className="per-cent">10%</div>
                </div>
                <div className="tags" style={{ backgroundColor: "#0FBF00" }}>
                  <div className="name">Positive</div>
                  <div className="per-cent">83%</div>
                </div>
                <div className="tags" style={{ backgroundColor: "#D93D3D" }}>
                  <div className="name">Negative</div>
                  <div className="per-cent">7 %</div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Speakers</Accordion.Header>
            <Accordion.Body>
              <div className="speakers-tag-main">
                <div className="table">
                  <div className="thead">
                    <div className="th">User Name</div>
                    <div className="th">Words</div>
                    <div className="th">Chat Time</div>
                  </div>
                  <div className="tbody">
                    <div className="td">John Kate</div>
                    <div className="td">1125</div>
                    <div className="td">90%</div>
                  </div>
                  <div className="tbody">
                    <div className="td">Kavita. W</div>
                    <div className="td">152</div>
                    <div className="td">10%</div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default ChatAnalyze;
