import React from "react";
import { Pagination } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProjectTable from "../../../common/project/ProjectTable";

const ClientProject = () => {
  const navigate = useNavigate();
  const gotoProjectDetail = () => {
    navigate("/client-project/details");
  };

  return (
    <section className="project-screen-main">
      <div className="project-screen-header">
        <h1 className="heading">Project Status</h1>
      </div>
      <div className="table-header">
        <div className="categories-main">
          <div className="tab-main">
            <div className="project-list-table">
              <ProjectTable gotoProjectDetail={gotoProjectDetail} />
              <div className="pagination-main">
                <Pagination total={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientProject;
