import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import activeIcon from "../../assets/Icon/active-status.svg";
import searchIcon from "../../assets/Icon/search-icon.png";
import chatMikeIcon from "../../assets/Icon/chat-mike.png";
import chatSendIcon from "../../assets/Icon/chat-send.png";
import "./AppoinmentChat.scss";

const AppoinmentChat = () => {
  return (
    <div className="chat-section">
      <div className="header d-flex justify-content-between align-items-center">
        <h2>
          Chat <img src={activeIcon} alt="" /> <span>Active</span>
        </h2>
        <div className="search-section">
          <InputGroup>
            <Form.Control placeholder="Search" />
            <img src={searchIcon} alt="" />
          </InputGroup>
        </div>
      </div>
      <section className="d-flex flex-column justify-content-between">
        <div className="chat-section-content">
          <div className="messages">
            <div className="message-contents">
              <div className="time">12:29 pm</div>
              <p>hello</p>
            </div>
          </div>
          <div className="messages owner">
            <div className="message-contents">
              <div className="time">12:29 pm</div>
              <p>
                And then we'll move right into contracting and pricing. Just to quickly confirm before I move it over to Candice, what's your launch
                timeline looking like? Has there been any movement there?
              </p>
            </div>
          </div>
          <div className="messages">
            <div className="message-contents">
              <div className="time">12:29 pm</div>
              <p>I would say yeah, like last time, about a month to two months.</p>
            </div>
          </div>
          <div className="messages owner">
            <div className="message-contents">
              <div className="time">12:29 pm</div>
              <p>
                I wanted to spend the first part of this call going through some of the technical aspects of Plaid's partnership with Duala and how
                that integration would work. Given that you are recording it and it will go to your engineers, it might be a good idea to actually
                spend a decent amount of time here talking about that. Candice, if that's okay with you, in that we'll also briefly go over identity
                verification just for your developers. But again, we talked about this already. You're going to have to be using Duala's verification
                as well for their compliance requirement. So we'll just give you access to our identity verification tool for KYC and identity
                verification tool for AML. But again, that will be something you use on an optional basis or as a backup solution, just like a lot of
                current customers of Platt also have. Okay, cool.
              </p>
            </div>
          </div>
        </div>
        <div className="send-message-footer">
          <input type="text" placeholder="write a message..." />
          <div className="icon-group">
            <img src={chatMikeIcon} alt="" />
            <img src={chatSendIcon} alt="" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppoinmentChat;
