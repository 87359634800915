import React from "react";
import * as Icon from "react-bootstrap-icons";
import { Accordion, Button } from "react-bootstrap";
import "./ProjectDetailTask.scss";

const ProjectDetailTask = ({ setModalShow, userRole }) => {
  return (
    <div className="project-detail-task">
      <Accordion defaultActiveKey="0">
        <div className="d-flex justify-content-between align-items-center total-task">
          <h4>12 Task</h4>
          {userRole !== "AGENCY_FOREMAN" && (
            <Button onClick={() => setModalShow(true)}>
              <Icon.PlusLg />
              Add New Task
            </Button>
          )}
        </div>
        <div className="d-flex align-items-center tab-header">
          <div className="task-btn">
            <Button>Upcoming Tasks (3)</Button>
          </div>
          <h4>Comments</h4>
          <h4>Assignee</h4>
          <h4>Due date</h4>
        </div>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="d-flex align-items-center">
            <h4>1. sample task Identify and engage with all relevant..</h4>
            <h4>
              3 <Icon.ChatLeft />
            </h4>
            <h4>Member Name</h4>
            <h4>03 Aug,2023</h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              sample task Identify and engage with all relevant stakeholders, including clients, architects, contractors, and regulatory authorities
              to ensure smooth communication and collaboration.{" "}
            </p>
            <div className="comment">
              Comments
              <ol>
                <li>sample task Identify and engage with all relevant stakeholders,</li>
                <li>sample task Identify and engage with all relevant stakeholders,</li>
                <li>sample task Identify and engage with all relevant stakeholders,</li>
              </ol>
            </div>
            <div className="d-flex gap-4 mb-2">
              <Button>Start Task</Button>
              <Button className="edit-btn">Edit Task</Button>
              <Button className="delete-btn">Delete</Button>
              <Button className="comment-btn">Comment</Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0" className="mt-5">
        <div className="d-flex align-items-center tab-header">
          <div className="task-btn">
            <Button className="ongoing">Ongoing Task (4)</Button>
          </div>
          <h4>Comments</h4>
          <h4>Assignee</h4>
          <h4>Due date</h4>
        </div>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="d-flex align-items-center">
            <h4>1. sample task Identify and engage with all relevant..</h4>
            <h4>
              3 <Icon.ChatLeft />
            </h4>
            <h4>Member Name</h4>
            <h4>03 Aug,2023</h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              sample task Identify and engage with all relevant stakeholders, including clients, architects, contractors, and regulatory authorities
              to ensure smooth communication and collaboration.{" "}
            </p>
            <div className="comment">
              Comments
              <ol>
                <li>sample task Identify and engage with all relevant stakeholders,</li>
                <li>sample task Identify and engage with all relevant stakeholders,</li>
                <li>sample task Identify and engage with all relevant stakeholders,</li>
              </ol>
            </div>
            <div className="d-flex gap-4 mb-2">
              <Button>Start Task</Button>
              <Button className="edit-btn">Edit Task</Button>
              <Button className="delete-btn">Delete</Button>
              <Button className="comment-btn">Comment</Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0" className="mt-5">
        <div className="d-flex align-items-center tab-header">
          <div className="task-btn">
            <Button className="completed">Completed Task (5)</Button>
          </div>
          <h4>Comments</h4>
          <h4>Assignee</h4>
          <h4>Due date</h4>
        </div>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="d-flex align-items-center">
            <h4>1. sample task Identify and engage with all relevant..</h4>
            <h4>
              3 <Icon.ChatLeft />
            </h4>
            <h4>Member Name</h4>
            <h4>03 Aug,2023</h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              sample task Identify and engage with all relevant stakeholders, including clients, architects, contractors, and regulatory authorities
              to ensure smooth communication and collaboration.{" "}
            </p>
            <div className="comment">
              Comments
              <ol>
                <li>sample task Identify and engage with all relevant stakeholders,</li>
                <li>sample task Identify and engage with all relevant stakeholders,</li>
                <li>sample task Identify and engage with all relevant stakeholders,</li>
              </ol>
            </div>
            <div className="d-flex gap-4 mb-2">
              <Button>Start Task</Button>
              <Button className="edit-btn">Edit Task</Button>
              <Button className="delete-btn">Delete</Button>
              <Button className="comment-btn">Comment</Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default ProjectDetailTask;
