import React from "react";
import { Table } from "react-bootstrap";
import "./UpComingPaySection.scss";
import errorRedIcon from "../../assets/Icon/error-red.png";
import ProfileImage1 from "../../assets/images/chat-profile1.jpg";

const UpComingPaySection = () => {
  return (
    <div className="upcoming-event">
      <div className="heading">
        Upcoming Payment Alert <img src={errorRedIcon} alt="" />
      </div>
      <Table hover responsive>
        <thead>
          <tr>
            <th>Project</th>
            <th>Due Amount</th>
            <th>Due Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="d-flex gap-3">
                <div className="img">
                  <img src={ProfileImage1} alt="" />
                </div>
                <div className="content">
                  <div className="name">Project Name</div>
                  <div className="designation">Project Manager</div>
                </div>
              </div>
            </td>
            <td>$3000.00</td>
            <td>Due Today</td>
          </tr>
          <tr>
            <td>
              <div className="d-flex gap-3">
                <div className="img">
                  <img src={ProfileImage1} alt="" />
                </div>
                <div className="content">
                  <div className="name">Project Name</div>
                  <div className="designation">Project Manager</div>
                </div>
              </div>
            </td>
            <td>$700.00</td>
            <td>07 Sep,2023</td>
          </tr>
          <tr>
            <td>
              <div className="d-flex gap-3">
                <div className="img">
                  <img src={ProfileImage1} alt="" />
                </div>
                <div className="content">
                  <div className="name">Project Name</div>
                  <div className="designation">Project Manager</div>
                </div>
              </div>
            </td>
            <td>$700.00</td>
            <td>08 Sep,2023</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default UpComingPaySection;
