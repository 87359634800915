import React from "react";

const ProposalDSubject = ({ proposalDetail }) => {
  return (
    <>
      <h6 className="mt-sm-5 mt-4">Subject: Proposal for Project </h6>
      <div className="detail mt-sm-5 mt-4">
        <h5>Dear {proposalDetail.client?.firstName ? proposalDetail.client.firstName + " " + proposalDetail.client?.lastName : ""},</h5>
        <h5>
          We are pleased to present this proposal for [Project Name], which outlines our comprehensive plan to address the needs and objectives
          outlined in our discussions. Our team at [Your Company Name] is excited about the opportunity to collaborate with [Client's Company Name] on
          this project and provide innovative solutions tailored to your requirements.
        </h5>
        <ol>
          <li>
            <p>
              Project Overview:
              <span>
                [Project Name] aims to [briefly describe the primary purpose and objectives of the project]. Our proposal outlines a detailed plan to
                accomplish these objectives efficiently and effectively.
              </span>
            </p>
          </li>
          <li>
            <p>
              Problem Statement:
              <span>
                After careful analysis and discussions, we have identified the key challenges and opportunities that [Client's Company Name] faces in
                [relevant industry or sector]. Our proposal will address these challenges and leverage opportunities to achieve desired outcomes.
              </span>
            </p>
          </li>
          <li>
            <p>
              Proposed Solution:
              <span>
                We propose a [briefly describe the proposed solution, products, or services] to meet the specific needs of [Client's Company Name].
                Our team of experts will work closely with your team to ensure the successful implementation of this solution.
              </span>
            </p>
          </li>
          <li>
            <p>
              Methodology and Approach:
              <span> Our approach includes the following steps: </span>
            </p>
            <ul>
              <li>[Step 1: Describe the first step of the project]</li>
              <li>[Step 2: Describe the second step of the project]</li>
              <li>[Step 3: Describe the third step of the project]</li>
              <li>[and so on...]</li>
            </ul>
          </li>
        </ol>
      </div>
      <div className="left">
        <h4>Sincerely,</h4>
        <h4>[Your Name]</h4>
        <h4>[Your Title]</h4>
        <h4>[Your Company Name]</h4>
        <h4>[Contact Information: Phone and Email]</h4>
      </div>
    </>
  );
};

export default ProposalDSubject;
